import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { StyleSheet, css } from 'aphrodite';
import NotificationAlert from "./NotificationAlert";





class IntegrationListItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hide: true
        }
    }

    handleEdit() {

        sessionStorage.setItem("integrationId", this.props.integrationId);
        this.props.history.push("/builder");
    }
    render() {

        let editButton = null;
        editButton = <i className="material-icons" onClick={() => {
            this.handleEdit();
        }}>edit</i>;

        let moreMenu;
        if (this.state.hide) {
            moreMenu = <div className={css(styles.more_menu__hide)}>{editButton}</div>
        } else {
            moreMenu = <div className={css(styles.more_menu)}>{editButton}</div>
        }
        //file_copy

        return (
            <div className="integration-list--items">
                <div className="integration-list--items--wrapper">
                    <span className="integration-list--items--status">{this.props.isPublished ? "Published" : "In Progress"}</span>
                    <span className="integration-list--items--title"> {this.props.integrationName}</span>
                    {this.props.modifiedAt && <span className="integration-list--items--last-update">Last Update: {"" + new Date(parseInt(this.props.modifiedAt) * 1000)}</span>}
                </div>
                <div className="integration-list--items--icon">
                    {!this.props.isTemplate && editButton}
                    <i className="material-icons" onClick={() => {
                        this.props.handleDelete && this.props.handleDelete(this.props.integrationId);
                    }}>delete</i>

                    {!this.props.isTemplate && this.props.isPublished && <i className="material-icons" onClick={() => {
                        this.props.createTemplate && this.props.createTemplate(this.props.integrationId);
                    }}>file_copy</i>}
                </div>
            </div>
        );
    }
}

export default withRouter(IntegrationListItem);


const styles = StyleSheet.create({
    more_menu: {
        backgroundColor: "white",
        position: "relative",
        borderRadius: "3px",
        width: "100px",
        boxShadow: "0px 0px 40px 1px rgb(229, 233, 236)",
        zIndex: "100"
    },

    more_menu__hide: {
        display: "none"
    }
});