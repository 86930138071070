import React, { Component } from "react";
import { withRouter, Route } from "react-router-dom";
import logos from "../services/LogoService"

class BuilderLogoHolder extends Component {

    constructor() {
        super();
        this.state = {
        }
    }

    render() {
        const trigger = this.props.triggerApp;
        const action = this.props.actionApp;

        let triggerHeader = trigger && trigger.application ? (<span className="builder__content__app-logo__text">
            {trigger.application.displayName}</span>) : null;

        let actionHeader = action && action.application ? (<span className="builder__content__app-logo__text">
            {action.application.displayName}</span>) : null;

        let triggerImage = trigger && trigger.application ? (<img width="100%" height="100%" src={logos.getLogoForApp(trigger.application.applicationName)} />) : <i className="material-icons">add_circle</i>;
        let actionImage = action && action.application ? (<img width="100%" height="100%" src={logos.getLogoForApp(action.application.applicationName)} />) : <i className="material-icons">add_circle</i>;

        return (
            <div className="builder__content__app-logo">
                <div className="builder__content__app-logo__box builder__content__app-logo__box--active">
                    <div className={"builder__content__app-logo__img" + (trigger ? " builder__content__app-logo__img--active" : "")}>
                        {triggerImage}
                    </div>
                    {triggerHeader}

                </div>
                <div className={"builder__content__app-logo__box" + (action ? " builder__content__app-logo__box--active" : "")}>
                    <div className={"builder__content__app-logo__img" + (action ? " builder__content__app-logo__img--active" : "")}>
                        {actionImage}
                    </div>
                    {actionHeader}
                </div>
                <div />
            </div>
        );
    }
}

export default withRouter(BuilderLogoHolder);
