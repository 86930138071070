import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import DropDown from "./DropDown";
import Select from "./Select";

class SelectAction extends Component {


    constructor(props) {
        super(props);
        this.state = {
            searchText: ""
        }
    }

    handleItemClick(action) {
        this.props.onActionTypeSelect && this.props.onActionTypeSelect(action);
    }

    dropdownTextChange(e) {
        this.setState({ searchText: e.target.value });
    }

    handleItemDelete() {
        this.props.clearField && this.props.clearField();
    }

    render() {

        const appList = this.props.actions ? this.props.actions.map(action => {
            return (<div key={action.actionName} className="trigger-select--dropdown--item" onClick={() => {
                this.handleItemClick(action);
            }}>
                <div className="trigger--dropdown--item--text">
                    <span className="title">{action.actionName}</span>
                    <span className="secondary">{action.displayName}</span>
                </div>
            </div>);
        }) : null;

        const selectedAction = this.props.selectedActionType;

        const searchText = selectedAction ? selectedAction.actionName : this.state.searchText;

        const userInput = selectedAction ? selectedAction.userInput : false;
        let userInputForm = null;
        // if (userInput) {
        //     let userInputSchema = selectedAction.userInputSchema;
        //     let fieldNames = Object.keys(userInputSchema.properties);
        //     userInputForm = fieldNames.map(fieldName => {
        //         return <div key={fieldName} className="builder__content__section__item">
        //             <label className="builder__content__section__item__label">
        //                 {userInputSchema.properties[fieldName].description}
        //             </label>
        //             <input className="builder__content__section__item__input" type="text" placeholder={userInputSchema.properties[fieldName].description} value={this.props.selectedActionType.userInputObject &&
        //                 this.props.selectedActionType.userInputObject[fieldName]}
        //                 onChange={e => { this.props.userInputChange && this.props.userInputChange(fieldName, e.target.value) }} />
        //         </div>;
        //     });
        // }

        if (userInput && selectedAction.userInputSchema) {
            let userInputSchema = selectedAction.userInputSchema;
            let fieldNames = Object.keys(userInputSchema.properties);
            userInputForm = fieldNames.map(fieldName => {
                if (userInputSchema.properties[fieldName].enum) {
                    const inputHtmlBlock = selectedAction.userInputObject &&
                        selectedAction.userInputObject[fieldName] ? <div className="select-active-item">
                            <span className="trigger--dropdown--item--text">{
                                userInputSchema.properties[fieldName].enum.filter(e => {
                                    return e.key === selectedAction.userInputObject[fieldName]
                                })[0].value}
                            </span>
                        </div> : null;
                    return <div key={fieldName} className="builder__content__section__item">
                        <label className="builder__content__section__item__label">
                            {userInputSchema.properties[fieldName].title}
                        </label>

                        <Select
                            htmlBlock={inputHtmlBlock}
                            items={userInputSchema.properties[fieldName].enum}
                            itemRenderer={inputEnum => {
                                return (<div key={inputEnum.key} className="trigger-select--dropdown--item" >
                                    <div className="trigger--dropdown--item--text">
                                        <span className="title">{inputEnum.value}</span>
                                        <span className="secondary">{inputEnum.key}</span>
                                    </div>
                                </div>);
                            }}
                            onItemSelect={(item) => {
                                //this.handleItemClick(item);
                                this.props.userInputChange && this.props.userInputChange(fieldName, item.key)
                            }}
                            filterField={'value'}
                            onItemClear={() => {
                                // this.handleItemDelete();
                                this.props.userInputChange && this.props.userInputChange(fieldName, undefined)
                            }}
                        ></Select></div>;
                } else {
                    return <div key={fieldName} className="builder__content__section__item">
                        <label className="builder__content__section__item__label">
                            {userInputSchema.properties[fieldName].title}
                        </label>
                        <input className="builder__content__section__item__input" type="text"
                            placeholder={userInputSchema.properties[fieldName].placeholder}
                            value={selectedAction.userInputObject &&
                                selectedAction.userInputObject[fieldName]}
                            onChange={e => { this.props.userInputChange && this.props.userInputChange(fieldName, e.target.value) }} />
                    </div>;
                }

            });
        }


        let htmlBlock = null;
        if (this.props.selectedActionType) {
            htmlBlock = <div className="trigger--dropdown--item">
                <div className="">
                    <span className="trigger--dropdown--item--text">{this.props.selectedActionType.displayName}</span>
                </div>
            </div>
        }
        let selectView = <Select
            htmlBlock={htmlBlock}
            items={this.props.actions}
            itemRenderer={action => {
                return (<div key={action.actionName} className="trigger-select--dropdown--item" onClick={() => {
                    this.handleItemClick(action);
                }}>
                    <div className="trigger--dropdown--item--text">
                        <span className="title">{action.actionName}</span>
                        <span className="secondary">{action.displayName}</span>
                    </div>
                </div>);
            }}
            onItemSelect={(item) => {
                this.handleItemClick(item);
            }}
            filterField={'displayName'}
            onItemClear={() => {
                this.handleItemDelete();
            }}
        ></Select>


        return (
            <section className="builder__content__section">
                <div className="builder__content__section__item">
                    <label className="builder__content__section__item__label">
                        Action
                </label>
                    {selectView}
                </div>
                {userInputForm}
            </section>
        );
    }
}

export default withRouter(SelectAction);
