/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import api from "../services/ApiService";
import dbcLogo from "../images/dbc.png";
import DropDown from "./DropDown";
import LogService from "../services/LogoService"

class SpectrumLogin extends Component {


    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            connectionPayload: {
                authorizationId: "",
                companyCode: "",
                host: "",
                port: "",
                protocol: "https",
                connectionName: ""
            }
        }
    }

    /*
    <div className="dbc-login__item">
                    <label className="dbc-login--item__label">
                        Database Type
                    </label>
                    <DropDown searchText={this.state.searchText} searchTextChange={(e) => { this.dropdownTextChange(e) }}>{appList}</DropDown>
                </div>
    
    
    */
    handleSubmit() {

        let qs = this.props.location.search;
        qs = qs.replace("?", "");

        let qsSplit = qs.split("&");
        let state = ""
        let accessToken = ""
        qsSplit.map(q => {
            let keyValue = q.split("=");

            if (keyValue.length === 2) {

                if (keyValue[0] === "state") {
                    state = keyValue[1];
                }

                if (keyValue[0] === "userJWT") {
                    accessToken = keyValue[1];
                }
            }
        })

        let data = this.state.connectionPayload;
        if (!data.host || !data.port || !data.connectionName || !data.companyCode || !data.authorizationId) {
            alert("missing mandatory parameter");
            return;
        }
        api.spectrumLogin(data, state, accessToken).then(res => {
            localStorage.setItem("connectionAuthStatus", 200);
            localStorage.setItem("connectionPayload", JSON.stringify(res.data));
            window.close();
        }).catch(err => {
            alert("Invalid username/password");
        })
    }

    handleItemClick(databaseType) {
        let databasePayload = this.state.databasePayload;
        databasePayload.databaseType = databaseType.typeName;
        this.setState({ searchText: databaseType.displayName, databasePayload })

    }
    render() {
        return <div className="dbc-login">

            <img className="dbc-login--img" height={"65"} width="65" src={LogService.getLogoForApp("SPECTRUM")}></img>


            <input type="text" placeholder="Connection Name"
                value={this.state.connectionPayload.connectionName}
                onChange={e => {
                    let connectionPayload = this.state.connectionPayload;
                    connectionPayload.connectionName = e.target.value;
                    this.setState({ connectionPayload });
                }} />
            <input type="text" placeholder="Spectrum Host"
                value={this.state.connectionPayload.host}
                onChange={e => {
                    let connectionPayload = this.state.connectionPayload;
                    connectionPayload.host = e.target.value;
                    this.setState({ connectionPayload });
                }} />
            <input type="text" placeholder="Port"
                value={this.state.connectionPayload.port}
                onChange={e => {
                    let connectionPayload = this.state.connectionPayload;
                    connectionPayload.port = e.target.value;
                    this.setState({ connectionPayload });
                }} />
            <input type="text" placeholder="Authorization ID"
                value={this.state.connectionPayload.authorizationId}
                onChange={e => {
                    let connectionPayload = this.state.connectionPayload;
                    connectionPayload.authorizationId = e.target.value;
                    this.setState({ connectionPayload });
                }} />
            <input type="text" placeholder="Company Code"
                value={this.state.connectionPayload.companyCode}
                onChange={e => {
                    let connectionPayload = this.state.connectionPayload;
                    connectionPayload.companyCode = e.target.value;
                    this.setState({ connectionPayload });
                }} />

            <a className="btn" onClick={() => {
                this.handleSubmit();
            }}>Authorize</a>
        </div>
    }
}

export default withRouter(SpectrumLogin);