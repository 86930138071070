import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import DropDown from "./DropDown";
import NewWindow from 'react-new-window'
import api from '../services/ApiService'
import Select from "./Select";



class ConnectActionAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            openNewWindow: false,
            intervalId: 0,
            authWindow: null,
        }
    }

    componentWillUnmount() {
        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
    }

    dropdownTextChange(e) {
        this.setState({ searchText: e.target.value });
    }

    handleNewConnection() {
        sessionStorage.setItem("currentNewConnection", this.props.application.applicationName)

        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
        if (this.state.authWindow) {
            this.state.authWindow.close();
        }

        const url = api.siteBaseUrl() + "/connections?app=" + this.props.application.applicationName;
        let authWindow = window.open(url, 'targetWindow',
            'toolbar=no,location=no,  status=no,menubar=no,scrollbars=yes, resizable=yes,width=700,height=700');

        let intervalID = setInterval(() => {

            let dataPointId = localStorage.getItem("newConnnectionId");

            if (this.state.authWindow.closed) {
                clearInterval(this.state.intervalId);
            }

            if (dataPointId) {
                api.getConnectionByDataPointId(dataPointId).then(res => {

                    if (res.status == 200) {
                        localStorage.removeItem("newConnnectionId");
                        this.props.onActionAuthComplete && this.props.onActionAuthComplete(res.data);
                        clearInterval(this.state.intervalId);
                    } else if (res.status == 500) {
                        // clearInterval(this.state.intervalId);
                        // this.setState({ openNewWindow: false, intervalId: 0 })
                    }
                }).catch(err => {

                })
            }
        }, 500);

        this.setState({ openNewWindow: true, intervalId: intervalID, authWindow })
    }

    handleItemClick(connection) {
        this.props.onActionAuthComplete && this.props.onActionAuthComplete(connection);
    }

    handleItemDelete() {
        this.props.clearField && this.props.clearField();
    }

    render() {
        const searchText = this.props.searchText ? this.props.searchText : this.state.searchText;

        const listheader = <span className="connect-trigger-acnt--dropdown--header">frequently used connections</span>
        const accountList = this.props.connections && this.props.connections.length > 0 ? this.props.connections.map(con => {

            return (<div key={con.dataPointId} className="connect-trigger-acnt--dropdown-item" onClick={() => {
                this.handleItemClick(con);
            }}>
                <div className="connect-trigger-acnt--dropdown-item--text">
                    <span className="title">{con.connectionName}</span>
                    <span className="updatedat">Last Update: {"" + new Date(con.updatedAt * 1000)}</span>
                </div>
                <a className="btn">test</a>
            </div>);
        }) : <div className="connect-trigger-acnt--dropdown-item">
                <div className="connect-trigger-acnt--dropdown-item--text">
                    <span className="title">No connections available</span>
                </div>
            </div>;

        let htmlBlock = null;
        if (this.props.selectedConnection) {
            let con = this.props.selectedConnection;
            htmlBlock = <div key={con.dataPointId} className="trigger--dropdown--item">
                <div className="">
                    <span className="trigger--dropdown--item--text">{con.connectionName}</span>
                </div>
            </div>
        }

        let selectView = <Select
            htmlBlock={htmlBlock}
            items={this.props.connections}
            itemRenderer={con => {
                return (<div key={con.dataPointId} className="connect-trigger-acnt--dropdown-item">
                    <div className="connect-trigger-acnt--dropdown-item--text">
                        <span className="title">{con.connectionName}</span>
                        <span className="updatedat">Last Update: {"" + new Date(parseInt(con.updatedAt) * 1000)}</span>
                    </div>
                    <a className="btn">test</a>
                </div>);
            }}
            onItemSelect={(item) => {
                this.handleItemClick(item);
            }}
            filterField={'connectionName'}
            onItemClear={() => {
                this.handleItemDelete();
            }}
        ></Select>
        return (
            <section className="builder__content__section">
                <div className="builder__content__section__item--trigger-account">
                    <label className="builder__content__section__item__label">
                        Account
                </label>
                    <div className="builder__content__section__item--trigger-account--div">
                        <div className="builder__content__section__item--trigger-account--div--wrap">
                            {selectView}
                        </div>
                        <a className="btn" onClick={() => {
                            this.handleNewConnection()
                        }}>connect an account</a>
                    </div>
                </div>
            </section>
        );
    }
}

export default withRouter(ConnectActionAccount);
