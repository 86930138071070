import React, { Component } from "react";
import NotificationAlert from "../components/NotificationAlert"

class AddNewFieldOverLay extends Component {


    constructor(props) {
        super(props);
        this.state = {
            notificationAlert: {},
            name: "",
            label: ""
        }
    }

    handleClose() {
        this.props.onClose && this.props.onClose();
    }



    render() {

        return (
            <div className="generic-overlay" >
                <NotificationAlert notificationAlert={this.state.notificationAlert}></NotificationAlert>
                {/* <header className="add-new-field--header">
                    <i className="material-icons add-new-field--close" onClick={() => {
                        this.handleClose();
                    }}>close</i>
                </header> */}
                <div className="add-new-field--main">
                    <div className="add-new-field--box">

                        <span className="add-new-field--title">
                            {"Add new field"}
                        </span>
                        <span className="add-new-field--name">
                            {"field name"}
                        </span>
                        <input className="builder__content__section__item__input" type="text"
                            value={this.state.name}
                            onChange={e => {
                                this.setState({ name: e.target.value })
                            }} />

                        <div className="add-new-field__btn-wrapper">
                            <span className="btn add-new-field__btn" onClick={() => {
                                let format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
                                if (!this.state.name) {
                                    alert("Field name connot be empty");
                                } else if (format.test(this.state.name)) {
                                    alert("field name name connot have space or special charecters")
                                } else {
                                    let obj = {
                                        type: "string",
                                        name: this.state.name,
                                        title: this.state.name
                                    }
                                    this.props.onAdd(this.props.data.path, obj)
                                }

                            }}>add</span>
                            <span className="btn add-new-field__btn" onClick={() => {
                                this.handleClose();
                            }}>cancel</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default AddNewFieldOverLay;