import React, { Component } from "react";
import "./App.css";
import Index from "./screens/Index";
import Security from "./services/Security";
import { withRouter, Switch, Route } from "react-router-dom";
import Login from "./screens/Login";
import Connect from "./screens/Connect";
import Connections from "./screens/Connections";
import Callback from "./screens/Callback";
import Home from "./screens/home";



class App extends Component {

  constructor(props) {
    super(props);
    this.auth = new Security(this.props.history);
  }

  loginSuccess(authStatus) {
    this.setState({ authStatus });
  }

  componentDidMount() {

    let bool = this.auth.isAuthenticated();
    if (!this.auth.isAuthenticated()) {
      sessionStorage.setItem("lastUrl", this.props.location.pathname);
    } else {
      sessionStorage.removeItem("lastUrl");
    }

  }
  render() {

    if (this.auth.isAuthenticated()) {
      return <Switch>
        <Route path="/connections" component={() => {
          return <Connections></Connections>
        }}></Route>
        <Route path="/connect" component={() => {
          return <Connect></Connect>
        }}></Route>
        <Route path="/cb/auth" component={() => {
          return <Login auth={this.auth} />;
        }}></Route>
        <Route path="/" component={() => {
          return <Index auth={this.auth} onAuthError={() => {
            sessionStorage.removeItem("accessToken");
          }} />;
        }}></Route>
      </Switch>
    } else {
      return <Switch>
        <Route path="/connections" component={() => {
          return <Connections></Connections>
        }}></Route>
        <Route path="/callback" component={() => {
          return <Callback></Callback>
        }}></Route>
        <Route path="/connect" component={() => {
          return <Connect></Connect>
        }}></Route>
        <Route path="/cb/auth" component={() => {
          return <Login auth={this.auth} />;
        }}></Route>
        <Route path="/login" component={() => {
          return <Login auth={this.auth} />;
        }}></Route>
        <Route path="/" component={() => {
          return <Login auth={this.auth} />;
        }}></Route>
      </Switch>
    }





  }
}

export default withRouter(App);
