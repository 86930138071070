import auth0 from 'auth0-js';
import api from './ApiService';

class Security {

    constructor(history) {
        this.history = history;



        var protocol = window.location.protocol;
        var slashes = protocol.concat("//");
        var host = slashes.concat(window.location.hostname);
        var port = window.location.port;
        if (port) {
            host += ":" + port;
        }

        this.auth0 = new auth0.WebAuth({
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: 'openid profile email',
            responseType: 'token',
            redirectUri: host + '/cb/auth'
        });


        // this.auth0 = new auth0.WebAuth({
        //     domain: "n3wlab.auth0.com",
        //     clientID: "dbyEtl1cCQiRebSomNMo5Svh3uqhGGa1",
        //     audience: 'https://dev.n3wlab.com',
        //     scope: 'openid profile email',
        //     responseType: 'token',
        //     redirectUri: host + '/cb/auth'
        // });
        // this.auth0 = new auth0.WebAuth({
        //     domain: "n3wlab.auth0.com",
        //     clientID: "EL7xVltiabxgrCh78Xfdc0amR49U84jj",
        //     audience: 'https://app.n3wlab.com',
        //     scope: 'openid profile email',
        //     responseType: 'token',
        //     redirectUri: host + '/cb/auth'
        // });

    }



    doLogin = () => {

        this.auth0.authorize();
    }

    isAuthenticated() {
        if (sessionStorage.getItem("accessToken")) {
            return true;
        } else {
            return false;
        }
    }

    handleAuth(hash) {
        // alert(hash)
        let self = this.history;
        this.auth0.parseHash(function (err, authResult) {
            if (err) {
                //this.doLogin();
                return console.log(err);
            }
            sessionStorage.setItem("accessToken", authResult.accessToken);
            sessionStorage.setItem("expiresIn", authResult.expiresIn);
            api.getUserSelf().then(res => {
                console.log(res.data);
                if (res.data) {
                    sessionStorage.setItem("userProfile", JSON.stringify(res.data));
                    if (sessionStorage.getItem("lastUrl")) {
                        self.push(sessionStorage.getItem("lastUrl"));
                    } else {
                        self.push("/");
                    }
                } else {
                }
            }).catch(err => {

                sessionStorage.removeItem("accessToken");
                sessionStorage.removeItem("expiresIn");
                // var protocol = window.location.protocol;
                // var slashes = protocol.concat("//");
                let returnTo = encodeURIComponent(`${'https://www.n3wlab.com'}`);

                //alert("df")
                window.location = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?returnTo=${returnTo}`
            })

        })
    }

}

export default Security;