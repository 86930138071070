import React, { Component } from "react";
import { withRouter, Route } from "react-router-dom";
import Logo from "../images/logo.png";
import NewIntegrationOverlay from "./NewIntegrationOverlay"

class Header extends Component {

  handleNewIntegration() {

    if (this.props.location.pathname == "/index/admin") {
      this.props.history.push("/index/admin/new-user")
    } else if (this.props.location.pathname == "/index/embed") {
      if (this.props.headerText === 'embed-users') {
        localStorage.removeItem("editUser");
        this.props.history.push("/index/admin/new-user")
      } else {
        localStorage.removeItem("editPlan");
        this.props.history.push("/index/monetisation/new-plan")
      }

    } else {
      this.props.history.push("/index/new-integration")
    }

  }

  render() {

    let btnText = "ADD A new integration";
    if (this.props.location.pathname == "/index/admin") {
      btnText = "Add new user";
    } else if (this.props.location.pathname == "/index/embed") {

      if (this.props.headerText === 'embed-users') {
        btnText = "Add new users";
      } else {
        btnText = "Add new plan";
      }

    }
    return (
      <header className="index__header">
        <div className="index__header__logo-box">
          {/* <img className="index__header__logo" src={Logo} /> */}
        </div>
        <div>
          <a className="btn index__header__btn" onClick={() => { this.handleNewIntegration() }}>{btnText}</a>
        </div>
        <Route path="/index/new-integration" component={() => {
          return <NewIntegrationOverlay></NewIntegrationOverlay>;
        }}></Route>
      </header>
    );
  }
}

export default withRouter(Header);
