import React, { Component } from "react";

import { Route, Redirect, withRouter, Switch } from "react-router-dom";
import Header from "../components/Header"
import IndexNav from "../components/IndexNav"
import Builder from "./Builder";
import IntegrationsList from "./IntegrationsList";
import UserList from "./UserList"
import Connections from "./Connections";
import Callback from "./Callback";
import Connect from "./Connect";
import AddNewUser from "./AddNewUser";
import EditUser from "./EditUser";
import PartnerListTemplates from "./PartnerListTemplates";
import MonetList from "./MonetList";
import AddNewPlan from "./AddNewPlan";
import EmbeddedApp from "./EmbeddedApp";

class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      header: ''
    }
  }

  handleHeaderBtnTxtChange(header) {

    this.setState({ ...this.state, header })
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={() => {
          return <Redirect
            to={{
              pathname: "/index/integrations"
            }}
          />
        }} ></Route>

        <Route path="/index" component={() => {

          return <div className="index">
            <Header headerText={this.state.header}></Header>
            <section className="index__container">
              <IndexNav></IndexNav>
              <div className="index__container__main" >
                <Route exact path="/index/integrations" component={() => {
                  return <IntegrationsList></IntegrationsList>
                }} />
                <Route exact path="/index/admin" component={() => {
                  return <UserList></UserList>
                }} />
                <Route exact path="/index/monetisation" component={() => {
                  return <MonetList></MonetList>
                }} />
                <Route exact path="/index/monetisation/new-plan" component={() => {
                  return <AddNewPlan></AddNewPlan>
                }} />
                <Route exact path="/index/admin/new-user" component={() => {
                  return <AddNewUser></AddNewUser>
                }} />

                <Route exact path="/index/embed" component={() => {
                  return <EmbeddedApp headerText={this.state.header} handleHeaderBtnTxtChange={(header) => {
                    this.handleHeaderBtnTxtChange(header);
                  }}></EmbeddedApp>
                }} />
                <Route exact path="/index/admin/edit-user/:userId" component={() => {
                  return <EditUser></EditUser>
                }} />
              </div>
            </section>
          </div>
        }} />

        <Route path="/builder" component={() => {
          return <Builder></Builder>
        }}></Route>

        <Route path="/connections" component={() => {
          return <Connections></Connections>
        }}></Route>

        <Route path="/callback" component={() => {
          return <Callback></Callback>
        }}></Route>

        <Route path="/connect" component={() => {
          return <Connect></Connect>
        }}></Route>

        <Route path="/embed/tmplt/:partnerId" component={() => {
          return <PartnerListTemplates></PartnerListTemplates>
        }}></Route>

      </Switch>

    );
  }
}

export default withRouter(Index);
