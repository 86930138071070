import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import NotificationAlert from "../components/NotificationAlert"
import DropDown from "../components/DropDown"
import api from "../services/ApiService";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import Loader from "../components/Loader";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

// const classes = useStyles();
//         const theme = useTheme();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

// function getStyles(name, personName, theme) {
//     return {
//         fontWeight:
//             personName.indexOf(name) === -1
//                 ? theme.typography.fontWeightRegular
//                 : theme.typography.fontWeightMedium,
//     };
// }



class AddNewPlan extends Component {



    constructor(props) {
        super(props);

        this.state = {
            plan: {
                planName: "",
                planCode: 0,
                default: false,
                maxIntegrations: null,
                customization: [],
                planId: null,
                connectors: []
            },
            applications: [],
            showLoader: false,
            notificationAlert: { type: "", message: "" }
        }
    }

    handleClose() {
        this.props.history.push("/index/embed");
    }

    componentDidMount() {
        const planStr = localStorage.getItem("editPlan");

        api.getAllApplications().then(res => {
            //  alert(JSON.stringify(res.data));
            this.setState({ ...this.state, applications: res.data })

        }).catch(err => {

        })

        if (planStr) {
            const plan = JSON.parse(planStr);
            console.log(planStr)
            console.log(plan.connectors)
            if (plan && plan.planId) {
                this.setState({
                    ...this.state, plan: {
                        planName: plan.planName,
                        planCode: plan.planCode,
                        default: plan.isDefault,
                        maxIntegrations: plan.maxIntegrations,
                        customization: plan.customization,
                        planId: plan.planId,
                        connectors: plan.connectors
                    }
                })
            }
        }

    }

    handleSubmit() {
        const plan = this.state.plan;
        if (!plan.planName) {
            return alert("name cannot be empty");
        }
        if (!plan.planCode) {
            return alert("planCode cannot be empty");
        }
        if (!plan.default) {
            plan.default = false;
        }

        if (!plan.maxIntegrations) {
            return alert("maxIntegrations cannot be empty");
        }
        if (!plan.customization) {
            plan.customization = [];
        }

        if (plan.planId) {
            api.updateOrgPlan(plan.planId, plan).then(res => {
                this.props.history.push("/index/embed")
            }).catch(err => {
                this.setState({ notificationAlert: { message: "unable to create plan", type: "warning" } });
            })
        } else {
            delete plan.planId;
            api.createOrgPlan(plan).then(res => {
                this.props.history.push("/index/embed")
            }).catch(err => {
                this.setState({ notificationAlert: { message: "unable to create plan", type: "warning" } });
            })
        }
        // alert(JSON.stringify(this.state.user))
    }


    render() {

        const customizationOps = [{ name: "Create new integration", key: "createNewIntegration" },
        { name: "Developer mode", key: "developerMode" },
        { name: "Formulas", key: "formulas" },
        { name: "Field Mapping", key: "fieldMapping" }]

        let loader = null;
        if (this.state.showLoader) {
            loader = <Loader></Loader>;
        }

        return (
            <div className="new-user-overlay" >
                <NotificationAlert notificationAlert={this.state.notificationAlert}></NotificationAlert>
                {loader}

                <div className="new-user-overlay--form" style={{ height: "600px" }}>
                    <div className="new-user-overlay--form--header">
                        <span className="new-user-overlay--form--header--text">Add Plan</span>
                        <i className="material-icons new-user-overlay--form--header--icon" onClick={() => {
                            this.handleClose();
                        }}>close</i>
                    </div>

                    <div className="new-user-overlay--form--content">
                        <div className="new-user-overlay--form--content--inputbox">
                            <span className="new-user-overlay--form--content--label">Plan Name</span>
                            <input className="new-user-overlay--form--content--input" onChange={e => {
                                let plan = this.state.plan;
                                plan.planName = e.target.value;
                                this.setState({ plan })
                            }} value={this.state.plan.planName} type="text"></input>
                        </div>

                        <div className="new-user-overlay--form--content--inputbox">
                            <span className="new-user-overlay--form--content--label">Plan Code</span>
                            <input className="new-user-overlay--form--content--input" onChange={e => {
                                let plan = this.state.plan;
                                plan.planCode = e.target.value;
                                this.setState({ plan })
                            }} value={this.state.plan.planCode} type="text"></input>
                        </div>

                        <div className="new-user-overlay--form--content--inputbox">
                            <span className="new-user-overlay--form--content--label">Default</span>
                            <DropDown searchText={"" + this.state.plan.default} className="new-user-overlay--form--content--dropdown" type="text">
                                <div className="new-user-overlay--form--content--dropdown-item" onClick={e => {
                                    let plan = this.state.plan;
                                    plan.default = true;
                                    this.setState({ plan })
                                }}>
                                    <span>True</span>
                                </div>
                                <div className="new-user-overlay--form--content--dropdown-item" onClick={e => {
                                    let plan = this.state.plan;
                                    plan.default = false;
                                    this.setState({ plan })
                                }}>
                                    <span>False</span>
                                </div>
                            </DropDown>
                        </div>
                        <div className="new-user-overlay--form--content--inputbox">
                            <span className="new-user-overlay--form--content--label">Customization</span>
                            <Select
                                multiple
                                value={this.state.plan.customization}
                                onChange={(event) => {
                                    //   alert(JSON.stringify(event.target.value))
                                    console.log(event.target.value)
                                    let plan = this.state.plan;
                                    plan.customization = event.target.value;
                                    this.setState({ plan });
                                }}
                                input={<Input className={"new-user-overlay--form--content--input"} style={{ border: "none" }} id="select-multiple-chip" />}
                                renderValue={selected => {
                                    // alert(JSON.stringify(selected))
                                    return <div >
                                        {selected.map(value => {
                                            let sv = customizationOps.filter(cus => { return cus.key === value })[0];
                                            return <Chip style={{ fontSize: "1.3rem" }} key={sv.key} label={sv.name} />;
                                        })}
                                    </div>
                                }}
                                MenuProps={MenuProps}
                            >
                                {customizationOps.map(cus => (
                                    <MenuItem style={{ fontSize: "1.3rem" }} key={cus.key} value={cus.key}>
                                        {cus.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="new-user-overlay--form--content--inputbox">
                            <span className="new-user-overlay--form--content--label">Connectors</span>
                            <Select
                                multiple
                                value={this.state.plan.connectors}
                                onChange={(event) => {
                                    //   alert(JSON.stringify(event.target.value))
                                    console.log(event.target.value)
                                    let plan = this.state.plan;
                                    plan.connectors = event.target.value;
                                    this.setState({ plan });
                                }}
                                input={<Input className={"new-user-overlay--form--content--input"} style={{ border: "none" }} id="select-multiple-chip" />}
                                renderValue={selected => {
                                    // alert(JSON.stringify(selected))
                                    return <div >
                                        {selected.map(value => {
                                            let sv = this.state.applications.filter(app => { return app.applicationName === value })[0];
                                            return sv && <Chip style={{ fontSize: "1.3rem" }} key={sv.applicationName} label={sv.displayName} />;
                                        })}
                                    </div>
                                }}
                                MenuProps={MenuProps}
                            >
                                {this.state.applications.map(app => (
                                    <MenuItem style={{ fontSize: "1.3rem" }} key={app.applicationName} value={app.applicationName}>
                                        {app.displayName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="new-user-overlay--form--content--inputbox">
                            <span className="new-user-overlay--form--content--label">Max Integrations</span>
                            <input className="new-user-overlay--form--content--input" value={this.state.plan.maxIntegrations} onChange={e => {
                                if (!isNaN(e.target.value)) {
                                    let plan = this.state.plan;
                                    plan.maxIntegrations = e.target.value;
                                    this.setState({ plan })
                                }
                            }} type="text"></input>
                        </div>

                        <div className="new-user-overlay--form--content--inputbox">
                            <a className="btn" onClick={() => {
                                this.handleSubmit();
                            }}>save</a>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default withRouter(AddNewPlan);
