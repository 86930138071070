import React, { Component } from "react";

class DropDown extends Component {

    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
            hide: true
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }


    /**
   * Set the wrapper ref
   */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ hide: true });
        }
    }

    togglePopup() {
        if (this.state.hide) {
            this.setState({ hide: false })
        } else {
            this.setState({ hide: true })
        }
    }

    render() {

        return (<div className={this.props.className ? this.props.className : "dropdown"} ref={this.setWrapperRef} onClick={() => { this.togglePopup() }}>
            <input disabled className={this.props.className ? this.props.className : ""} type="text" placeholder="Click here.." value={this.props.searchText} onChange={e => { this.props.searchTextChange && this.props.searchTextChange(e) }} />
            <div className={"dropdown__items_box" + (this.state.hide ? " dropdown__items_box--hide" : "")}>{this.props.children}</div>
        </div>);
    }
}

export default DropDown;