// import React, { Component } from "react";
// import { Grid, Container } from "@material-ui/core";
// import { makeStyles } from '@material-ui/core/styles';

// import CssBaseline from '@material-ui/core/CssBaseline';
// import { minWidth } from "@material-ui/system";

// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';





// export default class EmbeddedApp extends Component {

//     constructor(props) {
//         super(props);

//         this.state = {
//             embeddedEnable: false
//         }
//     }

//     render() {
//         return <div className={css(styles.root)}>
//             <div className={css(styles.embedEnableWrap)}>
//                 <span className={css(styles.embedEnableText)}>Enable Embedded Portal</span>
//                 <Switch
//                     checked={this.state.embeddedEnable}
//                     onChange={() => {
//                         this.setState({ embeddedEnable: !this.state.embeddedEnable });
//                     }}
//                     value="checkedA"
//                     inputProps={{ 'aria-label': 'secondary checkbox' }}
//                 />
//             </div>

//             <AppBar position="static" color="default">
//                 <Toolbar>
//                     <Typography variant="h4" color="inherit">
//                         Monetisation
//           </Typography>
//                 </Toolbar>
//             </AppBar>
//             <AppBar position="static" color="default">
//                 <Toolbar>
//                     <Typography variant="h4" color="inherit">
//                         SSO
//           </Typography>
//                 </Toolbar>
//             </AppBar>
//             <AppBar position="static" color="default">
//                 <Toolbar>
//                     <Typography variant="h4" color="inherit">
//                         Users
//           </Typography>
//                 </Toolbar>
//             </AppBar>
//         </div>
//     }

// }

import React from 'react';
import PropTypes, { func } from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { green } from '@material-ui/core/colors';
import { StyleSheet, css } from 'aphrodite';
import Switch from '@material-ui/core/Switch';
import { withRouter } from "react-router-dom";
import api from "../services/ApiService";
import Loader from "../components/Loader"
import { stat } from 'fs';


const styles = StyleSheet.create({
    root: {
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        width: "600px"
    },
    embedEnableWrap: {
        padding: "3rem"
    },
    embedEnableText: {
        fontSize: "1.5rem"
    }
});



function TabContainer(props) {
    const { children, dir } = props;

    return (
        <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        position: 'relative',
        minHeight: "600",
    },
    fontHeader: {
        fontSize: "1.3rem"
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabGreen: {
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        },
    },
}));


function loadUserProfile(props) {
    let userStr = sessionStorage.getItem("userProfile");
    let user = {};
    if (userStr) {
        user = JSON.parse(userStr);
        // alert(user)
        let embeddedEnable = user.embedded ? user.embedded.isEnabled : false;
        // setState({ ...state, user,embeddedEnable });
    }



    return React.useState({
        tab: props.headerText==='embed-users'?1:0, 
        embeddedEnable: user.embedded ? user.embedded.isEnabled : false,
        user,
        initailLoad: true,
        userLoad: true,
        showLoader: false,
        monets: [],
        users: []
    })
}


function FloatingActionButtonZoom(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [state, setState] = loadUserProfile(props);

    function handleChange(event, newValue) {

        // alert(newValue);
        if (newValue === 1) {
            props.handleHeaderBtnTxtChange && props.handleHeaderBtnTxtChange('embed-users');
        } else {
            props.handleHeaderBtnTxtChange && props.handleHeaderBtnTxtChange('embed-plans');
        }

        setState({ ...state, tab: newValue });
    }

    function handlePlanDelete(plan) {
        setState({ ...state, showLoader: true })
        api.deletePlan(plan.planId).then(res => {
            const newPlans = state.monets.filter(m => {
                return m.planId !== plan.planId
            })
            setState({ ...state, monets: newPlans, showLoader: false });

        }).catch(err => {
            setState({ ...state, showLoader: false });
        })
    }

    function handleDeleteUser(user) {
        setState({ ...state, showLoader: true })
        api.deleteUser(user.userId).then(res => {

            const newUsers = state.users.filter(u => {
                return u.userId !== user.userId;
            })

            setState({ ...state, users: newUsers, showLoader: false });

        }).catch(err => {
            setState({ ...state, showLoader: false })
        })
    }

    function handleChangeIndex(index) {


        setState({ ...state, tab: index });
    }

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const fabs = [
        {
            color: 'primary',
            className: classes.fab,
            icon: <AddIcon />,
            label: 'Add',
        },
        {
            color: 'primary',
            className: classes.fab,
            icon: <AddIcon />,
            label: 'Add',
        }
    ];
    if (state.initailLoad) {
        api.getOrgPlans().then(res => {

            setState({ ...state, monets: res.data, initailLoad: false });

        }).catch(err => {
            setState({ ...state, initailLoad: false });
        })
    }

    if (state.userLoad) {
        api.getAllUsers().then(res => {
            setState({ ...state, users: res.data, userLoad: false });
        }).catch(err => {

        })
    }

    const customizationOps = [{ name: "Create new integration", key: "createNewIntegration" },
    { name: "Developer mode", key: "developerMode" },
    { name: "Formulas", key: "formulas" },
    { name: "Field Mapping", key: "fieldMapping" }]

    const monets = state.monets ? state.monets.map(monet => {
        let customization = "";
        monet.customization.map(cus => {
            let cust = customizationOps.filter(f => { return f.key === cus });
            if (cust && cust.length > 0) {
                customization += cust[0].name + ", "
            }
        })
        return <div key={monet.planCode} className="user-list--items">
            <span className={"user-list--item--value user-list--item--value__name"}>{monet.planName}</span>
            <span className={"user-list--item--value user-list--item--value__email"}>{monet.planCode}</span>
            <span className={"user-list--item--value user-list--item--value__role"}>{monet.maxIntegrations}</span>
            <span className={"user-list--item--value user-list--item--value__icount"}>{customization}</span>
            <span className={"user-list--item--value user-list--item--value__icount"}>{"" + monet.isDefault}</span>
            <span className={"user-list--item--value user-list--item--value__action"}>
                <i className="material-icons" onClick={() => {
                    //  this.handleEdit(user);
                    localStorage.setItem("editPlan", JSON.stringify(monet));
                    props.history.push("/index/monetisation/new-plan")
                }}>edit</i>
                <i className="material-icons" onClick={() => {
                    //  this.handleDelete(user);
                    handlePlanDelete(monet);
                }}>delete</i>
            </span>
        </div>
    }) : [];

    const usersList = state.users ? state.users.map(user => {
        return <div key={user.userId} className="user-list--items">
            <span className={"user-list--item--value user-list--item--value__name"}>{user.firstname + " " + user.lastname}</span>
            <span className={"user-list--item--value user-list--item--value__email"}>{user.email}</span>
            <span className={"user-list--item--value user-list--item--value__role"}>{user.planCode}</span>
            <span className={"user-list--item--value user-list--item--value__action"}>
                <i className="material-icons" onClick={() => {
                    localStorage.setItem("editUser", JSON.stringify(user));
                    props.history.push("/index/admin/new-user")
                }}>edit</i>
                <i className="material-icons" onClick={() => {
                    handleDeleteUser(user);
                }}>delete</i>
            </span>
        </div>
    }) : [];

    React.useEffect(() => {

    })

    let loader = null;
    if (state.showLoader) {
        loader = <Loader></Loader>
    }
    return (
        <div className={classes.root}>
            {loader}
            <div className={css(styles.embedEnableWrap)}>
                <span className={css(styles.embedEnableText)}>Enable Embedded Portal</span>
                <Switch
                    checked={state.embeddedEnable}
                    onChange={() => {
                        setState({ ...state, embeddedEnable: !state.embeddedEnable });
                    }}
                    value="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            </div>
            <AppBar position="static" color="default">
                <Tabs
                    value={state.tab}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab className={classes.fontHeader} label="Monetization" />
                    {/* <Tab className={classes.fontHeader} label="SSO" /> */}
                    <Tab className={classes.fontHeader} label="Users" />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={state.tab}
                onChangeIndex={handleChangeIndex}
            >
                <TabContainer dir={theme.direction}>
                    <div className="user-list">
                        <header className="user-list--header">
                            <div className={"user-list--header--item user-list--header--item__name"}>Plan Name</div>
                            <div className={"user-list--header--item user-list--header--item__email"}>Plan Code</div>
                            <div className={"user-list--header--item user-list--header--item__icount"}>Max Integration</div>
                            <div className={"user-list--header--item user-list--header--item__icount"}>customization</div>
                            <div className={"user-list--header--item user-list--header--item__default"}>default</div>
                            <div className={"user-list--header--item user-list--header--item__action"}>Action</div>
                        </header>
                        {monets}
                    </div>
                </TabContainer>
                {/* <TabContainer dir={theme.direction}>
                    TBA
                </TabContainer> */}
                <TabContainer dir={theme.direction}>
                    <div className="user-list">
                        <header className="user-list--header">
                            <div className={"user-list--header--item user-list--header--item__name"}>Name</div>
                            <div className={"user-list--header--item user-list--header--item__email"}>Email</div>
                            <div className={"user-list--header--item user-list--header--item__role"}>Plan</div>
                            <div className={"user-list--header--item user-list--header--item__action"}>Action</div>
                        </header>
                        {usersList}
                    </div>;
                </TabContainer>
            </SwipeableViews>
            
        </div>
    );
}

export default withRouter(FloatingActionButtonZoom);