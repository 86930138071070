import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import api from "../services/ApiService";
import LogService from "../services/LogoService"

const qbd_login_wrap = {
    position: 'absolute',
    top: '5%',
    left: '50%',
    transform: 'translate(-50%, 0)',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    marginBottom: '100px'
};

const qbd_login_input = {
    margin: "10px auto",
    height: "50px",
    border: "1px solid rgb(6, 40, 68)",
    borderRadius: "3px",
    width: "400px",
    padding: "20px",
    fontSize: "1.7rem",
    fontStyle: "italic",
    fontWeight: "200",
    letterSpacing: "0.8px"
}
const desc_text = {
    fontSize: "1.3rem",
    fontWeight: "400",
};

const button = {
    marginTop: "1rem"
}

class QBDLogin extends Component {



    // String databaseType = payload.get("databaseType");
    // String databaseName = payload.get("databaseName");
    // String dbHost = payload.get("dbHost");
    // String dbPassword = payload.get("dbPassword");
    // String dbPort = payload.get("dbPort");
    // String dbSchema = payload.get("dbSchema");
    // String dbUsername = payload.get("dbUsername");
    // String ssl = payload.get("ssl");

    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            password: "",
            connectionPayload: {
                connectionName: ""
            }
        }
    }

    /*
    <div className="dbc-login__item">
                    <label className="dbc-login--item__label">
                        Database Type
                    </label>
                    <DropDown searchText={this.state.searchText} searchTextChange={(e) => { this.dropdownTextChange(e) }}>{appList}</DropDown>
                </div>
    
    
    */
    handleSubmit() {

        let qs = this.props.location.search;
        qs = qs.replace("?", "");

        let qsSplit = qs.split("&");
        let state = ""
        let accessToken = ""
        qsSplit.map(q => {
            let keyValue = q.split("=");

            if (keyValue.length === 2) {

                if (keyValue[0] === "state") {
                    state = keyValue[1];
                }

                if (keyValue[0] === "userJWT") {
                    accessToken = keyValue[1];
                }
            }
        })

        // alert(accessToken)

        let data = this.state.connectionPayload;
        api.qbdLogin(data, state, accessToken).then(res => {
            localStorage.setItem("connectionAuthStatus", 200);
            localStorage.setItem("connectionPayload", JSON.stringify(res.data));
            //this.setState({ showPassword: true });
            const conData = JSON.parse(res.data.connectionData);
            this.downloadFile(conData);
            this.setState({ showPassword: true, password: conData.qbPassword });

            //window.close();
        }).catch(err => {
            console.log(err);
            alert("error occurred");
        })
    }


    downloadFile(fileData) {
        const workspaceXML = `<?xml version="1.0"?>
  <QBWCXML>
    <AppName>${fileData.qbAppName}</AppName>
    <AppID>${fileData.qbAppId}</AppID>
    <AppURL>${fileData.qbUrl}</AppURL>
    <AppDescription>${fileData.qbAppDescription}</AppDescription>
    <AppSupport>${fileData.qbSupportUrl}</AppSupport>
    <UserName>${fileData.qbUsername}</UserName>
    <OwnerID>{${fileData.qbOwnerId}}</OwnerID>
    <FileID>{${fileData.qbFileId}}</FileID>
    <QBType>${fileData.QBType}</QBType>
    <Scheduler>
      <RunEveryNMinutes>5</RunEveryNMinutes>
    </Scheduler>
  </QBWCXML>
`;
        const element = document.createElement("a");
        const file = new Blob([workspaceXML], { type: "application/xml" });
        element.href = URL.createObjectURL(file);
        element.download = "QuickBooks.qwc";
        element.click();
    }

    render() {
        let showPassword = null
        if (this.state.showPassword) {
            // showPassword = 

            return <div style={qbd_login_wrap}>
                <img className="dbc-login--img" height={"65"} width="65" src={LogService.getLogoForApp("QBD")}></img>


                <input style={qbd_login_input} type="text" placeholder="Connection Name"
                    value={this.state.connectionPayload.connectionName}
                    disabled />
                <div><input style={qbd_login_input} type="text" placeholder="QB Password"
                    value={this.state.password} disabled />
                    <span style={desc_text}>Please save the password. Password will be required when you add QB file(downloaded) in web connector</span>
                </div>
                <a style={button} className="btn" onClick={() => {
                    window.close();
                }}>Close window</a>
            </div>
        } else {
            return <div style={qbd_login_wrap}>
                <img className="dbc-login--img" height={"65"} width="65" src={LogService.getLogoForApp("QBD")}></img>


                <input style={qbd_login_input} type="text" placeholder="Connection Name"
                    value={this.state.connectionPayload.connectionName}
                    onChange={e => {
                        let connectionPayload = this.state.connectionPayload;
                        connectionPayload.connectionName = e.target.value;
                        this.setState({ connectionPayload });
                    }} />

                <a style={button} className="btn" onClick={() => {
                    this.handleSubmit();
                }}>Authorize</a>
            </div>
        }


    }
}

export default withRouter(QBDLogin);