import React, { Component } from "react";
import { withRouter, Route } from "react-router-dom";

class BuilderNav extends Component {



    render() {

        const stepsDone = this.props.stepsDone ? this.props.stepsDone : {};
        const stepInprogress = this.props.stepInprogress ? this.props.stepInprogress : 1;



        return (
            <nav className="builder__nav">
                <div className="builder__nav__header">application 1</div>

                <div className={"builder__nav__item" + (stepsDone["1"] ? " builder__nav__item--done" : "") + (stepInprogress === 1 ? " builder__nav__item--active" : "")}>
                    <i className={"material-icons builder__nav__item__icon--done" + (stepsDone["1"] ? "" : " builder__nav__item__icon--hidden")}>
                        check_circle
              </i>Trigger Application
            </div>
                <div className={"builder__nav__item" + (stepsDone["2"] ? " builder__nav__item--done" : "") + (stepInprogress === 2 ? " builder__nav__item--active" : "")}>
                    <i className={"material-icons builder__nav__item__icon--done" + (stepsDone["2"] ? "" : " builder__nav__item__icon--hidden")}>
                        check_circle
              </i>Connect Your Account
            </div>

                <div className={"builder__nav__item" + (stepsDone["3"] ? " builder__nav__item--done" : "") + (stepInprogress === 3 ? " builder__nav__item--active" : "")}>
                    <i className={"material-icons builder__nav__item__icon--done" + (stepsDone["3"] ? "" : " builder__nav__item__icon--hidden")}>
                        check_circle
              </i>Select a Trigger
            </div>

                <div className={"builder__nav__item" + (stepsDone["4"] ? " builder__nav__item--done" : "") + (stepInprogress === 4 ? " builder__nav__item--active" : "")}>
                    <i className={"material-icons builder__nav__item__icon--done" + (stepsDone["4"] ? "" : " builder__nav__item__icon--hidden")}>
                        check_circle
              </i>Select an Object
            </div>
                <div className="builder__nav__line" />

                <div className="builder__nav__header">application 2</div>

                <div className={"builder__nav__item" + (stepsDone["5"] ? " builder__nav__item--done" : "") + (stepInprogress === 5 ? " builder__nav__item--active" : "")}>
                    <i className={"material-icons builder__nav__item__icon--done" + (stepsDone["5"] ? "" : " builder__nav__item__icon--hidden")}>
                        check_circle
              </i>Action Application
            </div>
                <div className={"builder__nav__item" + (stepsDone["6"] ? " builder__nav__item--done" : "") + (stepInprogress === 6 ? " builder__nav__item--active" : "")}>
                    <i className={"material-icons builder__nav__item__icon--done" + (stepsDone["6"] ? "" : " builder__nav__item__icon--hidden")}>
                        check_circle
              </i> Connect Your Account
            </div>

                <div className={"builder__nav__item" + (stepsDone["7"] ? " builder__nav__item--done" : "") + (stepInprogress === 7 ? " builder__nav__item--active" : "")}>
                    <i className={"material-icons builder__nav__item__icon--done" + (stepsDone["7"] ? "" : " builder__nav__item__icon--hidden")}>
                        check_circle
              </i>
                    Select an Action
            </div>

                <div className={"builder__nav__item" + (stepsDone["8"] ? " builder__nav__item--done" : "") + (stepInprogress === 8 ? " builder__nav__item--active" : "")}>
                    <i className={"material-icons builder__nav__item__icon--done" + (stepsDone["8"] ? "" : " builder__nav__item__icon--hidden")}>
                        check_circle
              </i>Select a Object
            </div>
                <div className="builder__nav__line" />
                <div className="builder__nav__header">mapping</div>

                <div className={"builder__nav__item" + (stepsDone["9"] ? " builder__nav__item--done" : "") + (stepInprogress === 9 ? " builder__nav__item--active" : "")}>
                    <i className={"material-icons builder__nav__item__icon--done" + (stepsDone["9"] ? "" : " builder__nav__item__icon--hidden")}>
                        check_circle
              </i>Field Mapping
            </div>
            </nav>
        );
    }
}

export default withRouter(BuilderNav);
