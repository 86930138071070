import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Logo from "../images/logo.png";
import IntegrationListHeader from "../components/IntegrationListHeader";
import IntegrationListItem from "../components/IntegrationListItem";
import api from '../services/ApiService';
import NotificationAlert from "../components/NotificationAlert";
import { StyleSheet, css } from 'aphrodite';
import ErrorSummary from "../components/ErrorSummary";

class CreateTemplateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationAlert: {},
            name: "",
            logoApp: "QBO",
            description: ""
        }
    }

    handleClose() {
        this.props.onClose && this.props.onClose();
    }

    doCreateTemplate() {
        let payload = {
            templateDescription: this.state.description,
            templateName: this.state.name,
            logoApp: this.state.logoApp
        }
        api.createTemplate(this.props.integrationId, payload).then(res => {
            this.setState({ notificationAlert: { message: "template created successfully", type: "success" } })
            this.props.onClose && this.props.onClose();
        }).catch(err => {
            this.setState({ notificationAlert: { message: "unable to craete template", type: "warning" } })
            this.props.onClose && this.props.onClose();
        })
    }



    render() {

        return (
            <div className="generic-overlay" >
                <NotificationAlert notificationAlert={this.state.notificationAlert}></NotificationAlert>
                {/* <header className="add-new-field--header">
                    <i className="material-icons add-new-field--close" onClick={() => {
                        this.handleClose();
                    }}>close</i>
                </header> */}
                <div className="add-new-field--main">
                    <div className={css(styles.addNewFieldBox)}>

                        <span className="add-new-field--title">
                            {"Add New Template"}
                        </span>
                        <span className="add-new-field--name">
                            {"Template Name"}
                        </span>
                        <input className="builder__content__section__item__input" type="text"
                            value={this.state.name}
                            onChange={e => {
                                this.setState({ name: e.target.value })
                            }} />

                        <span className="add-new-field--name">
                            {"Template Description"}
                        </span>
                        <input className="builder__content__section__item__input" type="text"
                            value={this.state.description}
                            onChange={e => {
                                this.setState({ description: e.target.value })
                            }} />
                        <select className={css(styles.input)} value={this.state.logoApp} onChange={e => {
                            this.setState({ logoApp: e.target.value })
                        }}>
                            <option value="QBO">QuickBooks Online</option>
                            <option value="QBD">QuickBooks Desktop</option>
                            <option value="GOCANVAS">GoCanvas</option>
                            <option value="SFDC">SalesForce</option>
                            <option value="POSTGRES">PostgreSQL</option>
                        </select>

                        <div className="add-new-field__btn-wrapper">
                            <span className="btn add-new-field__btn" onClick={() => {
                                if (!this.state.name) {
                                    alert("Field name connot be empty");
                                    return;
                                }
                                this.doCreateTemplate();
                            }}>Add Template</span>
                            <span className="btn add-new-field__btn" onClick={() => {
                                this.handleClose();
                            }}>cancel</span>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

}


class IntegrationList extends Component {

    constructor() {
        super();
        this.state = {
            headerTab: "IN PROGRESS",
            integrations: [],
            templates: [],
            showTemplate: false,
            integrationId: null
        }
    }


    componentDidMount() {
        api.getAllIntegrations().then(res => {
            if (res.status === 200 && res.data) {
                this.setState({ ...this.state, integrations: res.data });
            } else {
                console.log(JSON.stringify(res))
            }
            api.getAllTemplatesForEmbedded().then(res => {
                this.setState({ ...this.state, templates: res.data });
            }).catch(err => {

            })
        }).catch(err => {
            console.log(JSON.stringify(err));
        })
    }
    handleHeaderTabChange(tab) {
        this.setState({ headerTab: tab });
    }

    reloadTemplates() {
        api.getAllTemplatesForEmbedded().then(res => {
            this.setState({ ...this.state, templates: res.data });
        }).catch(err => {

        })
    }

    handleDeleteEvent(integrationId) {
        api.deleteIntegration(integrationId).then(res => {
            if (res.status === 200) {
                const newList = this.state.integrations.filter(t => { return t.integrationId !== integrationId });
                this.setState({ ...this.state, integrations: newList });
            }
        }).catch(err => {

        });
    }

    handleTemplateDelete(templateId) {
        api.deleteTemplateById(templateId).then(res => {
            if (res.status === 200) {
                const newList = this.state.templates.filter(t => { return t.templateId !== templateId });
                this.setState({ ...this.state, templates: newList });
            }
        }).catch(err => {

        });
    }

    createTemplate(integrationId) {
        //alert(integrationId)
        this.setState({ showTemplate: true, integrationId })

    }
    render() {

        let returnList = null;
        if (this.state.headerTab === 'ALL') {
            returnList = this.state.integrations.map(integration => {
                return <IntegrationListItem integrationId={integration.integrationId} key={integration.integrationId} integrationName={integration.integrationName}
                    isPublished={integration.isPublished}
                    modifiedAt={integration.updatedAt} />
            })
        } else if (this.state.headerTab === 'IN PROGRESS') {
            const publishedList = this.state.integrations.filter(int => {
                return (!int.isPublished && !int.isDeleted)
            })
            returnList = publishedList.map(integration => {
                return <IntegrationListItem integrationId={integration.integrationId} key={integration.integrationId} integrationName={integration.integrationName}
                    isPublished={integration.isPublished}
                    modifiedAt={integration.updatedAt}
                    handleDelete={integrationId => {
                        this.handleDeleteEvent(integrationId);
                    }} />
            })
        } else if (this.state.headerTab === 'PUBLISHED') {
            const publishedList = this.state.integrations.filter(int => {
                return (int.isPublished && !int.isDeleted)
            })

            publishedList.sort((a, b) => {
                let aVal = a.updatedAt && parseInt(a.updatedAt)
                let bVal = b.updatedAt && parseInt(b.updatedAt)

                if (!bVal) bVal = 0;
                if (!aVal) aVal = 0;

                if (aVal > bVal) {
                    return -1;
                } else if (aVal > bVal) {
                    return 1;
                } else {
                    return 0
                }
            })
            returnList = publishedList.map(integration => {
                return <IntegrationListItem
                    integrationId={integration.integrationId}
                    key={integration.integrationId}
                    integrationName={integration.integrationName}
                    isPublished={integration.isPublished}
                    modifiedAt={integration.updatedAt}
                    createTemplate={integrationId => {
                        this.createTemplate(integrationId);
                    }}
                    handleDelete={integrationId => {
                        this.handleDeleteEvent(integrationId);
                    }}
                />
            })
        } else if (this.state.headerTab === 'TEMPLATES') {
            returnList = this.state.templates.map(integration => {
                return <IntegrationListItem
                    integrationId={integration.templateId}
                    key={integration.templateId}
                    isPublished={true}
                    isTemplate={true}
                    integrationName={integration.templateName}
                    handleDelete={() => {
                        this.handleTemplateDelete(integration.templateId);
                    }}
                />
            })
        } else if (this.state.headerTab === 'ERRORS') {
            returnList = <ErrorSummary></ErrorSummary>
        }


        const publishedCount = this.state.integrations.filter(integration => { return integration.isPublished; }).length;
        const inProgressCount = this.state.integrations.length - publishedCount;
        const templatesCount = this.state.templates.length;
        return (
            <div className="integration-list">
                {this.state.showTemplate && <CreateTemplateForm integrationId={this.state.integrationId} onClose={() => {
                    this.reloadTemplates();
                    this.setState({ showTemplate: false })
                }}></CreateTemplateForm>}
                <IntegrationListHeader templatesCount={templatesCount} inProgressCount={inProgressCount} publishedCount={publishedCount} selectedTab={this.state.headerTab} onTabChange={(tab) => { this.handleHeaderTabChange(tab); }} />
                <div style={{ width: "100%" }}>
                    {returnList}
                </div>
            </div>
        );
    }
}

export default withRouter(IntegrationList);

const styles = StyleSheet.create({
    input: {
        height: "50px",
        border: "1px solid rgb(180, 190, 198)",
        borderRadius: "3px",
        width: "100%",
        marginTop: "3rem",
        padding: "20px",
        fontSize: "1.7rem",
        fontStyle: "italic",
        fontWeight: "200",
        letterSpacing: "0.8px",
        zIndex: "200"
    },
    addNewFieldBox: {
        margin: "auto",
        display: "flex",
        flexFlow: "column",
        backgroundColor: "white",
        height: "50rem",
        width: "60rem",
        border: "solid 1px rgb(208,214,219)",
        borderRadius: ".2rem",
        padding: "4rem"
    }
});

