import React, { Component } from "react";


/*

Props:
    textInput -
    htmlBlock - 
    htmlButton -
    onDeleteHtmlBlcok - 
    onChange -
*/

class Input extends Component {


    handleKeyUp(event) {

        let KeyID = event.keyCode;
        switch (KeyID) {
            case 8:
                this.deleteHtml();
                break;
            case 46:
                this.deleteHtml();
                break;
            default:
                break;
        }
    }

    deleteHtml() {
        console.log("delte");
        if (!this.props.textInput) {
            this.props.onDeleteHtmlBlock && this.props.onDeleteHtmlBlock();
        }
    }

    render() {

        return <div className="cus-input" onClick={() => {
            this.textInput.focus();
        }}>
            {this.props.htmlBlock}
            <input value={this.props.textInput} onChange={(e) => {
                this.props.onChange && this.props.onChange(e)
            }} onKeyUp={(event) => { this.handleKeyUp(event) }} className="cus-input--text" ref={(ip) => this.textInput = ip}></input>
            {this.props.htmlButton}
        </div>
    }

}

export default Input;