import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class BuilderContentHeader extends Component {

    render() {
        return (
            <div className="builder__content__header">
                <a className="btn builder__content__header__btn-back" onClick={() => {
                    this.props.handleBack && this.props.handleBack()
                }}>BACK</a>
                <div className="builder__content__header__text">
                    <span className="builder__content__header__step-text">
                        Step {this.props.step}
                    </span>
                    <span className="builder__content__header__title">
                        {this.props.stepTitle}
                        <i className="material-icons builder__content__header__title__icon">
                            error
                  </i>
                    </span>
                </div>
                <a className={"btn" + (this.props.nextEnable ? "" : " btn--disabled")}
                    onClick={() => { this.props.nextEnable && this.props.handleNext && this.props.handleNext() }}>{this.props.nextButtonText}</a>
            </div>
        );
    }
}

export default withRouter(BuilderContentHeader);
