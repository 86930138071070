import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import api from "../services/ApiService";
import dbcLogo from "../images/dbc.png";
import DropDown from "./DropDown";

class DatabaseLogin extends Component {



    // String databaseType = payload.get("databaseType");
    // String databaseName = payload.get("databaseName");
    // String dbHost = payload.get("dbHost");
    // String dbPassword = payload.get("dbPassword");
    // String dbPort = payload.get("dbPort");
    // String dbSchema = payload.get("dbSchema");
    // String dbUsername = payload.get("dbUsername");
    // String ssl = payload.get("ssl");

    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            databasePayload: {
                databaseType: "POSTGRES",
                databaseName: "",
                dbHost: "",
                dbPassword: "",
                dbPort: "",
                dbSchema: "",
                dbUsername: "",
                ssl: "false"
            },
            databaseTypes: [
                {
                    typeName: "POSTGRES",
                    displayName: "Postgres"
                },
                {
                    typeName: "MYSQL",
                    displayName: "MySql"
                },
                {
                    typeName: "MSSQL",
                    displayName: "MS SQL Server"
                }
            ]
        }
    }

    /*
    <div className="dbc-login__item">
                    <label className="dbc-login--item__label">
                        Database Type
                    </label>
                    <DropDown searchText={this.state.searchText} searchTextChange={(e) => { this.dropdownTextChange(e) }}>{appList}</DropDown>
                </div>
    
    
    */
    handleSubmit() {

        let data = this.state.databasePayload;
        api.databaseLogin(data).then(res => {
            localStorage.setItem("connectionAuthStatus", 200);
            localStorage.setItem("connectionPayload", JSON.stringify(res.data));
            window.close();
        }).catch(err => {
            alert("Invalid username/password");
        })
    }

    handleItemClick(databaseType) {
        let databasePayload = this.state.databasePayload;
        databasePayload.databaseType = databaseType.typeName;
        this.setState({ searchText: databaseType.displayName, databasePayload })

    }
    render() {

        const appList = this.state.databaseTypes ? this.state.databaseTypes.map(app => {
            return (<div key={app.typeName} className="trigger--dropdown--item" onClick={() => {
                this.handleItemClick(app);
            }}>
                <span className="trigger--dropdown--item--text">{app.displayName}</span>
            </div>);
        }) : null;
        return <div className="dbc-login">
            <img className="dbc-login--img" height={"65"} width="65" src={dbcLogo}></img>


            <input type="text" placeholder="Database Name"
                value={this.state.databasePayload.databaseName}
                onChange={e => {
                    let databasePayload = this.state.databasePayload;
                    databasePayload.databaseName = e.target.value;
                    this.setState({ databasePayload });
                }} />
            <input type="text" placeholder="Database Host"
                value={this.state.databasePayload.dbHost}
                onChange={e => {
                    let databasePayload = this.state.databasePayload;
                    databasePayload.dbHost = e.target.value;
                    this.setState({ databasePayload });
                }} />
            <input type="text" placeholder="Port"
                value={this.state.databasePayload.dbPort}
                onChange={e => {
                    let databasePayload = this.state.databasePayload;
                    databasePayload.dbPort = e.target.value;
                    this.setState({ databasePayload });
                }} />
            <input type="text" placeholder="DB Schema"
                value={this.state.databasePayload.dbSchema}
                onChange={e => {
                    let databasePayload = this.state.databasePayload;
                    databasePayload.dbSchema = e.target.value;
                    this.setState({ databasePayload });
                }} />
            <input type="text" placeholder="DB Username"
                value={this.state.databasePayload.dbUsername}
                onChange={e => {
                    let databasePayload = this.state.databasePayload;
                    databasePayload.dbUsername = e.target.value;
                    this.setState({ databasePayload });
                }} />
            <input type="password" placeholder="DB Password"
                value={this.state.databasePayload.dbPassword}
                onChange={e => {
                    let databasePayload = this.state.databasePayload;
                    databasePayload.dbPassword = e.target.value;
                    this.setState({ databasePayload });
                }} />

            <a className="btn" onClick={() => {
                this.handleSubmit();
            }}>Authorize</a>
        </div>
    }
}

export default withRouter(DatabaseLogin);