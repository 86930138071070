import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Logo from "../images/logo.png";
import api from "../services/ApiService";

class IndexNav extends Component {

    constructor(props) {
        super();
        this.state = {
            showUserPopup: false
        }
    }

    handleNavigation(path) {
        this.props.history.push(path);
    }


    render() {

        const history = this.props.history;
        let Integrations = true;
        let Admin = false;
        let monetisation = false;
        let embed = false;
        if (history.location.pathname == '/index/admin') {
            Integrations = false;
            Admin = true;
            monetisation = false;
            embed = false;
        } else if (history.location.pathname == '/index/monetisation') {
            Integrations = false;
            Admin = false;
            monetisation = true;
            embed = false;
        } else if (history.location.pathname == '/index/embed') {
            Integrations = false;
            Admin = false;
            monetisation = false;
            embed = true;
        }

        const userRole = sessionStorage.getItem("userRole");
        console.log(">>>>" + userRole);

        const userStr = sessionStorage.getItem("userProfile");
        let user = {};
        if (userStr) {
            user = JSON.parse(userStr);
        }

        let adminNav = null;
        //if (userRole === "ROLE_SUPER_USER" || userRole === "ROLE_COMPANY_ADMIN") {
        adminNav = <div className={"index__container__nav__item" + (Admin ? " index__container__nav__item--active" : "")}
            onClick={() => { this.handleNavigation('/index/admin') }}
        >
            <i className="material-icons">person_outline</i>
            <span className="index__container__nav__item__text">Users</span>
        </div>
        // }

        let monetisationNav = <div className={"index__container__nav__item" + (monetisation ? " index__container__nav__item--active" : "")}
            onClick={() => { this.handleNavigation('/index/monetisation') }}
        >
            <i className="material-icons">euro_symbol</i>
            <span className="index__container__nav__item__text">Monetisation</span>
        </div>

        let embedNav = <div className={"index__container__nav__item" + (embed ? " index__container__nav__item--active" : "")}
            onClick={() => { this.handleNavigation('/index/embed') }}
        >
            <i className="material-icons">add_to_queue</i>
            <span className="index__container__nav__item__text">Monetize</span>
        </div>
        return (
            <nav className="index__container__nav">
                <div className={"index__container__nav__item" + (Integrations ? " index__container__nav__item--active" : "")}
                    onClick={() => { this.handleNavigation('/index/integrations') }}
                >
                    <i className="material-icons index__container__nav__item__icon">
                        swap_calls
              </i>
                    <span className="index__container__nav__item__text">
                        Integrations
              </span>
                </div>
                {embedNav}
                <div className="index__container__logout" onClick={() => {

                    const showUserPopup = !this.state.showUserPopup;
                    this.setState({ ...this.state, showUserPopup })

                }}>
                    <span className="index__container__logout__avatar"><span>{user.firstname && user.firstname.charAt(0)}</span></span>
                    <span className="index__container__logout__user">{user.firstname}</span>
                    {this.state.showUserPopup && <span className="index__container__logout__user__popup"><span
                        onClick={() => {
                            sessionStorage.removeItem('accessToken');
                            let returnTo = encodeURIComponent(`${'https://www.n3wlab.com'}`);

                            window.location = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?returnTo=${returnTo}`
                        }
                        } className="index__container__logout__user__popup__text">Logout</span></span>}
                </div>
                {/* <div className="logout-wrapper">
                    <span className="btn logout-btn" onClick={() => {

                        sessionStorage.removeItem('accessToken');
                        let returnTo = encodeURIComponent(`${'https://www.n3wlab.com'}`);

                        window.location = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?returnTo=${returnTo}`
                    }}>Logout</span>
                </div> */}
            </nav>
        );
    }
}

export default withRouter(IndexNav);
