import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { StyleSheet, css } from 'aphrodite';
import api from '../services/ApiService';
import Loader from "./Loader";
import SyntaxHighlighter from 'react-syntax-highlighter';
import playImg from "../images/play.png"

class ErrorSummary extends Component {

    constructor() {
        super();
        this.state = {
            fromDate: "",
            toDate: "",
            load: false,
            transactions: [],
            showItem: false,
            selectedEvent: null,
            selectedItemType: null,
            selectedItemId: null,
            disableRetrigger: false
        }
    }

    componentDidMount() {
        const fromDate = new Date();
        const toDate = new Date();
        let date = '000' + fromDate.getDate();
        let month = '000' + (fromDate.getMonth() + 1);
        let year = fromDate.getFullYear();

        date = date.substr(-2)
        month = month.substr(-2)

        this.setState({ load: true, toDate: month + "/" + date + "/" + year, fromDate: month + "/" + date + "/" + year })

        this.invokeErrorGraphQL(fromDate, toDate);
    }


    invokeErrorGraphQL(fromDate, toDate) {
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setSeconds(0);
        fromDate.setMilliseconds(1);

        toDate.setHours(23);
        toDate.setMinutes(59);
        toDate.setSeconds(59);
        toDate.setMilliseconds(999);

        let formDay = '000' + fromDate.getUTCDate();
        let fromMonth = '000' + (fromDate.getUTCMonth() + 1);
        let fromYear = fromDate.getUTCFullYear();

        let fromHour = '000' + fromDate.getUTCHours();
        let fromMinute = '000' + fromDate.getUTCMinutes();
        let fromSeconds = '000' + fromDate.getUTCSeconds();

        formDay = formDay.substr(-2)
        fromMonth = fromMonth.substr(-2)
        fromHour = fromHour.substr(-2);
        fromSeconds = fromSeconds.substr(-2);
        fromMinute = fromMinute.substr(-2);

        let toDay = '000' + toDate.getUTCDate();
        let toMonth = '000' + (toDate.getUTCMonth() + 1);
        let toYear = toDate.getUTCFullYear();

        let toHour = '000' + toDate.getUTCHours();
        let toMinute = '000' + toDate.getUTCMinutes();
        let toSeconds = '000' + toDate.getUTCSeconds();



        toDay = toDay.substr(-2)
        toMonth = toMonth.substr(-2)
        toHour = toHour.substr(-2);
        toMinute = toMinute.substr(-2);
        toSeconds = toSeconds.substr(-2);

        let fromDateStr = `${fromYear}-${fromMonth}-${formDay} ${fromHour}:${fromMinute}:${fromSeconds}`;
        let toDateStr = `${toYear}-${toMonth}-${toDay} ${toHour}:${toMinute}:${toSeconds}`;



        this.setState({ load: true })
        const query = `{
            transactions(start_date:"${fromDateStr}",end_date:"${toDateStr}"){
                event_id
                integration_name
                transaction_id
                message_id
                message_status
                message_type
                updated_at
                message
                error_message
            }
        }
        `;
        api.processGraphQl(query).then(res => {
            this.setState({ load: false })
            if (res.status === 200 && res.data) {
                if (res.data.data && res.data.data.transactions) {
                    this.setState({ transactions: res.data.data.transactions })
                } else {
                    this.setState({ transactions: [] })
                }

            } else {
                console.log(JSON.stringify(res))
            }
        }).catch(err => {
            this.setState({ load: false })
            console.log(JSON.stringify(err));
        })

    }

    loadError() {
        const fromDate = new Date(this.state.fromDate);
        const toDate = new Date(this.state.toDate);

        if (isNaN(fromDate)) {
            return alert("Invalid start date")
        }

        if (isNaN(toDate)) {
            return alert("Invalid end date")
        }
        this.invokeErrorGraphQL(fromDate, toDate);

    }

    reTriggerEvent(event_id, message_id) {
        this.setState({ disableRetrigger: true, load: true })

        const query = `mutation{
            retriggerEvent(event_id:"${event_id}",message_id:"${message_id}"){
                status
            }
        }
        `;
        api.processGraphQl(query).then(res => {
            this.setState({ load: false })
            if (res.status === 200 && res.data) {
                this.setState({ disableRetrigger: false, load: false })
                if (res.data.data && res.data.data.retriggerEvent) {
                    alert(res.data.data.retriggerEvent.status)
                    //this.setState({ transactions: res.data.data.transactions })
                } else {
                    //this.setState({ transactions: [] })
                    alert("Unable to Retrigger")
                }

            } else {
                console.log(JSON.stringify(res))
            }
        }).catch(err => {
            this.setState({ disableRetrigger: false, load: false })
            this.setState({ load: false })
            console.log(JSON.stringify(err));
        })
    }

    render() {
        const transList = this.state.transactions.filter(transaction => {
            return transaction.message_status === 'PROCESSING_ERROR'
        }).map((transaction, idx) => {
            const dateTime = new Date();
            dateTime.setTime(transaction.updated_at);
            return <div key={idx} className={css(styles.tableRow)}>
                <span className={css(styles.tableRowError)}>{transaction.integration_name}</span>
                <span className={css(styles.tableRowEvent)} onClick={() => {
                    this.setState({ showItem: true, selectedEvent: transaction })
                }}>{transaction.event_id}</span>
                <span className={css(styles.tableRowType)}>{transaction.message_type}</span>
                <span className={css(styles.tableRowView)}>{dateTime.toLocaleString()}</span>
            </div>
        })

        const errorTable = <div className={css(styles.summaryTable)}>
            <div className={css(styles.tableHeader)}>
                <span className={css(styles.tableError)}>Integration</span>
                <span className={css(styles.tableType)}>Event</span>
                <span className={css(styles.tableType)}>Type</span>
                <span className={css(styles.tableView)}>Date</span>
            </div>
            {transList}
        </div>

        /**
         * Error Explanation 
         * 
         */
        let errorExp = null;


        if (this.state.showItem) {

            const singleEvent = this.state.transactions.filter(transaction => {
                return transaction.event_id === this.state.selectedEvent.event_id;
            })

            const triggers = singleEvent.filter(t => {
                return t.message_type === 'TRIGGER'
            });
            const actions = singleEvent.filter(t => {
                return t.message_type === 'ACTION'
            });
            const mappers = singleEvent.filter(t => {
                return t.message_type === 'MAPPER'
            });


            const triggerDate = new Date()
            console.log(triggers[triggers.length - 1].updated_at)
            triggerDate.setTime(triggers[triggers.length - 1].updated_at);

            const triggerComponents = triggers.map((trig, idx) => {
                let highlightClass = css(styles.errorExpItemHighlightSuccess);
                if (trig.message_status === "PROCESSING_ERROR") {
                    highlightClass = css(styles.errorExpItemHighlightError)
                }

                if (this.state.selectedItemId === idx && this.state.selectedItemType === "TRIGGER") {
                    return <div key={idx} className={css(styles.errorExpItem) + " " + highlightClass} >
                        <div className={css(styles.errorExpItemHeader)}>
                            <span className={css(styles.errorExpItemName)} onClick={() => {
                                if (this.state.selectedItemType === "TRIGGER" && this.state.selectedItemId === idx) {
                                    this.setState({ selectedItemId: null, selectedItemType: null })
                                } else {
                                    this.setState({ selectedItemId: idx, selectedItemType: "TRIGGER" })
                                }
                            }}>TRIGGER</span>
                            <img className={css(styles.errorPlayImg)} src={playImg} alt={"Re-trigger"} onClick={() => {
                                if (!this.state.disableRetrigger && window.confirm("Are you sure?")) {
                                    this.reTriggerEvent(trig.event_id, trig.message_id);
                                }
                            }}></img>
                        </div>
                        <span className={css(styles.errorExpItemExpandedHeader)}>Message</span>
                        <div className={css(styles.errorExpItemExpanded)}>
                            <SyntaxHighlighter wrapLines={true} language="javascript" customStyle={{ fontSize: "1.2rem", width: "100%", overflow: "auto" }}>
                                {trig.message || "No Message"}
                            </SyntaxHighlighter>
                        </div>

                        <span className={css(styles.errorExpItemExpandedHeader)}>Response/Error</span>
                        <div className={css(styles.errorExpItemExpanded)}>
                            <SyntaxHighlighter wrapLines={true} language="javascript" customStyle={{ fontSize: "1.2rem", width: "100%", overflow: "auto" }}>
                                {trig.error_message || "No Message"}
                            </SyntaxHighlighter>
                        </div>
                    </div>
                } else {
                    return <div key={idx} className={css(styles.errorExpItem) + " " + highlightClass} >
                        <div className={css(styles.errorExpItemHeader)}>
                            <span className={css(styles.errorExpItemName)} onClick={() => {
                                if (this.state.selectedItemType === "TRIGGER" && this.state.selectedItemId === idx) {
                                    this.setState({ selectedItemId: null, selectedItemType: null })
                                } else {
                                    this.setState({ selectedItemId: idx, selectedItemType: "TRIGGER" })
                                }
                            }}>TRIGGER</span>
                            <img className={css(styles.errorPlayImg)} src={playImg} alt={"Re-trigger"} onClick={() => {
                                if (!this.state.disableRetrigger && window.confirm("Are you sure?")) {
                                    this.reTriggerEvent(trig.event_id, trig.message_id);
                                }
                            }}></img>
                        </div>
                    </div>
                }

            });

            const actionComponents = actions.map((ac, idx) => {
                let highlightClass = css(styles.errorExpItemHighlightSuccess);
                if (ac.message_status === "PROCESSING_ERROR") {
                    highlightClass = css(styles.errorExpItemHighlightError)
                }
                if (this.state.selectedItemId === idx && this.state.selectedItemType === "ACTION") {
                    return <div key={idx} className={css(styles.errorExpItem) + " " + highlightClass} >
                        <div className={css(styles.errorExpItemHeader)}>
                            <span className={css(styles.errorExpItemName)} onClick={() => {
                                if (this.state.selectedItemType === "ACTION" && this.state.selectedItemId === idx) {
                                    this.setState({ selectedItemId: null, selectedItemType: null })
                                } else {
                                    this.setState({ selectedItemId: idx, selectedItemType: "ACTION" })
                                }
                            }}>ACTION</span>
                        </div>
                        <span className={css(styles.errorExpItemExpandedHeader)}>Message</span>
                        <div className={css(styles.errorExpItemExpanded)}>
                            <SyntaxHighlighter wrapLines={true} language="javascript" customStyle={{ fontSize: "1.2rem", width: "100%", overflow: "auto" }}>
                                {ac.message || "No Message"}
                            </SyntaxHighlighter>
                        </div>
                        <span className={css(styles.errorExpItemExpandedHeader)}>Response/Error</span>
                        <div className={css(styles.errorExpItemExpanded)}>
                            <SyntaxHighlighter wrapLines={true} language="javascript" customStyle={{ fontSize: "1.2rem", width: "100%", overflow: "auto" }}>
                                {ac.error_message || "No Message"}
                            </SyntaxHighlighter>
                        </div>
                    </div>
                } else {
                    return <div key={idx} className={css(styles.errorExpItem) + " " + highlightClass} onClick={() => {
                        if (this.state.selectedItemType === "ACTION" && this.state.selectedItemId === idx) {
                            this.setState({ selectedItemId: null, selectedItemType: null })
                        } else {
                            this.setState({ selectedItemId: idx, selectedItemType: "ACTION" })
                        }
                    }}>
                        <div className={css(styles.errorExpItemHeader)}>
                            <span className={css(styles.errorExpItemName)}>ACTION</span>
                        </div>
                    </div>
                }

            });

            const mapperComponents = mappers.map((mpr, idx) => {
                let highlightClass = css(styles.errorExpItemHighlightSuccess);
                if (mpr.message_status === "PROCESSING_ERROR") {
                    highlightClass = css(styles.errorExpItemHighlightError)
                }
                if (this.state.selectedItemId === idx && this.state.selectedItemType === "MAPPER") {
                    return <div key={idx} className={css(styles.errorExpItem) + " " + highlightClass} >
                        <div className={css(styles.errorExpItemHeader)}>
                            <span className={css(styles.errorExpItemName)} onClick={() => {
                                if (this.state.selectedItemType === "MAPPER" && this.state.selectedItemId === idx) {
                                    this.setState({ selectedItemId: null, selectedItemType: null })
                                } else {
                                    this.setState({ selectedItemId: idx, selectedItemType: "MAPPER" })
                                }
                            }}>MAPPER</span>
                        </div>
                        <span className={css(styles.errorExpItemExpandedHeader)}>Message</span>
                        <div className={css(styles.errorExpItemExpanded)}>
                            <SyntaxHighlighter wrapLines={true} language="javascript" customStyle={{ fontSize: "1.2rem", width: "100%", overflow: "auto" }}>
                                {mpr.message || "No Message"}
                            </SyntaxHighlighter>
                        </div>
                        <span className={css(styles.errorExpItemExpandedHeader)}>Response/Error</span>
                        <div className={css(styles.errorExpItemExpanded)}>
                            <SyntaxHighlighter wrapLines={true} language="javascript" customStyle={{ fontSize: "1.2rem", width: "100%", overflow: "auto" }}>
                                {mpr.error_message || "No Message"}
                            </SyntaxHighlighter>
                        </div>
                    </div>
                } else {
                    return <div key={idx} className={css(styles.errorExpItem) + " " + highlightClass} onClick={() => {
                        if (this.state.selectedItemType === "MAPPER" && this.state.selectedItemId === idx) {
                            this.setState({ selectedItemId: null, selectedItemType: null })
                        } else {
                            this.setState({ selectedItemId: idx, selectedItemType: "MAPPER" })
                        }
                    }}>
                        <span className={css(styles.errorExpItemName)}>MAPPER</span>
                    </div>
                }

            })


            errorExp = <div className={css(styles.errorExpContainer)}>
                <div className={css(styles.errorEventHeader)}>
                    <div className={css(styles.errorExpIntegrationNameWrap)}>
                        <span className={css(styles.errorExpIntegrationName)}>{this.state.selectedEvent.integration_name}</span>
                        <span className={css(styles.errorExpTriggerDate)}>{'Trigger Date : ' + triggerDate.toLocaleString()}</span>
                    </div>
                    <div className={css(styles.errorEventHeaderBack)}>
                        <span className="btn" onClick={() => {
                            this.setState({ showItem: false, selectedEvent: null })
                        }}>Back</span>
                    </div>

                </div>
                <div className={css(styles.errorExpItemContainer)}>
                    {triggerComponents}
                    {mapperComponents}
                    {actionComponents}
                </div>
            </div>

        }


        /**
        * *********************************************************************************************
        */


        return <> {this.state.load && <Loader></Loader>}{!this.state.showItem && <div>

            <div className={css(styles.dateInputHeader)}>
                <div className={css(styles.dateInputWrap)}>
                    <span className={css(styles.dateLabel)}>From</span>
                    <input value={this.state.fromDate}
                        onChange={val => {
                            this.setState({ fromDate: val.target.value })
                        }}
                        className={css(styles.input)} type="text" name="name" placeholder={"MM/DD/YYYY"} />
                </div>
                <div className={css(styles.dateInputWrap)}>
                    <span className={css(styles.dateLabel)}>To</span>
                    <input value={this.state.toDate}
                        onChange={val => {
                            this.setState({ toDate: val.target.value })
                        }}
                        className={css(styles.input)} type="text" name="name" placeholder={"MM/DD/YYYY"} />
                </div>
                <div className={css(styles.dateInputWrap)}>
                    <span className="btn" onClick={() => {
                        this.loadError();
                    }}>Load Errors</span>
                </div>

            </div>
            {errorTable}
        </div>}{
                this.state.showItem && errorExp
            }</>
    }
}

export default withRouter(ErrorSummary);


const styles = StyleSheet.create({

    dateInputHeader: {

        alignContent: "center",
        justifyContent: "center",
        width: "70%",
        margin: "auto"
    },

    dateInputWrap: {
        display: "inline-block",
        // width: "50%",
        padding: "20px"
    },
    loadBtn: {
        height: "40px"
    },
    dateLabel: {
        display: "inline-block",
        fontSize: "1.7rem",
        fontWeight: "600",
        marginRight: "20px"
    },
    input: {
        height: "40px",
        width: "200px",
        border: "1px solid rgb(180, 190, 198)",
        borderRadius: "3px",
        marginTop: "3rem",
        padding: "20px",
        fontSize: "1.7rem",
        fontStyle: "italic",
        fontWeight: "200",
        letterSpacing: "0.8px",
        zIndex: "200",
        outline: "none"
    },
    addNewFieldBox: {
        margin: "auto",
        display: "flex",
        flexFlow: "column",
        backgroundColor: "white",
        height: "50rem",
        width: "60rem",
        border: "solid 1px rgb(208,214,219)",
        borderRadius: ".2rem",
        padding: "4rem"
    },
    summaryTable: {
        boxSizing: "border-box",
        margin: "20px",
        border: "1px solid #F7F8F9",
        borderRadius: "5px",
    },
    tableHeader: {
        backgroundColor: "#F7F8F9",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "space-between",
        height: "40px",
        alignItems: "center"
    },
    tableError: {
        display: "inline-block",
        fontSize: "1.4rem",
        fontWeight: 600,
        padding: "20px",
        flex: 1
    },
    tableType: {
        display: "inline-block",
        fontSize: "1.4rem",
        padding: "20px",
        textAlign: "center",
        minWidth: "300px",
        fontWeight: 600,
    },
    tableView: {
        display: "inline-block",
        fontSize: "1.4rem",
        padding: "20px",
        textAlign: "center",
        fontWeight: 600,
        minWidth: "175px"
    },
    tableRow: {
        display: "flex",
        justifyContent: "space-between",
        minHeight: "40px",
        border: "1px solid #F7F8F9",
        alignItems: "center"
    },
    tableRowView: {
        display: "inline-block",
        fontSize: "1.4rem",
        padding: "20px",
        textAlign: "center",
        minWidth: "100px",
    },
    tableRowError: {
        display: "inline-block",
        fontSize: "1.4rem",
        padding: "20px",
        flex: 1
    },
    tableRowEvent: {
        display: "inline-block",
        fontSize: "1.4rem",
        padding: "20px",
        textAlign: "center",
        minWidth: "300px",
        cursor: "pointer"
    },
    tableRowType: {
        display: "inline-block",
        fontSize: "1.4rem",
        padding: "20px",
        textAlign: "center",
        minWidth: "300px"
    },
    errorExpContainer: {
        minHeight: "300px",
        padding: "30px",
        width: "100%"
    },
    errorEventHeader: {
        minHeight: "75px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    errorEventHeaderBack: {
        width: "100px",
        display: "inline-block"
    },
    errorExpIntegrationNameWrap: {
        display: "block",
        flex: 1
    },
    errorExpIntegrationName: {
        fontSize: "2rem",
        fontWeight: "600",
        display: "block",
    },
    errorExpTriggerDate: {
        marginTop: "10px",
        fontSize: "1.2rem",
        fontWeight: "600",
        display: "block",
    },
    errorExpItemContainer: {
        display: "block",
        width: "100%"
    },
    errorExpItem: {
        display: "flex",
        minHeight: "54px",
        backgroundColor: "#F7F8F9",
        marginTop: "20px",
        borderRadius: "5px",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: "30px",
        paddingRight: "30px",
        width: "100%"
    },
    errorExpItemHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "10px",
        marginBottom: "10px",
    },
    errorExpItemName: {
        fontSize: "1.4rem",
        lineHeight: "27px",
        letterSpacing: "1px",
        fontWeight: 500,
        cursor: "pointer"
    },
    errorExpItemHighlightError: {
        borderLeft: "5px solid red"
    },
    errorExpItemHighlightSuccess: {
        borderLeft: "5px solid #2AC940"
    },
    errorExpItemExpanded: {
        paddingBottom: "30px",
        maxWidth: "850px",
        overflow: "scroll",
        maxHeight: "300px"
    },
    errorExpItemExpandedHeader: {
        display: "inline-block",
        fontSize: "1.2rem",
        fontWeight: 500,
        marginBottom: "5px"
    },
    errorPlayImg: {
        height: "27px",
        width: "20px",
        cursor: "pointer"
    }
});

