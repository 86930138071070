import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import api from "../services/ApiService";
import gocanvasLogo from "../images/gocanvas.png";
import { stat } from "fs";

class GocanvasLogin extends Component {


    constructor(props) {
        super(props);
        this.state = {
            gocanvasUsername: "",
            gocanvasPassword: "",
            gocanvasApikey: "",
        }
    }



    handleSubmit() {

        let qs = this.props.location.search;
        qs = qs.replace("?", "");

        let qsSplit = qs.split("&");
        let state = ""
        let accessToken = ""
        qsSplit.map(q => {
            let keyValue = q.split("=");

            if (keyValue.length === 2) {

                if (keyValue[0] === "state") {
                    state = keyValue[1];
                }

                if (keyValue[0] === "userJWT") {
                    accessToken = keyValue[1];
                }
            }
        })

        // alert(state);


        let data = {
            gocanvasUsername: this.state.gocanvasUsername,
            gocanvasPassword: this.state.gocanvasPassword,
            gocanvasApikey: this.state.gocanvasApikey,
        }

        api.gocanvasLogin(data, state, accessToken).then(res => {
            localStorage.setItem("connectionAuthStatus", 200);
            localStorage.setItem("connectionPayload", JSON.stringify(res.data));
            window.close();
        }).catch(err => {
            alert("Invalid username/password");
        })
    }
    render() {
        return <div className="gocanvas-login">
            <img height={"65"} width="65" src={gocanvasLogo} alt="GoCanvas"></img>
            <input type="text" placeholder="Gocanvas Username"
                value={this.state.gocanvasUsername}
                onChange={e => { this.setState({ gocanvasUsername: e.target.value }) }} />

            <input type="text" placeholder="Gocanvas Apikey"
                value={this.state.gocanvasApikey}
                onChange={e => { this.setState({ gocanvasApikey: e.target.value }) }} />

            <div style={{ display: "flex", alignItems: "centre", justifyContent: "centre" }}>
                <span>OR</span>
            </div>

            <input type="password" placeholder="Gocanvas Password"
                value={this.state.gocanvasPassword}
                onChange={e => { this.setState({ gocanvasPassword: e.target.value }) }} />


            <span className="btn" onClick={() => {
                this.handleSubmit();
            }}>Authorize</span>
        </div>
    }
}

export default withRouter(GocanvasLogin);