import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import NotificationAlert from "./NotificationAlert"
import api from "../services/ApiService"

class NewIntegrationOverlay extends Component {

    constructor() {
        super();

        this.state = {
            integrationName: "",
            notificationAlert: { type: "", message: "" }
        }
    }


    handleNewIntegration() {
        api.createNewIntegration(this.state.integrationName).then(res => {
            console.log(res)
            if (res.status == 200 && res.data) {
                sessionStorage.setItem("integrationId", res.data.integrationId);
                this.props.history.push("/builder");
            }
        }).catch(err => {
            console.log(err)
            this.setState({ notificationAlert: { type: "warning", message: "error occurred while creating integration. try again later." } })
            setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
        })
    }

    handleClose() {
        this.props.history.push("/")
    }

    render() {
        return (
            <div className="new-integration-overlay" >
                <NotificationAlert notificationAlert={this.state.notificationAlert}></NotificationAlert>
                <i className="material-icons new-integration-overlay--close" onClick={() => {
                    this.handleClose();
                }}>close</i>

                <div className="new-integration-overlay--form">
                    <span className="new-integration-overlay--form--title">Name your new integration</span>
                    <input className="new-integration-overlay--form--input" value={this.state.integrationName}
                        onChange={(e) => {
                            this.setState({ integrationName: e.target.value })
                        }}></input>
                    <a className="btn" onClick={() => { this.handleNewIntegration() }}>Create</a>
                </div>
            </div>
        );
    }
}

export default withRouter(NewIntegrationOverlay);
