import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Logo from "../images/logo.png";

class IntegrationListHeader extends Component {


    render() {


        // <div className={"integration-list--header--item" + (tab == "ALL" ? " integration-list--header--item__active" : "")}
        //             onClick={() => { this.props.onTabChange("ALL") }}>
        //             <div className="integration-list--header--item--wrapper">
        //                 <span>ALL</span>
        //                 <span className="integration-list--header--item--count">{this.props.publishedCount + this.props.inProgressCount}</span></div>
        //         </div>
        const tab = this.props.selectedTab;
        return (
            <header className="integration-list--header">

                <div className={"integration-list--header--item" + (tab == "IN PROGRESS" ? " integration-list--header--item__active" : "")}
                    onClick={() => { this.props.onTabChange("IN PROGRESS") }}>
                    <div className="integration-list--header--item--wrapper">  <span >IN Progress</span>
                        <span className="integration-list--header--item--count ">{this.props.inProgressCount}</span></div>
                </div>
                <div className={"integration-list--header--item" + (tab == "PUBLISHED" ? " integration-list--header--item__active" : "")}
                    onClick={() => { this.props.onTabChange("PUBLISHED") }}>
                    <div className="integration-list--header--item--wrapper">
                        <span>Published</span>
                        <span className="integration-list--header--item--count">{this.props.publishedCount}</span>
                    </div>
                </div>
                <div className={"integration-list--header--item" + (tab == "TEMPLATES" ? " integration-list--header--item__active" : "")}
                    onClick={() => { this.props.onTabChange("TEMPLATES") }}>
                    <div className="integration-list--header--item--wrapper">
                        <span>Embedded Integrations</span>
                        <span className="integration-list--header--item--count">{this.props.templatesCount}</span>
                    </div>
                </div>
                <div className={"integration-list--header--item" + (tab == "ERRORS" ? " integration-list--header--item__active" : "")}
                    onClick={() => { this.props.onTabChange("ERRORS") }}>
                    <div className="integration-list--header--item--wrapper">
                        <span>Errors</span>
                        {/* <span className="integration-list--header--item--count">{this.props.templatesCount}</span> */}
                    </div>
                </div>
            </header>
        );
    }
}

export default withRouter(IntegrationListHeader);
