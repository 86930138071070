import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import DropDown from "./DropDown";
import Select from "./Select";

class SelectTrigger extends Component {


    constructor(props) {
        super(props);
        this.state = {
            searchText: ""
        }
    }

    handleItemClick(trigger) {
        this.props.onTriggerTypeSelect && this.props.onTriggerTypeSelect(trigger);
    }
    dropdownTextChange(e) {
        this.setState({ searchText: e.target.value });
    }

    handleItemDelete() {
        this.props.clearField && this.props.clearField();
    }

    render() {
        const selectedTrigger = this.props.selectedTrigger;
        const userInput = selectedTrigger ? selectedTrigger.userInput : false;
        let userInputForm = null;
        if (userInput && selectedTrigger.userInputSchema) {
            let userInputSchema = selectedTrigger.userInputSchema;
            let fieldNames = Object.keys(userInputSchema.properties);
            userInputForm = fieldNames.map(fieldName => {
                if (userInputSchema.properties[fieldName].enum) {
                    const inputHtmlBlock = this.props.selectedTrigger.userInputObject &&
                        this.props.selectedTrigger.userInputObject[fieldName] ? <div className="select-active-item">
                            <span className="trigger--dropdown--item--text">{
                                userInputSchema.properties[fieldName].enum.filter(e => {
                                    return e.key === this.props.selectedTrigger.userInputObject[fieldName]
                                })[0].value}
                            </span>
                        </div> : null;
                    return <div key={fieldName} className="builder__content__section__item">
                        <label className="builder__content__section__item__label">
                            {userInputSchema.properties[fieldName].title}
                        </label>

                        <Select
                            htmlBlock={inputHtmlBlock}
                            items={userInputSchema.properties[fieldName].enum}
                            itemRenderer={inputEnum => {
                                return (<div key={inputEnum.key} className="trigger-select--dropdown--item" >
                                    <div className="trigger--dropdown--item--text">
                                        <span className="title">{inputEnum.value}</span>
                                        <span className="secondary">{inputEnum.key}</span>
                                    </div>
                                </div>);
                            }}
                            onItemSelect={(item) => {
                                //this.handleItemClick(item);
                                this.props.userInputChange && this.props.userInputChange(fieldName, item.key)
                            }}
                            filterField={'value'}
                            onItemClear={() => {
                                // this.handleItemDelete();
                                this.props.userInputChange && this.props.userInputChange(fieldName, undefined)
                            }}
                        ></Select></div>;
                } else {
                    return <div key={fieldName} className="builder__content__section__item">
                        <label className="builder__content__section__item__label">
                            {userInputSchema.properties[fieldName].title}
                        </label>
                        <input className="builder__content__section__item__input" type="text"
                            placeholder={userInputSchema.properties[fieldName].placeholder}
                            value={this.props.selectedTrigger.userInputObject &&
                                this.props.selectedTrigger.userInputObject[fieldName]}
                            onChange={e => { this.props.userInputChange && this.props.userInputChange(fieldName, e.target.value) }} />
                    </div>;
                }

            });
        }

        const appList = this.props.triggers ? this.props.triggers.map(trigger => {
            return (<div key={trigger.triggerName} className="trigger-select--dropdown--item" onClick={() => {
                this.handleItemClick(trigger);
            }}>
                <div className="trigger--dropdown--item--text">
                    <span className="title">{trigger.triggerName}</span>
                    <span className="secondary">{"When object " + trigger.displayName}</span>
                </div>
            </div>);
        }) : null;

        let htmlBlock = null;
        if (this.props.selectedTrigger) {
            htmlBlock = <div className="select-active-item">
                <span className="trigger--dropdown--item--text">{this.props.selectedTrigger.displayName}</span>

            </div>
        }
        let selectView = <Select
            htmlBlock={htmlBlock}
            items={this.props.triggers}
            itemRenderer={trigger => {
                return (<div key={trigger.triggerName} className="trigger-select--dropdown--item" >
                    <div className="trigger--dropdown--item--text">
                        <span className="title">{trigger.displayName}</span>
                        <span className="secondary">{"When object " + trigger.displayName}</span>
                    </div>
                </div>);
            }}
            onItemSelect={(item) => {
                this.handleItemClick(item);
            }}
            filterField={'displayName'}
            onItemClear={() => {
                this.handleItemDelete();
            }}
        ></Select>

        const searchText = this.props.searchText ? this.props.searchText : this.state.searchText;
        return (
            <section className="builder__content__section">
                <div className="builder__content__section__item">
                    <label className="builder__content__section__item__label">
                        Trigger
                </label>
                    {selectView}
                </div>
                {userInputForm}
            </section>
        );
    }
}

export default withRouter(SelectTrigger);
