import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import NotificationAlert from "../components/NotificationAlert"
import DropDown from "../components/DropDown"
import api from "../services/ApiService";

class AddNewUser extends Component {

    constructor() {
        super();

        this.state = {
            user: {
                firstname: "",
                lastname: "",
                email: "",
                planCode: "",
                userId: null
            },
            plans: [],
            notificationAlert: { type: "", message: "" }
        }
    }

    handleClose() {
        this.props.history.push("/index/embed");
    }

    handleSubmit() {
        const user = this.state.user;
        if (!user.firstname) {
            return alert("name cannot be empty");
        }
        if (!user.email) {
            return alert("email cannot be empty");
        }
        if (!user.planCode) {
            return alert("planCode cannot be empty");
        }

        // alert(JSON.stringify(this.state.user))
        let userObj = {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            planCode: user.planCode
        }
        api.postUser(userObj).then(res => {
            if (res.status == 200) {
                alert("user saved successfully")
                //this.setState({ users: res.data });
                this.props.history.push("/index/embed");
            } else {
                console.log(JSON.stringify(res))
            }
        }).catch(err => {
            console.log(JSON.stringify(err));
        })
    }

    componentDidMount() {
        const userStr = localStorage.getItem("editUser");
        if (userStr) {
            const user = JSON.parse(userStr);
            if (user.userId) {
                this.setState({
                    ...this.state, user: {
                        firstname: user.firstname,
                        lastname: user.lastname,
                        planCode: user.planCode,
                        email: user.email,
                        userId: user.userId
                    }
                })
            }
        }
        api.getOrgPlans().then(res => {
            if (res.data) {
                this.setState({ ...this.state, plans: res.data });
            }
        }).catch(err => {

        })
    }


    render() {

        let planList = this.state.plans.map(plan => {
            return <div className="new-user-overlay--form--content--dropdown-item" onClick={() => {
                let user = this.state.user;
                user.planCode = plan.planCode;
                this.setState({ ...this.state, user });
            }}>
                <span>{plan.planName}</span>
            </div>
        })
        return (
            <div className="new-user-overlay" >
                <NotificationAlert notificationAlert={this.state.notificationAlert}></NotificationAlert>


                <div className="new-user-overlay--form">
                    <div className="new-user-overlay--form--header">
                        <span className="new-user-overlay--form--header--text">Add User</span>
                        <i className="material-icons new-user-overlay--form--header--icon" onClick={() => {
                            this.handleClose();
                        }}>close</i>
                    </div>

                    <div className="new-user-overlay--form--content">
                        <div className="new-user-overlay--form--content--inputbox">
                            <span className="new-user-overlay--form--content--label">Firstname</span>
                            <input className="new-user-overlay--form--content--input" onChange={e => {
                                let user = this.state.user;
                                user.firstname = e.target.value;
                                this.setState({ ...this.state, user })
                            }} value={this.state.user.firstname} ype="text"></input>
                        </div>
                        <div className="new-user-overlay--form--content--inputbox">
                            <span className="new-user-overlay--form--content--label">Lastname</span>
                            <input className="new-user-overlay--form--content--input" onChange={e => {
                                let user = this.state.user;
                                user.lastname = e.target.value;
                                this.setState({ ...this.state, user })
                            }} value={this.state.user.lastname} type="text"></input>
                        </div>

                        <div className="new-user-overlay--form--content--inputbox">
                            <span className="new-user-overlay--form--content--label">Email</span>
                            <input className="new-user-overlay--form--content--input" onChange={e => {
                                let user = this.state.user;
                                user.email = e.target.value;
                                this.setState({ ...this.state, user })
                            }} value={this.state.user.email} type="text"></input>
                        </div>

                        <div className="new-user-overlay--form--content--inputbox">
                            <span className="new-user-overlay--form--content--label">Plan</span>
                            <DropDown searchText={this.state.user.planCode} className="new-user-overlay--form--content--dropdown" type="text">

                                {planList}
                            </DropDown>
                        </div>


                        <div className="new-user-overlay--form--content--inputbox">
                            <a className="btn" onClick={() => {
                                this.handleSubmit();
                            }}>save</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AddNewUser);
