import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { StyleSheet, css } from 'aphrodite';


class Home extends Component {

    render() {

        return <div className={css(styles["body"])}>
            <header className={css(styles["header"])}>
                <img alt={"logo"} className={css(styles["header__logo"])} src="./logo.png"></img>
                <div className={css(styles["header__brand"])}><span className={css(styles["header__brand__text"])}>N3W Lab</span></div>
                <div className={css(styles["header__nav"])}>
                    <span className={css(styles["header__nav__item"])}>pricing</span>
                    <span className={css(styles["header__nav__item"])}>contact us</span>
                </div>
                <span className={css(styles["btn"]) + " " + css(styles["login-btn"])} onClick={() => {
                    this.props.history.push("/login");
                }}>Login</span>
            </header>

            <h1 className={css(styles["heading"])}>
                <span className={css(styles["heading__primary"])}>Integrate. Automate. Innovate. </span>
                <span className={css(styles["heading__secondary"])}>Unlock your applications potential with embedded integrations.</span>
            </h1>
            <div className={css(styles["demo_wrapper"])}><span className={css(styles["btn"]) + " " + css(styles["demo_btn"])}>Request Demo</span></div>

            <footer className={css(styles["footer"])}>
                <span className={css(styles["footer__text"])}>© 2019 N3W LAB LLC · All rights reserved</span>
            </footer>
        </div>
    }
}

export default withRouter(Home);

const styles = StyleSheet.create({

    body: {
        paddingTop: "16px",
        paddingLeft: "16px",
        paddingRight: "16px",
        position: "relative",
        height: "1024px",
        /* width: 1440px; */
        background: "linear-gradient(321.75deg, rgba(46, 160, 248, 0.6) 0%, rgba(0, 101, 179, 0.6) 100%), url('./home_bg.jpg')"
    },

    header: {
        display: "flex",
        flexFlow: "row",
        alignItems: "center",
        marginTop: "16px"
    },

    header__logo: {
        width: "60px",
        height: "32px"
    },

    header__brand: {
        marginLeft: "16px"
    },

    header__brand__text: {
        color: "#38FBA1",
        fontSize: "3rem",
        fontWeight: "700",
        display: "inline-block",
        letterSpacing: ".3rem"
    },

    header__nav: {
        marginLeft: "39px"
    },

    header__nav__item: {
        display: "inlineBlock",
        marginLeft: "32px",
        textTransform: "uppercase",
        fontSize: "1.2rem",
        color: "white",
        fontWeight: "600",
        letterSpacing: ".2rem",
        cursor: "pointer"
    },

    btn: {
        display: "inline-block",
        textTransform: "uppercase",
        backgroundColor: "rgb(31, 218, 104)",
        /*#38fba1;*/
        fontSize: "1.15rem",
        fontWeight: "700",
        color: "white",
        letterSpacing: "0.8px",
        borderRadius: "2rem",
        textDecoration: "none",
        cursor: "pointer",
        ":hover": {
            boxShadow: "0px 0px 20px 1px rgb(130, 148, 161)"
        },

        ":active": {
            boxShadow: "0px 0px 20px 1px rgb(130, 148, 161)"
        }
    },



    "login-btn": {
        display: "inline-block",
        borderRadius: "24px",
        backgroundColor: "#38FBA1",
        color: "#0D61A1",
        padding: "15px 45px",
        textTransform: "uppercase",
        fontSize: "1.5rem",
        fontWeight: "600",
        letterSpacing: "0.8px",
        textDecoration: "none",
        cursor: "pointer",
        marginLeft: "auto",
        marginRight: "16px"
    },

    heading: {
        color: "white",
        display: "block",
        marginTop: "195px",
        display: "block",
        textAlign: "center"
    },

    heading__primary: {
        fontSize: "5.3rem",
        letterSpacing: ".3rem",
        display: "inline-block"
    },

    heading__secondary: {
        fontSize: "2.5rem",
        display: "inline-block",
        letterSpacing: ".2rem",
        marginTop: "1rem"
    },
    demo_wrapper: {
        marginTop: "88px",
        display: "block",
        textAlign: "center"
    },
    demo_btn: {
        display: "inline-block",
        borderRadius: "24px",
        backgroundColor: "#38FBA1",
        color: "#0D61A1",
        padding: "15px 45px",
        textTransform: "uppercase",
        fontSize: "1.5rem",
        fontWeight: "600",
        letterSpacing: "0.8px",
        textDecoration: "none",
        cursor: "pointer",

    },

    footer: {
        position: "absolute",
        right: "0",
        bottom: "0",
        left: "0",
        padding: "32px",
        textAlign: "center",
        boxShadow: "inset 0 1px 0 0 #052944",
        marginLeft: "16px",
        marginRight: "16px"
    },

    footer__text: {
        color: "white",
        fontSize: "13px",
        fontWeight: "300",
        lineHeight: "16px"
    }
})
