const convertToNumberingScheme = (number) => {
    var baseChar = ("A").charCodeAt(0),
        letters = "";

    do {
        number -= 1;
        letters = String.fromCharCode(baseChar + (number % 26)) + letters;
        number = (number / 26) >> 0; // quick `floor`
    } while (number > 0);

    return letters;
}

export const getGoogleSchema = (rows) => {
    let schema = {
        "type": "object",
        "title": "Google Sheet",
        "properties": {
            "Row": {
                "type": "array",
                "title": "Row",
                "items": {
                    "type": "object",
                    "title": "Row",
                    "properties": {}
                }
            }
        }
    }

    // eslint-disable-next-line array-callback-return
    rows[0].map((column, idx) => {
        const chr = convertToNumberingScheme(idx + 1);
        schema.properties.Row.items.properties[chr] = {
            "type": "string",
            "title": column,
            "description": " "
        }
    })
    return schema;
};


export const getGSheetColumns = (rows) => {
    let schema = []

    // eslint-disable-next-line array-callback-return
    schema = rows[0].map((column, idx) => {
        const chr = convertToNumberingScheme(idx + 1);

        return { key: chr, value: column };
    })
    return schema;
};