import React, { Component } from "react";
import { withRouter, Route } from "react-router-dom";
import GocanvasLogin from "../components/GocanvasLogin";
import DatabaseLogin from "../components/DatabaseLogin";
import PostgresLogin from "../components/PostgresLogin";
import QBDLogin from "../components/QBDLogin";
import SpectrumLogin from "../components/SpectrumLogin";
import NetSuiteLogin from "../components/NetSuiteLogin";
import MySqlLogin from "../components/MySqlLogin";
import MSSqlLogin from "../components/MSSqlLogin";
import SapBydLogin from "../components/SapBydLogin";
import QuickBaseLogin from "../components/QuickBaseLogin";

class Connect extends Component {


    constructor(props) {
        super(props);
    }
    render() {



        return (<div>
            <Route path="/connect/GOCANVAS" component={() => {
                return <GocanvasLogin></GocanvasLogin>
            }}></Route>
            <Route path="/connect/DBC" component={() => {
                return <DatabaseLogin></DatabaseLogin>
            }}></Route>
            <Route path="/connect/POSTGRES" component={() => {
                return <PostgresLogin></PostgresLogin>
            }}></Route>
            <Route path="/connect/MYSQL" component={() => {
                return <MySqlLogin></MySqlLogin>
            }}></Route>
            <Route path="/connect/MSSQL" component={() => {
                return <MSSqlLogin></MSSqlLogin>
            }}></Route>
            <Route path="/connect/QBD" component={() => {
                return <QBDLogin></QBDLogin>
            }}></Route>
            <Route path="/connect/SPECTRUM" component={() => {
                return <SpectrumLogin></SpectrumLogin>
            }}></Route>
            <Route path="/connect/NETSUITE" component={() => {
                return <NetSuiteLogin></NetSuiteLogin>
            }}></Route>
            <Route path="/connect/SAPBYD" component={() => {
                return <SapBydLogin></SapBydLogin>
            }}></Route>
            <Route path="/connect/QBASE" component={() => {
                return <QuickBaseLogin></QuickBaseLogin>
            }}></Route>

        </div>)
    }
}

export default withRouter(Connect);