import qbo from "../images/qbo.png";
import sfdc from "../images/sfdc.png";
import gocanvas from '../images/gocanvas.png'
import dbc from "../images/dbc.png"
import postgres from "../images/postgres.png"
import mssql from "../images/mssql.png"
import gsheets from "../images/gsheet.png"
import procore from "../images/procore.png"
import spectrum from "../images/spectrum.png"
import netsuite from "../images/netsuite.png"
import sapbyd from "../images/sapbyd.jpg"
import quickbase from "../images/quickbase.png"

class LogoService {

    getLogoForApp(appName) {

        const logos = {
            "QBO": qbo,
            "SFDC": sfdc,
            "GOCANVAS": gocanvas,
            "DBC": dbc,
            "QBD": qbo,
            "MSSQL": mssql,
            "MYSQL": dbc,
            "ORACLE": dbc,
            "POSTGRES": postgres,
            "GSHEET": gsheets,
            "PROCORE": procore,
            "SPECTRUM": spectrum,
            "NETSUITE": netsuite,
            "SAPBYD": sapbyd,
            "QBASE": quickbase
        }

        return logos[appName];
    }

    getBGColorForApp(appName) {

        const logos = {
            "QBO": "",
            "SFDC": "",
            "GOCANVAS": "",
            "DBC": "",
            "QBD": "",
            "MSSQL": "",
            "MYSQL": "",
            "ORACLE": "",
            "POSTGRES": "",
            "GSHEET": "",
            "PROCORE": "",
            "SPECTRUM": "",
            "NETSUITE": "",
            "QBASE": ""
        }

        return logos[appName];
    }

}

export default new LogoService();