import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Logo from "../images/logo.png";
import IntegrationListHeader from "../components/IntegrationListHeader";
import IntegrationListItem from "../components/IntegrationListItem";
import api from '../services/ApiService';

class UserList extends Component {


    constructor(props) {
        super(props);
        this.state = {
            users: []
        }
    }
    componentDidMount() {
        api.getAllUsers().then(res => {
            if (res.status == 200 && res.data) {
                this.setState({ users: res.data });
            } else {
                console.log(JSON.stringify(res))
            }
        }).catch(err => {
            console.log(JSON.stringify(err));
        })
    }


    handleDelete(user) {
        const userConfirm = window.confirm("Confirm user delete");
        userConfirm && api.deleteUser(user.id).then(res => {
            api.getAllUsers().then(resp => {
                if (resp.status == 200 && resp.data) {
                    this.setState({ users: resp.data });
                } else {
                    console.log(JSON.stringify(resp))
                }
            }).catch(err => {
                console.log(JSON.stringify(err));
            })
        }).catch(err => {

        })
    }

    handleEdit(user) {
        this.props.history.push("/index/admin/edit-user/" + user.id);
    }
    render() {

        const usersList = this.state.users.map(user => {
            return <div key={user.id} className="user-list--items">
                <span className={"user-list--item--value user-list--item--value__name"}>{user.name}</span>
                <span className={"user-list--item--value user-list--item--value__email"}>{user.email}</span>
                <span className={"user-list--item--value user-list--item--value__role"}>{user.roles && user.roles.length > 0 ? user.roles[0].name : ""}</span>
                <span className={"user-list--item--value user-list--item--value__icount"}>{user.userProperty ? user.userProperty.allowedIntegration : ""}</span>
                <span className={"user-list--item--value user-list--item--value__action"}>
                    <i class="material-icons" onClick={() => {
                        this.handleEdit(user);
                    }}>edit</i>
                    <i class="material-icons" onClick={() => {
                        this.handleDelete(user);
                    }}>delete</i>
                </span>
            </div>
        })


        return <div className="user-list">
            <header className="user-list--header">
                <div className={"user-list--header--item user-list--header--item__name"}>Name</div>
                <div className={"user-list--header--item user-list--header--item__email"}>Email</div>
                <div className={"user-list--header--item user-list--header--item__role"}>Role</div>
                <div className={"user-list--header--item user-list--header--item__icount"}>Max Integration</div>
                <div className={"user-list--header--item user-list--header--item__action"}>Action</div>
            </header>
            {usersList}
        </div>;
    }

}

export default withRouter(UserList);