import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class BuilderHeader extends Component {


    navigateBack() {
        this.props.history.push("/index/integrations")
    }

    render() {
        return (
            <header className="builder__header">
                <span className="builder__header__text">{this.props.integrationName}</span>
                <i className="material-icons builder__header__close" onClick={() => {
                    this.navigateBack()
                }}>close</i>
            </header>
        );
    }
}

export default withRouter(BuilderHeader);
