import React, { Component } from "react";
import { withRouter, Route } from "react-router-dom";
import sfdcLogo from "../images/sfdc.png";

class BuilderProgressBar extends Component {

    render() {

        const stepsDone = this.props.stepsDone ? this.props.stepsDone : {};
        const stepInprogress = this.props.stepInprogress ? this.props.stepInprogress : 1;


        return (
            <div className="builder__content__progress">
                <div className="builder__content__progress__bar">
                    <i className={"material-icons" + (stepsDone["1"] ? " builder__content__progress__circle--done" : " builder__content__progress__circle") + (stepInprogress == 1 ? " builder__content__progress__circle--active" : "")}>
                        {stepsDone["1"] ? "check_circle" : "brightness_1"}
                    </i>
                    <div className={"builder__content__progress__line  " + (stepsDone["1"] ? "builder__content__progress__line--done" : "")} />

                    <i className={"material-icons" + (stepsDone["2"] ? " builder__content__progress__circle--done" : " builder__content__progress__circle") + (stepInprogress == 2 ? " builder__content__progress__circle--active" : "")}>
                        {stepsDone["2"] ? "check_circle" : "brightness_1"}
                    </i>
                    <div className={"builder__content__progress__line  " + (stepsDone["2"] ? "builder__content__progress__line--done" : "")} />

                    <i className={"material-icons" + (stepsDone["3"] ? " builder__content__progress__circle--done" : " builder__content__progress__circle") + (stepInprogress == 3 ? " builder__content__progress__circle--active" : "")}>
                        {stepsDone["3"] ? "check_circle" : "brightness_1"}
                    </i>
                    <div className={"builder__content__progress__line  " + (stepsDone["3"] ? "builder__content__progress__line--done" : "")} />

                    <i className={"material-icons" + (stepsDone["4"] ? " builder__content__progress__circle--done" : " builder__content__progress__circle") + (stepInprogress == 4 ? " builder__content__progress__circle--active" : "")}>
                        {stepsDone["4"] ? "check_circle" : "brightness_1"}
                    </i>
                    <div className={"builder__content__progress__line  " + (stepsDone["4"] ? "builder__content__progress__line--done" : "")} />

                    <i className={"material-icons" + (stepsDone["5"] ? " builder__content__progress__circle--done" : " builder__content__progress__circle") + (stepInprogress == 5 ? " builder__content__progress__circle--active" : "")}>
                        {stepsDone["5"] ? "check_circle" : "brightness_1"}
                    </i>
                    <div className={"builder__content__progress__line  " + (stepsDone["5"] ? "builder__content__progress__line--done" : "")} />

                    <i className={"material-icons" + (stepsDone["6"] ? " builder__content__progress__circle--done" : " builder__content__progress__circle") + (stepInprogress == 6 ? " builder__content__progress__circle--active" : "")}>
                        {stepsDone["6"] ? "check_circle" : "brightness_1"}
                    </i>
                    <div className={"builder__content__progress__line  " + (stepsDone["6"] ? "builder__content__progress__line--done" : "")} />

                    <i className={"material-icons" + (stepsDone["7"] ? " builder__content__progress__circle--done" : " builder__content__progress__circle") + (stepInprogress == 7 ? " builder__content__progress__circle--active" : "")}>
                        {stepsDone["7"] ? "check_circle" : "brightness_1"}
                    </i>
                    <div className={"builder__content__progress__line  " + (stepsDone["7"] ? "builder__content__progress__line--done" : "")} />


                    <i className={"material-icons" + (stepsDone["8"] ? " builder__content__progress__circle--done" : " builder__content__progress__circle") + (stepInprogress == 8 ? " builder__content__progress__circle--active" : "")}>
                        {stepsDone["8"] ? "check_circle" : "brightness_1"}
                    </i>
                    <div className={"builder__content__progress__line  " + (stepsDone["8"] ? "builder__content__progress__line--done" : "")} />

                    <i className={"material-icons" + (stepsDone["9"] ? " builder__content__progress__circle--done" : " builder__content__progress__circle") + (stepInprogress == 9 ? " builder__content__progress__circle--active" : "")}>
                        {stepsDone["9"] ? "check_circle" : "brightness_1"}
                    </i>
                </div>
                <div className="builder__content__progress__text">
                    <span
                        className="builder__content__progress__text__item"
                        style={{ left: this.props.step * 80 - 80 }}
                    >
                        {this.props.stepTitle}
                    </span>
                </div>
            </div>
        );
    }
}

export default withRouter(BuilderProgressBar);
