import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import api from "../services/ApiService";
import LogService from "../services/LogoService"

class DatabaseLogin extends Component {



    // String databaseType = payload.get("databaseType");
    // String databaseName = payload.get("databaseName");
    // String dbHost = payload.get("dbHost");
    // String dbPassword = payload.get("dbPassword");
    // String dbPort = payload.get("dbPort");
    // String dbSchema = payload.get("dbSchema");
    // String dbUsername = payload.get("dbUsername");
    // String ssl = payload.get("ssl");

    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            databasePayload: {
                databaseType: "MYSQL",
                databaseName: "",
                dbHost: "",
                dbPassword: "",
                dbPort: "",
                dbSchema: "",
                dbUsername: "",
                ssl: "false"
            }
        }
    }

    /*
    <div className="dbc-login__item">
                    <label className="dbc-login--item__label">
                        Database Type
                    </label>
                    <DropDown searchText={this.state.searchText} searchTextChange={(e) => { this.dropdownTextChange(e) }}>{appList}</DropDown>
                </div>
    
    
    */
    handleSubmit() {

        let qs = this.props.location.search;
        qs = qs.replace("?", "");

        let qsSplit = qs.split("&");
        let state = ""
        let accessToken = ""
        qsSplit.map(q => {
            let keyValue = q.split("=");

            if (keyValue.length === 2) {

                if (keyValue[0] === "state") {
                    state = keyValue[1];
                }

                if (keyValue[0] === "userJWT") {
                    accessToken = keyValue[1];
                }
            }
        })

        let data = this.state.databasePayload;
        api.mysqlLogin(data, state, accessToken).then(res => {
            localStorage.setItem("connectionAuthStatus", 200);
            localStorage.setItem("connectionPayload", JSON.stringify(res.data));
            window.close();
        }).catch(err => {
            alert("Unable to connect to db. Please verify the DB details");
        })
    }

    handleItemClick(databaseType) {
        let databasePayload = this.state.databasePayload;
        databasePayload.databaseType = databaseType.typeName;
        this.setState({ searchText: databaseType.displayName, databasePayload })

    }
    render() {
        return <div className="dbc-login">
            <img className="dbc-login--img" height={"65"} width="65" src={LogService.getLogoForApp("MYSQL")}></img>


            <input type="text" placeholder="Database Name"
                value={this.state.databasePayload.databaseName}
                onChange={e => {
                    let databasePayload = this.state.databasePayload;
                    databasePayload.databaseName = e.target.value;
                    this.setState({ databasePayload });
                }} />
            <input type="text" placeholder="Database Host"
                value={this.state.databasePayload.dbHost}
                onChange={e => {
                    let databasePayload = this.state.databasePayload;
                    databasePayload.dbHost = e.target.value;
                    this.setState({ databasePayload });
                }} />
            <input type="text" placeholder="Port"
                value={this.state.databasePayload.dbPort}
                onChange={e => {
                    let databasePayload = this.state.databasePayload;
                    databasePayload.dbPort = e.target.value;
                    this.setState({ databasePayload });
                }} />
            <input type="text" placeholder="DB Schema"
                value={this.state.databasePayload.dbSchema}
                onChange={e => {
                    let databasePayload = this.state.databasePayload;
                    databasePayload.dbSchema = e.target.value;
                    this.setState({ databasePayload });
                }} />
            <input type="text" placeholder="DB Username"
                value={this.state.databasePayload.dbUsername}
                onChange={e => {
                    let databasePayload = this.state.databasePayload;
                    databasePayload.dbUsername = e.target.value;
                    this.setState({ databasePayload });
                }} />
            <input type="password" placeholder="DB Password"
                value={this.state.databasePayload.dbPassword}
                onChange={e => {
                    let databasePayload = this.state.databasePayload;
                    databasePayload.dbPassword = e.target.value;
                    this.setState({ databasePayload });
                }} />

            <a className="btn" onClick={() => {
                this.handleSubmit();
            }}>Authorize</a>
        </div>
    }
}

export default withRouter(DatabaseLogin);