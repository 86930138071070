import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import DropDown from "./DropDown";
import Select from "./Select";

class SelectTriggerObject extends Component {


    constructor(props) {
        super(props);
        this.state = {
            searchText: ""
        }
    }

    handleItemClick(object) {
        this.props.onTriggerObjectSelect && this.props.onTriggerObjectSelect(object);
    }

    dropdownTextChange(e) {
        this.setState({ searchText: e.target.value });
    }

    handleItemDelete() {
        this.props.clearField && this.props.clearField();
    }
    render() {

        const searchText = this.props.searchText ? this.props.searchText : this.state.searchText;

        const objectList = this.props.objects;

        let htmlBlock = null;
        if (this.props.selectedObject) {
            htmlBlock = <div className="trigger--dropdown--item">
                <div className="">
                    <span className="trigger--dropdown--item--text">{this.props.selectedObject.displayName}</span>
                </div>
            </div>
        }

        let selectView = <Select
            htmlBlock={htmlBlock}
            items={this.props.objects}
            itemRenderer={object => {
                return (<div key={object.objectId} className="trigger-select--dropdown--item" onClick={() => {
                    this.handleItemClick(object);
                }}>
                    <div className="trigger--dropdown--item--text">
                        <span className="title">{object.displayName}</span>
                        <span className="secondary">{object.objectName}</span>
                    </div>
                </div>);
            }}
            onItemSelect={(item) => {
                this.handleItemClick(item);
            }}
            filterField={'displayName'}
            onItemClear={() => {
                this.handleItemDelete();
            }}
        ></Select>

        // !this.props.searchText ?
        //     this.props.objects.filter(o => { return (this.state.searchText ? o.displayName.includes(this.state.searchText) : true); }) : this.props.objects;

        const appList = objectList ? objectList.map(object => {
            return (<div key={object.objectId} className="trigger-select--dropdown--item" onClick={() => {
                this.handleItemClick(object);
            }}>
                <div className="trigger--dropdown--item--text">
                    <span className="title">{object.displayName}</span>
                    <span className="secondary">{object.objectName}</span>
                </div>
            </div>);
        }) : null;


        return (
            <section className="builder__content__section">
                <div className="builder__content__section__item">
                    <label className="builder__content__section__item__label">
                        {this.props.objectLabel}
                    </label>
                    {selectView}
                </div>
            </section>
        );
    }
}

export default withRouter(SelectTriggerObject);
