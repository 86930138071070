import React, { Component } from "react";
import { StyleSheet, css } from 'aphrodite';
class Loader extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        return <div className="loader-wrapper"><div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div><div></div><div></div><div></div><div></div></div></div>;
    }

}

export default Loader;