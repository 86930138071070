import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import logos from "../services/LogoService";
import DropDown from "./DropDown";
import Select from "./Select";

class TriggerApp extends Component {


    constructor(props) {
        super(props);
        this.state = {
            searchText: ""
        }
    }
    handleItemClick(app) {
        this.props.onAppNameSelect && this.props.onAppNameSelect(app);
    }

    handleItemDelete() {
        this.props.clearField && this.props.clearField();
    }
    dropdownTextChange(e) {
        this.setState({ searchText: e.target.value });
    }


    render() {


        let htmlBlock = null;
        if (this.props.selectedApp && this.props.selectedApp.displayName) {
            htmlBlock = <div key={this.props.selectedApp.applicationName} className="select-active-item">
                <img className="trigger--dropdown--item--img" src={logos.getLogoForApp(this.props.selectedApp.applicationName)}></img>
                <span className="trigger--dropdown--item--text">{this.props.selectedApp.displayName}</span>
            </div>;
        }

        const searchText = this.props.selectedApp ? this.props.selectedApp : this.state.searchText;
        return (
            <section className="builder__content__section">
                <div className="builder__content__section__item">
                    <label className="builder__content__section__item__label">
                        Trigger Application
                </label>
                    <Select
                        htmlBlock={htmlBlock}
                        items={this.props.applications}
                        itemRenderer={app => {
                            return (<div key={app.applicationName} className="trigger--dropdown--item" >
                                <img className="trigger--dropdown--item--img" src={logos.getLogoForApp(app.applicationName)}></img>
                                <span className="trigger--dropdown--item--text">{app.displayName}</span>
                            </div>);
                        }}
                        onItemSelect={(item) => {
                            this.handleItemClick(item);
                        }}
                        filterField={'displayName'}
                        onItemClear={() => {
                            this.handleItemDelete();
                        }}
                    ></Select>
                </div>
            </section>
        );
    }
}

export default withRouter(TriggerApp);
