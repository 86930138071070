import React, { Component } from 'react';

export default class NotificationAlert extends Component {

    render() {
        return this.props.notificationAlert.type === 'warning' ? (
            <div className='notification-wrapper__warning'>
                <div className='notification-wrapper__warning__text' >{this.props.notificationAlert.message}</div>
            </div>
        ) : this.props.notificationAlert.type === 'success' ? (
            <div className='notification-wrapper__success'>
                <div className='notification-wrapper__success__text' >{this.props.notificationAlert.message}</div>
            </div>
        ) : null
    }
}