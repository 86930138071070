import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import DropDown from "./DropDown";
import sfdc from "../images/sfdc.png";
import qbo from "../images/qbo.png";
import gocanvas from "../images/gocanvas.png"
import dbc from "../images/dbc.png"
import Select from "./Select"
import logos from "../services/LogoService";
import FormulaBuilder from "./FormulaBuilder";
import AddNewFieldOverLay from "./AddNewFieldOverLay";

class FieldMapping extends Component {


    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            logos: {
                "QBO": qbo,
                "SFDC": sfdc,
                "GOCANVAS": gocanvas,
                "DBC": dbc
            },
            formBuilderOpen: false,
            formulaBuilderData: {},
            addNewFieldOpen: false,
            addNewFieldData: {}
        }
    }

    // handleItemClick(actionObject, triggerObject) {
    //     //  this.props.onFieldMappingSubmit && this.props.onFieldMappingSubmit(action);
    //     let mappedObject = actionObject;
    //     mappedObject.mappingValue = triggerObject.xpath;
    // }

    handleFieldMapping(path, triggerField, isArray) {
        let actionSchema = this.props.action.actionObject.objectSchema;
        let mapValue = JSON.parse(triggerField.path)
        actionSchema = this.updateField(path, actionSchema, mapValue, isArray);
        this.props.onFieldMapped && this.props.onFieldMapped(actionSchema);
    }

    saveFormula(path, formula) {
        let actionSchema = this.props.action.actionObject.objectSchema;
        actionSchema = this.updateField(path, actionSchema, formula, false);
        this.props.onFieldMapped && this.props.onFieldMapped(actionSchema);
    }

    formOpenHandler(triggerFields, path, fieldTitle, appName, mapField) {

        let formulaBuilderData = { triggerFields, path, fieldTitle, appName, mapField };
        let formBuilderOpen = true;
        this.setState({ formBuilderOpen, formulaBuilderData });
    }

    handleFieldMappingDelete(path, isArray) {
        let actionSchema = this.props.action.actionObject.objectSchema;
        actionSchema = this.updateField(path, actionSchema, undefined, isArray);

        this.props.onFieldMapped && this.props.onFieldMapped(actionSchema);
    }

    updateField(path, schema, value, isArray) {

        if (schema.type === "object") {
            if (path.length === 0) {

                schema.mapField = value;
            } else {
                let tempPath = [];
                for (let i = 1; i < path.length; i++) {
                    tempPath.push(path[i]);
                }
                schema.properties[path[0]] = this.updateField(tempPath, schema.properties[path[0]], value, isArray);
            }
            //schema.properties;

        } else if (schema.type === "array") {
            if (path.length === 0) {
                if (isArray) {
                    schema.mapField = value;
                } else {
                    schema.items.mapField = value;
                }
            } else {
                let tempPath = [];
                for (let i = 1; i < path.length; i++) {
                    tempPath.push(path[i]);
                }
                if (schema.items.type === "object") {
                    schema.items.properties[path[0]] = this.updateField(tempPath, schema.items.properties[path[0]], value, isArray);
                }
            }
        } else {
            schema.mapField = value;
        }

        return schema;
    }

    handleAddNewField(path, object) {
        //this.props.action.actionObject.objectSchema

        let schema = this.props.action.actionObject.objectSchema;
        let jsonPath = "schema.properties"

        for (let i = 0; i < path.length; i++) {
            jsonPath += `['${path[i]}']`
        }

        let newField = eval(jsonPath);

        if (newField.type === 'object') {
            newField.properties[object.name] = {
                type: object.type,
                title: object.title
            }
        } else if (newField.type === 'array') {
            if (newField.items.type = "object") {
                newField.items.properties[object.name] = {
                    type: object.type,
                    title: object.title
                }
            }
        }

        eval(`${jsonPath}=newField`);

        this.props.handleActionAddNewField && this.props.handleActionAddNewField(schema);

    }

    flattenSchema(schema, path) {

        if (!path) {
            path = []
        }
        let layout = {};
        if (schema.type === "object") {
            const keys = Object.keys(schema.properties);
            let pathStr = JSON.stringify(path);
            layout[pathStr] = {
                title: schema.title,
                type: schema.type,
                path: pathStr
            }
            for (let i = 0; i < keys.length; i++) {
                let tempPath = [...path];

                tempPath.push(keys[i]);
                layout[JSON.stringify(tempPath)] = {
                    title: schema.properties[keys[i]].title,
                    type: schema.properties[keys[i]].type,
                    path: JSON.stringify(tempPath)
                }
                let tempLayout = this.flattenSchema(schema.properties[keys[i]], tempPath);
                let layoutKeys = Object.keys(tempLayout);
                for (let j = 0; j < layoutKeys.length; j++) {
                    layout[layoutKeys[j]] = tempLayout[layoutKeys[j]];
                }
            }
        } else if (schema.type === "array") {
            let pathStr = JSON.stringify(path);
            layout[pathStr] = {
                title: schema.title,
                type: schema.type,
                path: pathStr
            }
            if (schema.items.type === "object") {
                let propKeys = Object.keys(schema.items.properties);
                for (let i = 0; i < propKeys.length; i++) {
                    let tempPath = [...path];
                    tempPath.push(propKeys[i]);
                    let tempLayout = this.flattenSchema(schema.items.properties[propKeys[i]], tempPath);
                    let keys = Object.keys(tempLayout);
                    for (let j = 0; j < keys.length; j++) {
                        layout[keys[j]] = tempLayout[keys[j]];
                    }
                }

            } else {
                let tempLayout = this.flattenSchema(schema.items, path);
            }
        } else {
            let pathStr = JSON.stringify(path);
            layout[pathStr] = {
                title: schema.title,
                type: schema.type,
                path: pathStr
            }
        }

        return layout;
    }

    fieldMapLayout(triggerType, schema, triggerFields, path) {

        if (!path) {
            path = []
        }
        let layout = null;
        if (schema.type === "object") {
            const keys = Object.keys(schema.properties);
            let fields = [];
            let required = schema.required ? schema.required : [];
            for (let i = 0; i < keys.length; i++) {
                let tempPath = [...path];
                tempPath.push(keys[i]);

                let reqFilter = required.filter(r => { return r === keys[i] });
                if (reqFilter.length > 0) {
                    schema.properties[keys[i]].requiredField = true;
                }
                fields.push(this.fieldMapLayout(triggerType, schema.properties[keys[i]], triggerFields, tempPath));
            }
            let pathStr = JSON.stringify(path);

            layout = <div key={pathStr} className="field-mapping">
                <span className="field-mapping__input__header">{schema.title}</span>{schema.requiredField && <span className="field-mapping__label__required">(required)</span>}
                <div className="field-mapping__wrapper">
                    {fields}
                    {this.props.action.actionObject.dynamicSchema && <div className="field-mapping__btn-wrapper">
                        <span className="btn field-mapping__add-btn" onClick={() => {
                            this.setState({ addNewFieldOpen: true, addNewFieldData: { path } })
                        }}>add new field</span></div>}
                </div>
            </div>
        } else if (schema.type === "array") {
            let fields = [];
            let pathStr = JSON.stringify(path);
            //this.fieldMapLayout(triggerType, schema.items, triggerFields, path);
            if (schema.items.type === "object") {
                const props = schema.items.properties;
                const propKeys = Object.keys(props);
                let required = schema.items.required ? schema.items.required : [];
                for (let i = 0; i < propKeys.length; i++) {
                    let tempPath = [...path]
                    tempPath.push(propKeys[i]);
                    let reqFilter = required.filter(r => { return r === propKeys[i] });
                    if (reqFilter.length > 0) {
                        schema.items.properties[propKeys[i]].requiredField = true;
                    }
                    let tempFields = this.fieldMapLayout(triggerType, schema.items.properties[propKeys[i]], triggerFields, tempPath);
                    fields.push(tempFields);
                }

            } else {
                fields.push(this.fieldMapLayout(triggerType, schema.items, triggerFields, path));
            }

            let htmlBlock = null;
            const triggerKeys = Object.keys(triggerFields);
            let triggerFieldList = triggerKeys.filter(key => {
                return (triggerFields[key].type === "array")
            }).map(key => {
                return triggerFields[key];
            })

            let repeatMapSelection = null;
            try {
                const pathStr = JSON.stringify(schema.mapField);
                const triggerMatch = triggerFields[pathStr];
                if (triggerMatch) {
                    htmlBlock = <div className="cus-input--html">
                        <div className="field-logo-wrapper">
                            <img className="field-logo--img" src={logos.getLogoForApp(triggerType)}></img>
                            <span className="field-logo--text--wrapper">
                                <span className="field-logo--text">{triggerMatch.title}</span></span>
                        </div>
                    </div>
                }
            } catch (err) { }
            repeatMapSelection = <div>
                <div className="field-mapping__label__wrapper">
                    <span className="field-mapping__label">{"Repeat on each:"}</span>

                </div>
                <div className="field-mapping__input_wrapper">
                    <Select
                        htmlBlock={htmlBlock}
                        items={triggerFieldList}
                        itemRenderer={item => {
                            return <div className="trigger--dropdown--item" >
                                <img className="trigger--dropdown--item--img" src={logos.getLogoForApp(triggerType)}></img>
                                <span className="trigger--dropdown--item--text">{item.title}</span>
                            </div>
                        }}
                        onItemSelect={(item) => {

                            this.handleFieldMapping(path, item, true);
                        }}
                        filterField={'title'}
                        onItemClear={() => {
                            console.log("buttsadsf")
                            this.handleFieldMappingDelete(path, true);
                        }}
                    ></Select>
                </div>
            </div >
            layout = <div key={pathStr} className="field-mapping">
                <span className="field-mapping__input__header">{schema.title}</span>{schema.requiredField ? <span className="field-mapping__label__required">(required)</span> : <span className="field-mapping__label__optional">(required)</span>}
                <div className="field-mapping__wrapper">
                    <div key={pathStr}>
                        <div className="field-mapping__repeat__wrapper">
                            <div className="field-mapping__repeat_check" >
                                <span className="field-mapping__repeat_check__text">{schema.title + " is a repeating field"}</span>
                            </div>
                            {repeatMapSelection}
                        </div>
                    </div>
                    {fields}
                    {this.props.action.actionObject.dynamicSchema && <div className="field-mapping__btn-wrapper">
                        <span className="btn field-mapping__add-btn" onClick={() => {
                            //this.handleAddNewField(path);
                            this.setState({ addNewFieldOpen: true, addNewFieldData: { path } })
                        }}>add new field</span></div>}
                </div>
            </div>

        } else {
            if (!schema.hidden) {
                let pathStr = JSON.stringify(path);
                let htmlBlock = null;
                if (schema.mapField) {
                    if (Array.isArray(schema.mapField)) {
                        const pathStr = JSON.stringify(schema.mapField);
                        const triggerMatch = triggerFields[pathStr];

                        htmlBlock = <div className="cus-input--html">
                            <div className="field-logo-wrapper">
                                <img className="field-logo--img" src={logos.getLogoForApp(triggerType)}></img>
                                <span className="field-logo--text--wrapper">
                                    <span className="field-logo--text">{triggerMatch && triggerMatch.title}</span></span>
                            </div>
                        </div>
                    } else if (typeof schema.mapField === 'object') {
                        htmlBlock = <span className="cus-input--html">Click on formula icon to edit/view the formula</span>
                    } else {
                        htmlBlock = <span className="cus-input--html field-formula--text">{schema.mapField}</span>
                    }
                }

                const triggerKeys = Object.keys(triggerFields);
                let triggerFieldList = triggerKeys.filter(key => {
                    return (triggerFields[key].type !== "object" && triggerFields[key].type !== "array")
                }).map(key => {
                    return triggerFields[key];
                })
                layout = <div key={pathStr}>
                    <div className="field-mapping__label__wrapper">
                        <img className="field-mapping__label__logo"
                            src={logos.getLogoForApp(this.props.action.type.application.applicationName)}></img>
                        <span className="field-mapping__label">{schema.title}</span>
                        {schema.requiredField && <span className="field-mapping__label__required">(required)</span>}

                    </div>
                    <div className="field-mapping__input_wrapper">
                        {/* <input className="field-mapping__input"></input>
                        <span className="field-mapping__input__formula"><span className="field-mapping__input__formula__text">f(x)</span></span> */}
                        <Select
                            htmlBlock={htmlBlock}
                            dropdownButton={<span className="field-mapping__input__formula" onClick={() => {
                                this.formOpenHandler(triggerFields, path, schema.title, this.props.trigger.type.application.applicationName, schema.mapField)
                            }}>
                                <span className="field-mapping__input__formula__text">f(x)</span>
                            </span>}
                            items={triggerFieldList}
                            filterField={'title'}
                            itemRenderer={item => {
                                return <div className="trigger--dropdown--item" >
                                    <img className="trigger--dropdown--item--img" src={logos.getLogoForApp(triggerType)}></img>
                                    <span className="trigger--dropdown--item--text">{item.title}</span>
                                </div>
                            }}
                            onItemSelect={(item) => {

                                this.handleFieldMapping(path, item, false);
                            }}
                            onItemClear={() => {
                                this.handleFieldMappingDelete(path, false);
                            }}
                        ></Select>
                    </div>
                </div >
            }
        }

        return layout;
    }
    render() {

        const triggerRootField = Object.keys(this.props.trigger.triggerObject.objectSchema.properties);
        const triggerObjects = this.props.trigger.triggerObject.objectSchema.properties[triggerRootField[0]];
        const actionRootField = Object.keys(this.props.action.actionObject.objectSchema.properties);
        const actionObjects = this.props.action.actionObject.objectSchema.properties[actionRootField[0]];/// === "array" ? this.props.action.actionObject.objectSchema.items : this.props.action.actionObject.objectSchema.properties;
        const triggerFields = this.flattenSchema(triggerObjects, [triggerRootField[0]]);

        const fieldLayout = this.fieldMapLayout(this.props.trigger.application.applicationName, actionObjects, triggerFields, [actionRootField[0]]);


        let formBuilder = null;
        if (this.state.formBuilderOpen) {
            formBuilder = <FormulaBuilder onClose={() => {
                this.setState({ formBuilderOpen: false, formulaBuilderData: null })
            }} formulaBuilderData={this.state.formulaBuilderData} onSave={(path, formula) => {
                this.saveFormula(path, formula);
                this.setState({ formBuilderOpen: false, formulaBuilderData: null })
            }}></FormulaBuilder>;
        }

        let addField = null;
        if (this.state.addNewFieldOpen) {
            addField = <AddNewFieldOverLay onAdd={(path, object) => {
                this.handleAddNewField(path, object)
                this.setState({ addNewFieldData: {}, addNewFieldOpen: false });
            }} data={this.state.addNewFieldData} onClose={() => {
                this.setState({ addNewFieldData: {}, addNewFieldOpen: false });
            }}></AddNewFieldOverLay>
        }
        return (
            <section className="builder__content__section">
                {addField}
                {formBuilder}
                <div className="field-mapping-mode"><span className="field-mapping-mode__text">Developer Mode</span><i className="material-icons field-mapping-mode__icon--off">
                    toggle_off
</i></div>
                {/* {fieldList}
                {addFieldButton} */}
                <div className="field-mapping-container">
                    {fieldLayout}
                </div>
            </section >
        );
    }
}

export default withRouter(FieldMapping);
