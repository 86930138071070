import React, { Component } from "react";
import NotificationAlert from "./NotificationAlert"
import api from "../services/ApiService"
import logos from "../services/LogoService";

class FormulaBuilder extends Component {

    constructor(props) {
        super(props);
        let path = []

        this.state = {
            notificationAlert: {},
            selectedTag: "",
            formulas: [
                { name: "substring", tag: "text", minInputs: 2, maxInputs: 3, display: "substring(inputString, startPosition, endPosition(optional) )" },
                { name: "concat", tag: "text", minInputs: 2, display: "concat(inputString1, inputString2, ..)" },
                { name: "replace", tag: "text", minInputs: 3, maxInputs: 3, display: "replace(inputString, pattern, replaceString )" }
            ],
            formulaText: "",
            formula: this.props.formulaBuilderData.mapField ? this.props.formulaBuilderData.mapField : null,
            ///concat: [{ substring: [[], '2', { abc: [[], '770908', 9099] }] }, 'afsdf']

            cursorPath: path
        }
    }

    componentDidMount() {
        let path = [];
        if (this.props.formulaBuilderData.mapField) {
            let formula = this.props.formulaBuilderData.mapField;
            if (typeof formula === 'object' && !Array.isArray(formula)) {
                let keys = Object.keys(formula);

                path = [`${keys[0]}[${formula[keys[0]].length}]`]
                console.log(JSON.stringify(path));
            }
        }
        this.setState({ cursorPath: path })
    }

    validateFormula() {

    }

    handleClose() {
        this.props.onClose && this.props.onClose();
    }

    createInput() {
        return (<input className="formula-input--text"></input>)
    }

    handleKeyDown(event, path) {
        if (event.keyCode === 8) {
            this.removeFormulaPart();
        }
    }

    handleKeyPress(event, path) {
        let cursorPath = this.state.cursorPath;
        let jsonPath = "";
        let formula = this.state.formula;
        if (cursorPath.length == 0) {
            if (!formula) {
                formula = event.key;
            } else if (typeof formula !== 'object' && !Array.isArray()) {
                formula += event.key;
            }
            this.setState({ formula });
            return;
        }
        for (let i = 0; i < cursorPath.length; i++) {
            let item = cursorPath[i];
            let position = parseInt(item.substr(item.length - 2, 1));
            item = item.substr(0, item.length - 3);
            if (!jsonPath) {
                jsonPath = `${item}[${position}]`;
            } else {
                jsonPath += "." + `${item}[${position}]`;
            }
        }

        let parentPath = jsonPath.substr(0, jsonPath.length - 3);
        let position = parseInt(jsonPath.substr(jsonPath.length - 2, 1));
        let parentElement = eval(`formula.${parentPath}`);

        let currentElement = eval(`formula.${parentPath}[${position - 1}]`);
        if (currentElement) {
            if (typeof currentElement !== 'object' && !Array.isArray(currentElement)) {
                currentElement += event.key;
                eval(`formula.${parentPath}[${position - 1}]=currentElement`);

            } else {
                eval(`formula.${parentPath}.push(event.key)`)
                let parentName = cursorPath[cursorPath.length - 1];
                let pos = parseInt(parentName.substr(parentName.length - 2, 1))
                parentName = parentName.substr(0, parentName.length - 3);
                pos++;
                cursorPath[cursorPath.length - 1] = `${parentName}[${pos}]`;
            }

        } else {
            console.log(position);
            console.log(JSON.stringify(cursorPath))
            eval(`formula.${parentPath}.push(event.key)`)
            let parentName = cursorPath[cursorPath.length - 1];
            let pos = parseInt(parentName.substr(parentName.length - 2, 1))
            parentName = parentName.substr(0, parentName.length - 3);
            pos++;
            cursorPath[cursorPath.length - 1] = `${parentName}[${pos}]`;
            console.log(JSON.stringify(cursorPath))
        }
        this.setState({ formula, cursorPath })

    }
    // ["add[1]","substract[0]","multiply[2]"]
    removeFormulaPart() {
        //   path
        let formula = this.state.formula;
        let cursorPath = this.state.cursorPath;
        if (!formula) {
            //no formula available
            return;
        }
        if (typeof formula === "object" && Array.isArray(formula)) {
            this.setState({ formula: null, cursorPath: [] });
            return;
        }
        if (typeof formula !== "object") {
            this.setState({ formula: null, cursorPath: [] });
            return;
        }

        let jsonPath = "";

        for (let i = 0; i < cursorPath.length; i++) {
            let item = cursorPath[i];
            let position = parseInt(item.substr(item.length - 2, 1));
            item = item.substr(0, item.length - 3);
            if (!jsonPath) {
                jsonPath = `${item}[${position}]`;
            } else {
                jsonPath += "." + `${item}[${position}]`;
            }
        }

        let parentPath = jsonPath.substr(0, jsonPath.length - 3);
        let position = parseInt(jsonPath.substr(jsonPath.length - 2, 1));
        let parentElement = eval(`formula.${parentPath}`);

        if (parentElement.length > 0) {
            // when ursor is inside functoin and need to remove element inside function
            eval(`formula.${parentPath}.splice(${position - 1},1)`);
            let parentName = cursorPath[cursorPath.length - 1].substr(0, cursorPath[cursorPath.length - 1].length - 3);
            cursorPath[cursorPath.length - 1] = `${parentName}[${position - 1}]`
        } else if (cursorPath.length === 1) {
            // when cursor is inside functoin  and need to remove the function
            cursorPath = [];
            formula = null;
        } else {
            // when cursor is inside functoin  and need to remove the function

            let grandpaPath = "";
            for (let i = 0; i < cursorPath.length - 1; i++) {
                let item = cursorPath[i];
                let position = parseInt(item.substr(item.length - 2, 1));
                item = item.substr(0, item.length - 3);
                if (!grandpaPath) {
                    grandpaPath = `${item}[${position}]`;
                } else {
                    grandpaPath += "." + `${item}[${position}]`;
                }
            }
            let grandpaPathNoPosition = grandpaPath.substr(0, grandpaPath.length - 3);
            let grandpaPosition = parseInt(grandpaPath.substr(grandpaPath.length - 2, 1));
            let grandpaElement = eval(`formula.${grandpaPathNoPosition}`);

            eval(`formula.${grandpaPathNoPosition}.splice(grandpaPosition,1)`);
            cursorPath.splice(cursorPath.length - 1, 1);
            let grandpaName = cursorPath[cursorPath.length - 1].substr(0, cursorPath[cursorPath.length - 1].length - 3);
            cursorPath[cursorPath.length - 1] = `${grandpaName}[${grandpaPosition}]`
        }
        this.setState({ formula, cursorPath });

    }

    buildInput(currentPath) {

        return <input key={Math.random()} className="formula-input"
            ref={(ip) => this.textInput = ip}
            value={''}
            onKeyDown={(e) => {
                this.handleKeyDown(e, currentPath);
            }}
            onKeyPress={(e) => {
                this.handleKeyPress(e, currentPath);
            }}
            onChange={(e) => {
            }}
        >
        </input>

    }
    buildFormulaLayout(formula, currentPath) {
        let layout = null;
        if (!currentPath) {
            currentPath = [];
        }
        let cursorPath = this.state.cursorPath;

        if (!formula) {
            return this.buildInput(currentPath);
        }
        if (Array.isArray(formula) && cursorPath.length === 0) {
            let triggerField = this.props.formulaBuilderData.triggerFields[JSON.stringify(formula)];
            return <span key={Math.random()}>
                <div key={Math.random()} className="formula-field"> <div className="trigger-field"><div className="field-logo-wrapper">
                    <img className="field-logo--img" src={logos.getLogoForApp(this.props.formulaBuilderData.appName)}></img>
                    <span className="field-logo--text--wrapper">
                        <span className="field-logo--text">{triggerField && triggerField.title}</span></span>
                </div></div></div>{this.buildInput(currentPath)}
            </span>
        }
        if (typeof formula !== 'object' && cursorPath.length === 0) {
            return <span key={Math.random()} className="formula-plain-text">{"'" + formula + "'"}{this.buildInput(currentPath)}</span>
        }

        if (typeof formula === 'object' && !Array.isArray(formula)) {
            let keys = Object.keys(formula);

            let innerLayout = [];
            let pathToSend = [...currentPath];
            currentPath.push(`${keys[0]}[${formula[keys[0]].length}]`)
            pathToSend.push(`${keys[0]}`)
            let input;
            // if (formula[keys[0]].length === 0) {
            //     input = this.buildInput(currentPath);
            // }
            for (let i = 0; i < formula[keys[0]].length;) {

                pathToSend[pathToSend.length - 1] = `${keys[0]}[${i}]`;
                innerLayout.push(this.buildFormulaLayout(formula[keys[0]][i], pathToSend));
                i++;
                if (i < formula[keys[0]].length) {
                    innerLayout.push(<span key={Math.random()} className="comma-text">,</span>)
                }

                let cursorPathTrimmed = [...cursorPath]
                cursorPathTrimmed.splice(cursorPath.length - 1, 1);
                let pathToSendTrimmed = [...pathToSend]
                pathToSendTrimmed.splice(pathToSend.length - 1, 1);
                if (JSON.stringify(cursorPathTrimmed) === JSON.stringify(pathToSendTrimmed)) {
                    let cursorLast = cursorPath[cursorPath.length - 1];
                    let currentLast = pathToSend[pathToSend.length - 1];
                    let pos = parseInt(currentLast.substr(currentLast.length - 2, 1));
                    currentLast = currentLast.substr(0, currentLast.length - 3)
                    currentLast = `${currentLast}[${pos + 1}]`
                    if (currentLast === cursorLast)
                        innerLayout.push(this.buildInput(pathToSend));
                }
            }
            let cursorPathTrimmed = [...cursorPath]
            cursorPathTrimmed.splice(cursorPath.length - 1, 1);
            let pathToSendTrimmed = [...currentPath]
            pathToSendTrimmed.splice(currentPath.length - 1, 1);

            if (JSON.stringify(cursorPathTrimmed) === JSON.stringify(pathToSendTrimmed)) {


                let cursorLast = cursorPath[cursorPath.length - 1];
                let currentLast = currentPath[currentPath.length - 1];
                let pos = parseInt(currentLast.substr(currentLast.length - 2, 1));
                currentLast = currentLast.substr(0, currentLast.length - 3)
                currentLast = `${currentLast}[${pos}]`
                if (currentLast === cursorLast)
                    innerLayout.push(this.buildInput(currentPath));
            }

            // if (JSON.stringify(cursorPath) === JSON.stringify(currentPath)) {

            // }

            layout = <span key={Math.random()}> <span className="formula-text" onClick={() => {
                let cursorPath = this.state.cursorPath;
                let localPath = [...currentPath];
                let path = `${keys[0]}[${formula[keys[0]].length}]` //`${keys[0]}[${formula[keys[0]].length}]`)
                //   localPath.push(path);
                cursorPath = [...localPath]
                this.setState({ cursorPath });
            }}>{keys[0] + "("}</span>
                {innerLayout}{input}
                <span className="formula-text">)</span>
            </span>
        } else if (Array.isArray(formula)) {
            let triggerField = this.props.formulaBuilderData.triggerFields[JSON.stringify(formula)];
            layout = <span key={Math.random()}>
                <div key={Math.random()} className="formula-field"> <div className="trigger-field"><div className="field-logo-wrapper">
                    <img className="field-logo--img" src={logos.getLogoForApp(this.props.formulaBuilderData.appName)}></img>
                    <span className="field-logo--text--wrapper">
                        <span className="field-logo--text">{triggerField && triggerField.title}</span></span>
                </div></div></div>
            </span>
        } else {
            return <span key={Math.random()} className="formula-plain-text">{"'" + formula + "'"}</span>
        }



        return layout;
    }

    componentDidUpdate() {
        this.textInput && this.textInput.focus();
    }

    render() {



        const triggerKeys = Object.keys(this.props.formulaBuilderData.triggerFields);

        const triggerFieldListHtml = triggerKeys.filter(key => {
            return (this.props.formulaBuilderData.triggerFields[key].type !== "object" && this.props.formulaBuilderData.triggerFields[key].type !== "array")
        }).map(key => {
            const triggerField = this.props.formulaBuilderData.triggerFields[key];

            return <div key={Math.random()} className="fields-tray--item" onClick={() => {
                // let formulaText = this.state.formulaText;
                // let textToAdd = "$'" + triggerField.title + "'";
                // let endPosition = this.textInput.selectionEnd;
                // let start = formulaText.substring(0, this.textInput.selectionEnd);
                // let end = formulaText.substring(this.textInput.selectionEnd, formulaText.length);
                // formulaText = start + textToAdd + end;
                // endPosition += textToAdd.length;
                // this.setState({ formulaText }, () => {
                //     this.textInput.selectionStart = endPosition;
                //     this.textInput.selectionEnd = endPosition;
                // })

                let cursorPath = this.state.cursorPath;
                let jsonPath = "";
                let formula = this.state.formula;
                if (cursorPath.length > 0) {

                    for (let i = 0; i < cursorPath.length; i++) {
                        let item = cursorPath[i];
                        let position = parseInt(item.substr(item.length - 2, 1));
                        item = item.substr(0, item.length - 3);
                        if (!jsonPath) {

                            jsonPath += `${item}[${position}]`;
                        } else {
                            jsonPath += "." + `${item}[${position}]`;
                        }
                    }

                    jsonPath = jsonPath.substr(0, jsonPath.length - 3);
                    let parsed = JSON.parse(triggerField.path);
                    eval(`formula.${jsonPath}.push(parsed)`);
                    let currentCursorElement = cursorPath[cursorPath.length - 1];
                    let position = parseInt(currentCursorElement.substr(currentCursorElement.length - 2, 1));
                    currentCursorElement = currentCursorElement.substr(0, currentCursorElement.length - 3);
                    cursorPath[cursorPath.length - 1] = `${currentCursorElement}[${position + 1}]`;

                } else {
                    formula = JSON.parse(triggerField.path);
                }
                this.setState({ formula, cursorPath });
                this.textInput && this.textInput.focus();
            }}><div className="trigger-field"><div className="field-logo-wrapper">
                <img className="field-logo--img" src={logos.getLogoForApp(this.props.formulaBuilderData.appName)}></img>
                <span className="field-logo--text--wrapper">
                    <span className="field-logo--text">{triggerField.title}</span></span>
            </div></div></div>
        })

        const fomulaList = this.state.formulas.filter(f => {
            return f.tag === this.state.selectedTag
        })

        const fomulaListHtml = fomulaList.map(f => {
            return <span key={Math.random()} onClick={() => {
                //f.name
                let formula = this.state.formula;
                let cursorPath = this.state.cursorPath;
                if (!formula) {
                    // When no formula is available. ie at the beginning of session
                    formula = {};
                    formula[f.name] = [];
                    cursorPath = [`${f.name}[0]`]
                } else if (typeof formula == 'object' && !Array.isArray(formula)) {
                    // if there is formula added before with function
                    let jsonPath = "";

                    let currentElement = cursorPath[cursorPath.length - 1];
                    //let position = parseInt(currentElement.substr(currentElement.length - 2, 1));
                    currentElement = currentElement.substr(0, currentElement.length - 3)
                    //  cursorPath[cursorPath.length - 1] = `${currentElement}[${position + 1}]`;
                    for (let i = 0; i < cursorPath.length; i++) {
                        let item = cursorPath[i];
                        let position = parseInt(item.substr(item.length - 2, 1));
                        item = item.substr(0, item.length - 3);
                        if (!jsonPath) {
                            jsonPath = `${item}[${position}]`;
                        } else {
                            jsonPath += "." + `${item}[${position}]`;
                        }
                    }
                    jsonPath = jsonPath.substring(0, jsonPath.length - 3);
                    let evalString = `formula.${jsonPath}.push({"${f.name}":[]})`;
                    eval(evalString);

                    cursorPath.push(`${f.name}[0]`)
                } else {
                    let oldFormula = formula;
                    formula = {};
                    formula[f.name] = [oldFormula];
                    cursorPath = [`${f.name}[1]`]
                }

                this.setState({ formula, cursorPath })

                this.textInput && this.textInput.focus();
            }} className="formula-tag--fomula">{f.display}</span>
        })

        let formulaHtml = this.buildFormulaLayout(this.state.formula)

        return (
            <div className="formula-builder-overlay" >
                <NotificationAlert notificationAlert={this.state.notificationAlert}></NotificationAlert>
                <header className="formula-builder--header">
                    <span className="formula-builder--header--text"><span>Create your formula</span></span>
                    <span className="formula-builder--header--title">{this.props.formulaBuilderData.fieldTitle}</span>
                    <span className="btn" onClick={() => {
                        this.props.onSave && this.props.onSave(this.props.formulaBuilderData.path, this.state.formula);

                    }}>Save</span>
                    <i className="material-icons formula-builder-overlay--close" onClick={() => {
                        this.handleClose();
                    }}>close</i>
                </header>
                <div className="formula-builder--main">
                    <section className="formula-tray">
                        <span className="formula-tray--header">FORMULAS</span>
                        <div className="formula-tray--form-container">
                            {this.state.selectedTag !== "math" ? (<span className="formula-tag" onClick={() => {
                                this.setState({ selectedTag: "math" })
                            }}>
                                <i className="material-icons">exposure</i>
                                <span className="formula-tag--text">Math</span>
                            </span>) : (
                                    <span className="formula-tag--expanded">
                                        <span className="formula-tag--title">
                                            <i className="material-icons">exposure</i>
                                            <span className="formula-tag--text">{"Math"}</span>
                                        </span>
                                        {fomulaListHtml}
                                    </span>
                                )}
                            {this.state.selectedTag !== "text" ? (<span className="formula-tag" onClick={() => {
                                this.setState({ selectedTag: "text" })
                            }}>
                                <i className="material-icons">format_size</i>
                                <span className="formula-tag--text">Text</span>
                            </span>) : (<span className="formula-tag--expanded">
                                <span className="formula-tag--title">
                                    <i className="material-icons">format_size</i>
                                    <span className="formula-tag--text">{"Text"}</span>
                                </span>
                                {fomulaListHtml}
                            </span>)}
                            {this.state.selectedTag !== "date-time" ? (<span className="formula-tag" onClick={() => {
                                this.setState({ selectedTag: "date-time" })
                            }}>
                                <i className="material-icons">date_range</i>
                                <span className="formula-tag--text">{"Date & Time"}</span>
                            </span>) : (<span className="formula-tag--expanded">
                                <span className="formula-tag--title">
                                    <i className="material-icons">date_range</i>
                                    <span className="formula-tag--text">{"Date & Time"}</span>
                                </span>
                                {fomulaListHtml}
                            </span>)}
                            {this.state.selectedTag !== "logical" ? (<span className="formula-tag" onClick={() => {
                                this.setState({ selectedTag: "logical" })
                            }}>
                                <i className="material-icons">highlight</i>
                                <span className="formula-tag--text">{"Logical"}</span>
                            </span>) : (<span className="formula-tag--expanded">
                                <span className="formula-tag--title">
                                    <i className="material-icons">highlight</i>
                                    <span className="formula-tag--text">{"Logical"}</span>
                                </span>
                                {fomulaListHtml}
                            </span>)}
                        </div>
                    </section>
                    <section className="formula-builder--body" onClick={() => {
                        this.textInput && this.textInput.focus();

                    }}>
                        <div className="formula-input--wrapper" >
                            {formulaHtml}
                        </div>
                    </section>
                    <section className="fields-tray">
                        <span className="formula-tray--header">{this.props.formulaBuilderData.appName + " FIELDS"}</span>
                        <div className="fields-tray--field-container">{triggerFieldListHtml}</div>
                    </section>
                </div>
            </div>
        );
    }

}

export default FormulaBuilder;