import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import api from "../services/ApiService";

class Connections extends Component {


    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            redirectUrl: ""
        }
    }

    componentDidMount() {
        //alert(sessionStorage.getItem("currentNewConnection"));

        api.getRedirectURIforConnection(sessionStorage.getItem("currentNewConnection")).then(res => {
            window.location.href = res.data;
            let jwt = localStorage.getItem("accessToken") || "";
            if (res.status === 200 && res.data) {
                // this.setState({ redirect: true, redirectUrl: res.data });
                localStorage.setItem("newConnnectionId", res.data.dataPointId);

                if (res.data.redirectUri.includes('state')) {
                    window.location.href = res.data.redirectUri; // + "_userJWT_" + jwt;
                } else {
                    window.location.href = res.data.redirectUri + "?state=" + res.data.dataPointId + "&userJWT=" + jwt;
                }



            }
        }).catch(err => {

        });
    }
    render() {

        const redirect = this.state.redirect ? <Redirect from="/connections" to={this.state.redirectUrl}></Redirect> : null;

        return <div>{redirect}</div>
    }
}

export default withRouter(Connections);