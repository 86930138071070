import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import api from "../services/ApiService";
import { stat } from "fs";

class Callback extends Component {


    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            redirectUrl: ""
        }
    }

    componentDidMount() {

        try {



            let pathname = this.props.location.pathname;
            let search = this.props.location.search;
            pathname = pathname.replace("/callback/", "");

            let qs = this.props.location.search;
            qs = qs.replace("?", "");

            let qsSplit = qs.split("&");
            let state = ""
            let accessToken = ""
            let realmId = ""
            qsSplit.map(q => {
                let keyValue = q.split("=");

                if (keyValue.length === 2) {

                    if (keyValue[0] === "state") {
                        state = keyValue[1];
                    }

                    if (keyValue[0] === "userJWT") {
                        accessToken = keyValue[1];
                    }
                    if (keyValue[0] === "realmId") {
                        realmId = keyValue[1];
                    }
                }
            })

            let s = state.split("_userJWT_");
            if (s.length > 1) {
                state = s[0];
                accessToken = s[1];
            }

            console.log("search======>" + search);
            console.log(
                "accessToken=======>" + accessToken);
            console.log("state==========>" + state);
            console.log("realmId==========>" + realmId);
            console.log("pathanme==========>" + pathname);

            // alert("loading...")


            // alert(JSON.stringify(search));

            if (!search) {
                search = '';
            }

            search = search.replace(accessToken, "");
            search = search.replace("_userJWT_", "");
            // let state = params.filter(s => { return s.includes("state") })[0].split("=")[1];
            // let code = params.filter(s => { return s.includes("code") })[0].split("=")[1];
            // let realmId = params.filter(s => { return s.includes("realmId") })[0].split("=")[1];

            // alert(pathname + search);
            api.validateOauth(pathname, search, accessToken).then(res => {
                if (res.status === 200 && res.data) {
                    localStorage.setItem("connectionAuthStatus", 200);
                    localStorage.setItem("connectionPayload", JSON.stringify(res.data));
                    window.close();
                }
            }).catch(err => {

                //  alert("permission denied");
                window.close();
            })
        } catch (err) {
            localStorage.setItem("connectionAuthStatus", 500);
            window.close();
        }

        //http://localhost:3000/callback?
        //state=ddfed492-4c67-4e81-8014-e0bf145dc316
        //&code=Q011552318893EhKdVULHQoawasUZiAALVUTjxwcp8rdLnGKxA&realmId=193514755413704

    }
    render() {
        return <div></div>
    }
}

export default withRouter(Callback);