import axios from "axios";

const site_url = process.env.REACT_APP_SITE_URL;
const url = process.env.REACT_APP_API_URL;
//const url = 'http://localhost:80';
//const site_url = 'http://localhost:3000';

// const url = 'https://app.saasflows.com';
// const site_url = 'https://app.saasflows.com';

class ApiService {

    siteBaseUrl() {
        return site_url;
    }

    constructor() {
        axios.interceptors.response.use((response) => {
            return response
        }, (error) => {
            //  console.log(JSON.stringify(error));
            if (!error.response && error.request) {
                sessionStorage.removeItem("accessToken");
                window.location = "/";
            } else if (error.response.status === 401) {
                sessionStorage.removeItem("accessToken");
                alert("Session Expired");
                var protocol = window.location.protocol;
                var slashes = protocol.concat("//");
                var host = slashes.concat(window.location.hostname);
                let returnTo = encodeURIComponent(`${'https://www.n3wlab.com'}`);

                window.location = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?returnTo=${returnTo}`
            } else {
                return Promise.reject(error);
            }
        })
    }

    login(username, password) {
        return axios.post(`${url}/api/auth/signin`, { usernameOrEmail: username, password });
    }

    getAllUsers() {
        return axios.get(`${url}/api/users/0/all`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getUserById(userId) {
        return axios.get(`${url}/api/users/${userId}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    processGraphQl(query) {
        //http://localhost:9101
        return axios.post(`${url}/graphql`, { query }, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getUserSelf() {
        return axios.get(`${url}/api/users/0/self`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }


    postUser(user) {
        return axios.post(`${url}/api/users`, user, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    updateUserById(userId, allowedIntegration, password) {
        return axios.put(`${url}/api/users/${userId}?allowedIntegration=${allowedIntegration}&password=${password}`, null, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }


    deleteUser(userId) {
        return axios.delete(`${url}/api/users/0/${userId}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getAllIntegrations() {
        return axios.get(`${url}/api/integrations`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getIntegrationById(integrationId) {
        return axios.get(`${url}/api/integrations/${integrationId}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    saveIntegration(integrationId, integration) {
        return axios.put(`${url}/api/integrations/${integrationId}`, integration, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    deleteIntegration(integrationId) {
        return axios.delete(`${url}/api/integrations/${integrationId}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }
    createNewIntegration(jobName) {
        return axios.post(`${url}/api/integrations?integrationName=${jobName}`, null, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getAllApplications() {
        return axios.get(`${url}/api/applications`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getAllAppAcconnections(applicationName) {
        return axios.get(`${url}/api/connections?applicationName=${applicationName}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }


    getConnectionByDataPointId(dataPointId) {
        return axios.get(`${url}/api/connections/${dataPointId}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getAllConnections() {
        return axios.get(`${url}/api/connections`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getRedirectURIforConnection(applicationName) {
        return axios.get(`${url}/api/connections/redirect/${applicationName}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getTriggersForApp(applicationName) {
        return axios.get(`${url}/api/applications/${applicationName}/triggers`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getObjectsForTrigger(applicationName, integrationId, triggerId, connectionId) {
        return axios.get(`${url}/api/applications/${applicationName}/triggers/${triggerId}/objects?dataPointId=${connectionId}&integrationId=${integrationId}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getObjectsForAction(applicationName, acitonId, connectionId) {
        return axios.get(`${url}/api/applications/${applicationName}/actions/${acitonId}/objects?dataPointId=${connectionId}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getTriggerObjectSchema(applicationName, triggerId, objectId, connectionId) {
        return axios.get(`${url}/api/applications/${applicationName}/triggers/${triggerId}/objects/${objectId}/schema?dataPointId=${connectionId}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getActionObjectSchema(applicationName, actionId, objectId, connectionId) {
        return axios.get(`${url}/api/applications/${applicationName}/actions/${actionId}/objects/${objectId}/schema?dataPointId=${connectionId}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    validateOauth(applicationName, search, accessToken) {
        if (!accessToken) {
            accessToken = sessionStorage.getItem('accessToken') || "";
        }
        return axios.get(`${url}/api/connections/token/${applicationName}${search}`);
    }

    getActionsForApp(applicationName) {
        return axios.get(`${url}/api/applications/${applicationName}/actions`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }
    //
    //  sapBydLogin
    sapBydLogin(data, state, accessToken) {
        if (!accessToken) {
            accessToken = sessionStorage.getItem('accessToken') || "";
        }
        // alert(state);
        return axios.post(`${url}/api/connections/token/SAPBYD?state=${state}`, data);
    }
    gocanvasLogin(data, state, accessToken) {
        if (!accessToken) {
            accessToken = sessionStorage.getItem('accessToken') || "";
        }
        // alert(state);
        return axios.post(`${url}/api/connections/token/GOCANVAS?state=${state}`, data);
    }

    quickbaseLogin(data, state, accessToken) {
        if (!accessToken) {
            accessToken = sessionStorage.getItem('accessToken') || "";
        }
        // alert(state);
        return axios.post(`${url}/api/connections/token/QBASE?state=${state}`, data);
    }

    spectrumLogin(data, state, accessToken) {
        if (!accessToken) {
            accessToken = sessionStorage.getItem('accessToken') || "";
        }
        // alert(state);
        return axios.post(`${url}/api/connections/token/SPECTRUM?state=${state}`, data);
    }
    databaseLogin(data) {
        return axios.post(`${url}/api/connections/token/DBC`, data, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }
    //
    postgresLogin(data, state, accessToken) {
        if (!accessToken) {
            accessToken = sessionStorage.getItem('accessToken') || "";
        }
        return axios.post(`${url}/api/connections/token/POSTGRES?state=${state}`, data);
    }

    mysqlLogin(data, state, accessToken) {
        if (!accessToken) {
            accessToken = sessionStorage.getItem('accessToken') || "";
        }
        return axios.post(`${url}/api/connections/token/MYSQL?state=${state}`, data);
    }
    mssqlLogin(data, state, accessToken) {
        if (!accessToken) {
            accessToken = sessionStorage.getItem('accessToken') || "";
        }
        return axios.post(`${url}/api/connections/token/MSSQL?state=${state}`, data);
    }

    netsuiteLogin(data, state, accessToken) {
        if (!accessToken) {
            accessToken = sessionStorage.getItem('accessToken') || "";
        }
        return axios.post(`${url}/api/connections/token/NETSUITE?state=${state}`, data);
    }


    qbdLogin(data, state, accessToken) {
        if (!accessToken) {
            accessToken = sessionStorage.getItem('accessToken') || "";
        }
        return axios.post(`${url}/api/connections/token/QBD?state=${state}`, data);
    }

    publishIntegration(integrationId, data) {
        return axios.post(`${url}/api/integrations/${integrationId}/publish`, data, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    createTemplate(integrationId, data) {
        return axios.post(`${url}/api/templates?integrationId=${integrationId}`, data, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getAllTemplates() {
        return axios.get(`${url}/api/templates`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getAllTemplatesForEmbedded() {
        return axios.get(`${url}/api/templates/embedded`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }
    getTemplateById(templateId) {
        return axios.get(`${url}/api/templates/${templateId}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }
    deleteTemplateById(templateId) {
        return axios.delete(`${url}/api/templates/${templateId}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }
    publishIntegrationByTemplate(template) {
        return axios.post(`${url}/api/templates/${template.templateId}/publish`, template, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    updateOrgPlan(planId, data) {
        return axios.put(`${url}/api/users/0/plans/${planId}`, data, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    createOrgPlan(data) {
        return axios.post(`${url}/api/users/0/plans`, data, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getOrgPlans() {
        return axios.get(`${url}/api/users/0/plans`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    deletePlan(planId) {
        return axios.delete(`${url}/api/users/0/plans/${planId}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getPlanByPlanId(planId) {
        return axios.get(`${url}/api/users/0/plans/${planId}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getOrgPlansForEndUser() {
        return axios.get(`${url}/api/users/1/plans`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    //GSHEET
    getGDrives(connectionId) {
        return axios.get(`${url}/api/helpers/gdrives?dataPointId=${connectionId}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }
    getGSheetBooks(connectionId, fileId) {
        return axios.get(`${url}/api/helpers/gsheets/books?dataPointId=${connectionId}&fileId=${fileId}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getGSheetBookRows(connectionId, fileId, book) {
        return axios.get(`${url}/api/helpers/gsheets/books/rows?dataPointId=${connectionId}&fileId=${fileId}&book=${book}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }

    getGSheetFiles(connectionId, driveId) {
        if (driveId) {
            driveId = "&driveId=" + driveId;
        } else {
            driveId = "";
        }
        return axios.get(`${url}/api/helpers/gsheets?dataPointId=${connectionId}${driveId}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }
    //GSHEET

    /***** PROCORE  *****/

    getProcoreCompanies(connectionId) {
        return axios.get(`${url}/api/helpers/PROCORE/companies?dataPointId=${connectionId}`, { headers: { 'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}` } });
    }
    /***** PROCORE  *****/
}

export default new ApiService();