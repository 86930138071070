import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import NotificationAlert from "../components/NotificationAlert"
import DropDown from "../components/DropDown"
import api from "../services/ApiService";

class EditUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {
                name: "",
                email: "",
                roles: "",
                allowedIntegrations: 0,
                password: ""
            },
            notificationAlert: { type: "", message: "" }
        }
    }

    handleClose() {
        this.props.history.push("/index/admin");
    }

    componentDidMount() {
        let userId = this.props.location.pathname.replace("/index/admin/edit-user/", "");
        if (userId) {
            api.getUserById(userId).then(res => {

                let user = {
                    name: res.data.name,
                    email: res.data.email,
                    password: "",
                    roles: (res.data.roles && res.data.roles.length > 0) ? res.data.roles[0].name : "",
                    allowedIntegrations: res.data.userProperty ? res.data.userProperty.allowedIntegration : 0
                };
                console.log(JSON.stringify(user));
                this.setState({ user })
            }).catch(err => {

            })
        } else {
            alert("error occurred ");
        }
    }


    handleSubmit() {
        // alert(JSON.stringify(this.state.user))
        let userId = this.props.location.pathname.replace("/index/admin/edit-user/", "");
        let password = this.state.user.password ? this.state.user.password : "";
        let allowedIntegration = this.state.user.allowedIntegrations;
        api.updateUserById(userId, allowedIntegration, password).then(res => {
            if (res.status == 200) {
                alert("user updated successfully")
                //this.setState({ users: res.data });
                this.props.history.push("/index/admin");
            } else {
                console.log(JSON.stringify(res))
            }
        }).catch(err => {
            alert("alert error occurred")
        });
    }


    render() {
        return (
            <div className="new-user-overlay" >
                <NotificationAlert notificationAlert={this.state.notificationAlert}></NotificationAlert>


                <div className="new-user-overlay--form">
                    <div className="new-user-overlay--form--header">
                        <span className="new-user-overlay--form--header--text">Add User</span>
                        <i className="material-icons new-user-overlay--form--header--icon" onClick={() => {
                            this.handleClose();
                        }}>close</i>
                    </div>

                    <div className="new-user-overlay--form--content">
                        <div className="new-user-overlay--form--content--inputbox">
                            <span className="new-user-overlay--form--content--label">Name</span>
                            <input className="new-user-overlay--form--content--input" readOnly value={this.state.user.name} disabled type="text"></input>
                        </div>

                        <div className="new-user-overlay--form--content--inputbox">
                            <span className="new-user-overlay--form--content--label">Email</span>
                            <input className="new-user-overlay--form--content--input" readOnly value={this.state.user.email} disabledtype="text"></input>
                        </div>

                        <div className="new-user-overlay--form--content--inputbox">
                            <span className="new-user-overlay--form--content--label">Password</span>
                            <input className="new-user-overlay--form--content--input" value={this.state.user.password}
                                onChange={e => {
                                    let user = this.state.user;
                                    user.password = e.target.value;
                                    this.setState({ user })
                                }}
                                type="text"></input>
                        </div>

                        <div className="new-user-overlay--form--content--inputbox">
                            <span className="new-user-overlay--form--content--label">Allowed Integrations</span>
                            <DropDown searchText={this.state.user.allowedIntegrations} className="new-user-overlay--form--content--dropdown" type="text">
                                <div className="new-user-overlay--form--content--dropdown-item" onClick={e => {
                                    let user = this.state.user;
                                    user.allowedIntegrations = 1;
                                    this.setState({ user })
                                }}>
                                    <span>1</span>
                                </div>
                                <div className="new-user-overlay--form--content--dropdown-item" onClick={e => {
                                    let user = this.state.user;
                                    user.allowedIntegrations = 2;
                                    this.setState({ user })
                                }}>
                                    <span>2</span>
                                </div>
                                <div className="new-user-overlay--form--content--dropdown-item" onClick={e => {
                                    let user = this.state.user;
                                    user.allowedIntegrations = 3;
                                    this.setState({ user })
                                }}>
                                    <span>3</span>
                                </div>
                                <div className="new-user-overlay--form--content--dropdown-item" onClick={e => {
                                    let user = this.state.user;
                                    user.allowedIntegrations = 4;
                                    this.setState({ user })
                                }}>
                                    <span>4</span>
                                </div>
                                <div className="new-user-overlay--form--content--dropdown-item" onClick={e => {
                                    let user = this.state.user;
                                    user.allowedIntegrations = 5;
                                    this.setState({ user })
                                }}>
                                    <span>5</span>
                                </div>
                            </DropDown>
                        </div>

                        <div className="new-user-overlay--form--content--inputbox">
                            <span className="new-user-overlay--form--content--label">Role</span>
                            <input className="new-user-overlay--form--content--input" value={this.state.user.roles} disabled type="text"></input>
                        </div>


                        <div className="new-user-overlay--form--content--inputbox">
                            <a className="btn" onClick={() => {
                                this.handleSubmit();
                            }}>save</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(EditUser);
