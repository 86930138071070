import React, { Component } from "react";
import bgImage from "../images/login_bg.png";
import api from '../services/ApiService';
import NotificationAlert from '../components/NotificationAlert';
import { withRouter, Switch, Route } from "react-router-dom";
import Loader from "../components/Loader"

class Login extends Component {


  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      disableLogin: false,
      notificationAlert: { type: "", message: "" }
    }
  }

  componentDidMount() {

  }
  // doLogin() {
  //   if (!this.state.disableLogin) {
  //     api.login(this.state.username, this.state.password).then(res => {
  //       this.setState({ disableLogin: false });
  //       if (res.status === 200) {
  //         if (res.data.accessToken) {
  //           sessionStorage.setItem("accessToken", res.data.accessToken);
  //           sessionStorage.setItem("userRole", res.data.user.roles[0].name);
  //           this.props.onLoginSuccess(true);
  //         }
  //       } else {
  //         this.setState({ notificationAlert: { type: "warning", message: "Invalid username/password" } })
  //         setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 4000)
  //       }
  //     }).catch(err => {
  //       console.log(JSON.stringify(err))
  //       this.setState({ disableLogin: false });
  //       if (err.response && err.response.status && err.response.status < 500) {
  //         this.setState({ notificationAlert: { type: "warning", message: "Invalid username/password" } })
  //         setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 4000)
  //       } else {
  //         this.setState({ notificationAlert: { type: "warning", message: "Unknown error occurred. Please try again" } })
  //         setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 4000)
  //       }

  //     });
  //     this.setState({ disableLogin: true });
  //   }

  // }

  render() {
    // alert(this.props.location.hash)
    // this.auth.doLogin();

    return (

      <section className="login__section">
        <Switch>
          <Route path="/cb/auth" component={() => {
            this.props.auth.handleAuth(this.props.location.hash);
            return <Loader></Loader>;
          }}></Route>
          <Route path="/" component={() => {
            this.props.auth.doLogin();
            return <Loader></Loader>;
          }}></Route>
        </Switch>
        {/* <NotificationAlert notificationAlert={this.state.notificationAlert}></NotificationAlert>
        <div className="login__banner">
          <img className="login__banner_img" src={bgImage} />
        </div>
        <div className="login__form">
          <div className="login__form__wrap">
            <h2>Sign in to Workflow</h2>
            <label className="login__form__wrap__label">Username</label>
            <input type="text" className="login__form__wrap__input" value={this.state.username} onChange={(e) => {
              this.setState({ username: e.target.value });
            }} />
            <label className="login__form__wrap__label">Password</label>
            <input type="password" className="login__form__wrap__input" value={this.state.password} onChange={(e) => {
              this.setState({ password: e.target.value });
            }} />
            <span className="login__form__wrap__forgot">forgot password</span>
            <a className={"btn login__form__btn" + (this.state.disableLogin ? " btn--disabled" : "")} onClick={() => { this.doLogin() }}>Login</a>
          </div>
        </div> */}
      </section>
    );
  }
}

export default withRouter(Login);
