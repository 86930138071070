import React, { Component } from "react";
import api from '../services/ApiService';
import NotificationAlert from '../components/NotificationAlert';
import { StyleSheet, css } from 'aphrodite';
import LogoService from "../services/LogoService"
import { relative } from "path";
import Select from "../components/Select";
import hatchapps from "../images/hatchapps.svg"
import speakap from "../images/speakap.svg"
import FieldMapping from "../embed/FieldMapping";
import { runInThisContext } from "vm";
import BuilderContentHeader from "../components/BuilderContentHeader"
import { withRouter, Route } from "react-router-dom";

class Loader extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        return <div className="loader-wrapper"><div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div><div></div><div></div><div></div><div></div></div></div>
    }
}


class CreateConnection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            openNewWindow: false,
            intervalId: 0,
            authWindow: null,
            loader: false,
            notificationAlert: {},
            connections: []
        }
    }

    componentWillUnmount() {
        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
    }

    loadAppConnections(applicationName) {
        this.setState({ loader: true });
        api.getAllConnections().then(res => {
            this.setState({ loader: false, connections: res.data });
        }).catch(err => {
            this.setState({ loader: false, notificationAlert: { type: "warning", message: "unable to load" } });

            setTimeout(() => {
                this.setState({ notificationAlert: { type: "", message: "" } });
            }, 3000);
        })
    }

    dropdownTextChange(e) {
        this.setState({ searchText: e.target.value });
    }

    componentDidMount() {
        this.loadAppConnections(this.props.applicationName)
    }

    handleNewConnection() {
        sessionStorage.setItem("currentNewConnection", this.props.applicationName)

        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
        if (this.state.authWindow) {
            this.state.authWindow.close();
        }

        const url = api.siteBaseUrl() + "/connections";
        let authWindow = window.open(url, 'targetWindow',
            'toolbar=no,location=no,  status=no,menubar=no,scrollbars=yes, resizable=yes,width=700,height=700');

        let intervalID = setInterval(() => {
            let conStatus = localStorage.getItem("connectionAuthStatus");
            let connection = localStorage.getItem("connectionPayload");

            if (conStatus == 200) {

                clearInterval(this.state.intervalId);
                localStorage.removeItem("connectionAuthStatus");
                localStorage.removeItem("connectionPayload");
                this.props.onActionAuthComplete && this.props.onActionAuthComplete(JSON.parse(connection));
            } else if (conStatus == 500) {
                clearInterval(this.state.intervalId);
                this.setState({ openNewWindow: false, intervalId: 0 })
            }

        }, 500);

        this.setState({ openNewWindow: true, intervalId: intervalID, authWindow })
    }

    handleItemClick(connection) {
        this.props.onActionAuthComplete && this.props.onActionAuthComplete(connection);
    }

    handleItemDelete() {
        this.props.clearField && this.props.clearField();
    }

    render() {

        let htmlBlock = null;
        if (this.props.selectedConnection) {
            let con = this.props.selectedConnection;
            htmlBlock = <div key={con.dataPointId} className="trigger--dropdown--item">
                <div className="">
                    <span className="trigger--dropdown--item--text">{con.connectionName}</span>
                </div>
            </div>
        }
        const connections = this.state.connections.filter(con => {
            return con.application.applicationName === this.props.applicationName
        })
        let selectView = <Select
            htmlBlock={htmlBlock}
            items={connections}
            itemRenderer={con => {
                return (<div key={con.dataPointId} className="connect-trigger-acnt--dropdown-item">
                    <div className="connect-trigger-acnt--dropdown-item--text">
                        <span className="title">{con.connectionName}</span>
                        <span className="updatedat">Last Update: {"" + new Date(parseInt(con.updatedAt) * 1000)}</span>
                    </div>
                    <a className="btn">test</a>
                </div>);
            }}
            onItemSelect={(item) => {
                this.handleItemClick(item);
            }}
            filterField={'displayName'}
            onItemClear={() => {
                this.handleItemDelete();
            }}
        ></Select>
        return (
            <section className="builder__content__section">
                <NotificationAlert notificationAlert={this.state.notificationAlert}></NotificationAlert>
                <div className="builder__content__section__item--trigger-account">
                    <label className="builder__content__section__item__label">
                        Select {this.props.appType} Account
                </label>
                    <div className="builder__content__section__item--trigger-account--div">
                        <div className="builder__content__section__item--trigger-account--div--wrap">
                            {selectView}
                        </div>
                        <a className="btn" onClick={() => {
                            this.handleNewConnection()
                        }}>connect an account</a>
                    </div>

                </div>
                {this.props.selectedConnection && <div className="builder__content__section__item--trigger-account" >
                    <span className="btn" style={{ margin: "1rem auto" }} onClick={() => {
                        this.props.nextFlow && this.props.nextFlow();
                    }}>Next</span>
                    <span className="btn" style={{ marginLeft: "1rem" }} onClick={() => {
                        this.props.cancelFlow && this.props.cancelFlow();
                    }}>Cancel</span>
                </div>}
                {!this.props.selectedConnection && <div className="builder__content__section__item--trigger-account" >
                    <span className="btn" style={{ marginLeft: "1rem" }} onClick={() => {
                        this.props.cancelFlow && this.props.cancelFlow();
                    }}>Cancel</span>
                </div>}

            </section>
        );
    }
}


class PartnerListTemplates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            templates: [],
            loader: false,
            notificationAlert: {},
            selectedTemplate: null,
            actionConnection: null,
            triggerConnection: null,
            customization: false,
            flowstep: 0,
            orgPlans: [],
            user: null
        }

    }
    //[{"templateId":4,"templateName":"test","templateDescription":"test","logoApp":"QBD","triggerApp":"QBD","actionApp":"GOCANVAS"}]
    componentDidMount() {
        this.setState({ loader: true });
        api.getUserSelf().then(usRes => {

            this.setState({ ...this.state, user: usRes.data });
            api.getOrgPlansForEndUser().then(orgRes => {

                let orgPlans = orgRes.data;
                this.setState({ ...this.state, orgPlans });
                api.getAllTemplates().then(res => {
                    if (res.data) {
                        console.log(res.data)
                        if (res.status == 200)
                            this.setState({ templates: res.data, loader: false });
                    }
                }).catch(err => {
                    this.setState({ loader: false });
                })

            }).catch(err => {
                this.setState({ loader: false });
            })
        }).catch(err => {
            this.setState({ loader: false });
        })



    }

    loadTemplateById(templateId, customization) {
        this.setState({ loader: true, customization });

        api.getTemplateById(templateId).then(res => {
            if (res.status === 200) {
                let flowstep = 1;
                this.setState({ loader: false, selectedTemplate: res.data, flowstep });
            } else {
                console.log(res.data);
                this.setState({ loader: false });
            }
        }).catch(err => {
            this.setState({ loader: false, notificationAlert: { type: "warning", message: "unable to load" } });

            setTimeout(() => {
                this.setState({ notificationAlert: { type: "", message: "" } });
            }, 3000);
        })

    }

    publishIntegration() {

        let template = this.state.selectedTemplate;

        template.trigger.connection = {
            connectionId: this.state.triggerConnection.connectionId,
            dataPointId: this.state.triggerConnection.dataPointId
        }

        template.action.connection = {
            connectionId: this.state.actionConnection.connectionId,
            dataPointId: this.state.actionConnection.dataPointId
        }

        this.setState({ loader: true });

        api.publishIntegrationByTemplate(template).then(res => {
            let notificationAlert = {
                type: "success",
                message: "Integration published successfully"
            }
            this.setState({
                actionConnection: null,
                triggerConnection: null, flowstep: 0, selectedTemplate: null, loader: false, notificationAlert
            });
            setTimeout(() => {
                this.setState({ notificationAlert: { type: "", message: "" } });
            }, 3000);
        }).catch(err => {
            let notificationAlert = {
                type: "warning",
                message: "Unable to publish integration"
            }
            this.setState({
                actionConnection: null,
                triggerConnection: null, flowstep: 0, selectedTemplate: null, loader: false, notificationAlert
            });
            setTimeout(() => {
                this.setState({ notificationAlert: { type: "", message: "" } });
            }, 3000);
        });
    }

    render() {

        console.log(sessionStorage.getItem("userProfile"));
        let userStr = sessionStorage.getItem("userProfile");

        let userPlan = {};

        if (this.state.user) {
            let user = this.state.user;

            let userPlans = this.state.orgPlans.filter(plan => {
                return plan.planCode === user.planCode;
            });
            if (userPlans.length > 0) {
                userPlan = userPlans[0];
            }
            console.log("==========")
            console.log(userPlan);
        }

        let developerMode = userPlan.customization && userPlan.customization.filter(cus => { return cus === "developerMode" }).length > 0 ? true : false;
        let createNewIntegration = userPlan.customization && userPlan.customization.filter(cus => { return cus === "createNewIntegration" }).length > 0 ? true : false;
        let formulas = userPlan.customization && userPlan.customization.filter(cus => { return cus === "formulas" }).length > 0 ? true : false;
        let fieldMapping = userPlan.customization && userPlan.customization.filter(cus => { return cus === "fieldMapping" }).length > 0 ? true : false;

        const tempList = this.state.templates.map(t => {


            return <div key={t.templateId} className={css(styles.integration_templt)}>
                <div className={css(styles.integration_templt__top, styles[t.logoApp])}>
                    <img className={css(styles.integration_templt__logo)} src={LogoService.getLogoForApp(t.logoApp)}></img>
                    <span className={css(styles.integration_templt__title)}>{t.templateName}</span>
                </div>
                <div className={css(styles.integration_templt__btm)}>
                    {userPlan.customization && userPlan.customization.length > 0 && <span className={css(styles.integration_templt_connect)} onClick={() => {
                        this.loadTemplateById(t.templateId, true);
                    }}>Customize</span>}
                    <span className={css(styles.integration_templt_connect)} onClick={() => {
                        this.loadTemplateById(t.templateId, false);
                    }}>Connect</span>
                </div>
            </div>
        })

        let loader;
        if (this.state.loader) {
            loader = <Loader></Loader>
        }

        let step;
        if (!this.state.selectedTemplate) {
            step = <div className={css(styles.content__)}>
                <div className={css(styles.content__header)}>Integrations</div>
                <div className={css(styles.integration_wrapper)}>
                    {tempList}
                </div>
            </div>
        } else if (this.state.selectedTemplate && this.state.flowstep === ACTION_STEP) {
            step = <div className={css(styles.content__)}><CreateConnection appType="Action"
                selectedConnection={this.state.actionConnection}
                applicationName={this.state.selectedTemplate.action.application.applicationName}
                onActionAuthComplete={(connection) => {
                    this.setState({ actionConnection: connection })
                }
                } nextFlow={() => {
                    let flowstep = this.state.flowstep;
                    if (this.state.customization) {
                        flowstep += 2;
                    } else {
                        flowstep++;
                    }
                    this.setState({ flowstep })
                }} cancelFlow={() => {
                    this.setState({
                        actionConnection: null,
                        triggerConnection: null, flowstep: 0, selectedTemplate: null
                    })
                }}></CreateConnection></div>
        } else if (this.state.selectedTemplate && this.state.flowstep === TRIGGER_STEP) {
            step = <div className={css(styles.content__)}><CreateConnection appType="Trigger"
                selectedConnection={this.state.triggerConnection}
                applicationName={this.state.selectedTemplate.trigger.application.applicationName}
                onActionAuthComplete={(connection) => {
                    this.setState({ triggerConnection: connection })
                }
                } nextFlow={() => {
                    let flowstep = this.state.flowstep;
                    flowstep++;
                    this.setState({ flowstep })
                }} cancelFlow={() => {
                    this.setState({
                        actionConnection: null,
                        triggerConnection: null, flowstep: 0, selectedTemplate: null
                    })
                }}></CreateConnection></div>
        } else if (this.state.selectedTemplate && this.state.flowstep === PUBLISH_STEP) {

            step = <div className={css(styles.content__)}>
                <div className={css(styles.integration_wrapper)}>
                    <div className={css(styles.publish_wrapper)}>
                        <div className={css(styles.publish_text_wrap)}> <span className={css(styles.publish_text)}>Template Name :</span><span className={css(styles.publish_text)}>{this.state.selectedTemplate.templateName}</span></div>

                        <div><span className="btn" onClick={() => {
                            this.publishIntegration();
                        }}>Publish</span>
                            <span className="btn" style={{ marginLeft: "1rem" }} onClick={() => {
                                this.setState({
                                    flowstep: 0, selectedTemplate: null, actionConnection: null,
                                    triggerConnection: null
                                })
                            }}>Cancel</span>
                        </div>
                    </div>

                </div>
            </div>
        } else if (this.state.selectedTemplate && this.state.flowstep === FIELD_MAPPING) {
            step = <div className="builder__content">
                <BuilderContentHeader nextEnable={true}
                    step={3}
                    stepTitle={"Field Mapping"}
                    nextButtonText={"publish"}
                    handleNext={() => {
                        this.publishIntegration();
                    }} handleBack={() => {
                        //this.publishIntegration();
                        this.setState({
                            flowstep: 0, selectedTemplate: null, actionConnection: null,
                            triggerConnection: null
                        })
                    }}></BuilderContentHeader>
                <FieldMapping handleActionAddNewField={(objectSchema) => {
                    let integration = this.state.selectedTemplate;
                    if (integration.action.actionObject.dynamicSchema) {

                        integration.action.actionObject.objectSchema = objectSchema;
                        this.setState({ ...this.state, selectedTemplate: integration });
                    }

                }}
                    onFieldMapped={(mappedObject) => {
                        let integration = this.state.selectedTemplate;
                        integration.action.actionObject.objectSchema = mappedObject;

                        // if (integration.action.fieldMapping) integration.action.fieldMapping.push(mappedObject);
                        // else integration.action.fieldMapping = [mappedObject];

                        // const mandatoryFields = integration.action.actionObject.objectSchema.filter(ao => {
                        //   return ao.mandatory;
                        // })

                        let enableSubmit = true;
                        // let nex
                        // for (let i = 0; i < mandatoryFields.length; i++) {
                        //   const temp = integration.action.fieldMapping ? integration.action.fieldMapping.filter(f => { return (f.xpath === mandatoryFields[i].xpath) }) : [];
                        //   if (temp.length === 0) {
                        //     enableSubmit = false;
                        //     break;
                        //   }
                        // }

                        this.setState({ ...this.state, selectedTemplate: integration });
                    }} trigger={this.state.selectedTemplate.trigger} action={this.state.selectedTemplate.action}></FieldMapping></div>

        }

        return <div>
            <NotificationAlert notificationAlert={this.state.notificationAlert}></NotificationAlert>
            <header className={css(styles.header)}>
                <img className={css(styles.header__logo)} src={hatchapps}></img>
                <span className={css(styles.header__text)}></span>
                {createNewIntegration && <div><span onClick={() => {
                    this.props.history.push("/index/new-integration");
                }} className={"btn index__header__btn " + css(styles.header_btn)}>new integration</span> </div>}

            </header>
            {loader}
            <main className={css(styles.main__)}>
                <div className={css(styles.nav__)} >
                    <span className={css(styles.nav__header)}>Category</span>
                    <span className={css(styles.nav__item)} >All</span>
                    <span className={css(styles.nav__item)}>ERP</span>
                    <span className={css(styles.nav__item)}>CRM</span>
                    <span className={css(styles.nav__item)}>IoT</span>
                </div>
                {step}
            </main>
        </div>
    }

}

const TRIGGER_STEP = 1;
const ACTION_STEP = 2;
const FIELD_MAPPING = 4;
const PUBLISH_STEP = 3;


const translateKeyframes = {
    '0%': {
        transform: 'scale(1.02)',
    },

    '50%': {
        transform: 'scale(1.05)',
    },

    '100%': {
        transform: 'scale(1.06)',
    },
};

const opacityKeyframes = {
    'from': {
        opacity: 0,
    },

    'to': {
        opacity: 1,
    }
};

const styles = StyleSheet.create({

    loader: {
        borderRadius: "50%",
        borderWidth: "10px",
        borderColor: "white",
        width: "30px",
        height: "30px",
        position: "fixed",
        top: "30%",
        left: "50%",
        backgroundColor: "#E8EBEE",
        zIndex: "10000"
    },
    header: {
        /* display: flex; */
        position: "abolute",
        top: "0px",
        left: "0px",
        width: "100vw",
        height: "87px",
        padding: "10px",
        justifyContent: "space-between",
        display: "flex",
        flexFlow: "row wrap"
        // backgroundColor: "rgb(6, 40, 68)"
    },

    header_btn: {
        marginTop: "1rem"
    },
    header__logo: {
        height: "50px",
        marginTop: "auto",
        marginBottom: "auto"
    },
    main__: {
        padding: "1rem",
        position: "relative"
    },

    header__text: {
        position: "absolute",
        textTransform: "capitalize",
        color: "white",
        letterSpacing: "0.4px",
        top: "43px",
        marginLeft: "15px",
        fontSize: "1.5rem",
        fontWeight: "600",
        transform: "translate(0, -50%)"
    },
    header__close: {
        position: "absolute",
        color: "white",
        top: "43px",
        fontSize: "3.79rem",
        fontWeight: "500",
        transform: "translate(0, -50%)",
        right: "25px"
    },
    nav__: {
        position: "absolute",
        width: "300px",
        padding: "1rem",
        display: "inline-block"
    },

    nav__header: {
        display: "block",
        backgroundColor: "#E8EBEE",
        padding: "1rem",
        fontWeight: "500",
        fontSize: "1.5rem"
    },

    nav__item: {
        display: "block",
        padding: "1rem",
        fontWeight: "500",
        fontSize: "1.5rem"
    },

    content__: {
        marginLeft: "300px",
        flexFlow: "column",
        padding: "1rem",
    },

    content__header: {
        display: "block",
        fontSize: "1.8rem",
        fontWeight: "500",
        padding: "1rem",
    },

    integration_wrapper: {
        display: "block",
    },
    integration_templt: {
        display: "inline-block",
        height: "170px",
        width: "300px",
        margin: "2rem",
        boxShadow: "0 2px 7px 0 rgba(5,41,68, .2)",
        ":hover": {
            animationName: [translateKeyframes],
            animationDuration: '.5s',
            animationIterationCount: '1',
            transform: "scale(1.06)"
        }
    },
    integration_templt__btm: {
        flexFlow: "column",
        textAlign: "right",
        padding: "1rem"
    },
    integration_templt__top: {
        display: "flex",
        flexFlow: "row",
        alignItems: "center",
        backgroundColor: "rgb(19,157,217)",
        height: "110px"
    },
    integration_templt__logo: {
        width: "80px",
        height: "80px",
        margin: "1rem",
        display: "inline-block"
    },
    integration_templt__title: {
        display: "inline-block",
        fontSize: "1.5rem",
        fontWeight: "500",
        color: "#fff"
    },
    integration_templt_connect: {
        display: "inline-block",
        textTransform: "capitalize",
        color: "rgb(6, 40, 68)",
        cursor: "pointer",
        marginTop: "1rem",
        marginBottom: "1rem",
        marginRight: "1rem",
        fontSize: "1.5rem",
        ":hover": {
            color: "rgb(2, 159, 237)"
        }
    },

    publish_wrapper: {
        display: "flex",
        flexFlow: "column",
        alignItems: "center"
    },
    publish_text_wrap: {
        marginBottom: "2rem"
    },
    publish_text: {
        fontSize: "1.8rem",
        fontWeight: "500",
        padding: "1rem",
    },
    "QBO": {
        backgroundColor: "rgb(2,166,63)"
    },
    "SFDC": {
        backgroundColor: "rgb(19,157,217)"
    },
    "GOCANVAS": {
        backgroundColor: "rgb(1,161,240)"
    },
    "DBC": {
        backgroundColor: "rgb(19,157,217)"
    },
    "QBD": {
        backgroundColor: "rgb(2,166,63)"
    },
    "MSSQL": {
        backgroundColor: "rgb(19,157,217)"
    },
    "MYSQL": {
        backgroundColor: "rgb(19,157,217)"
    },
    "ORACLE": {
        backgroundColor: "rgb(19,157,217)"
    },
    "POSTGRES": {
        backgroundColor: "rgb(47,103,146)"
    },

});


export default withRouter(PartnerListTemplates);
