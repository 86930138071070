import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import api from "../services/ApiService";
import sapbydLogo from "../images/sapbyd.jpg";

class SapBydLogin extends Component {


    constructor(props) {
        super(props);
        this.state = {
            sapbydHost: "",
            username: "",
            password: "",
            port: ""
        }
    }



    handleSubmit() {

        let qs = this.props.location.search;
        qs = qs.replace("?", "");

        let qsSplit = qs.split("&");
        let state = ""
        let accessToken = ""
        qsSplit.map(q => {
            let keyValue = q.split("=");

            if (keyValue.length === 2) {

                if (keyValue[0] === "state") {
                    state = keyValue[1];
                }

                if (keyValue[0] === "userJWT") {
                    accessToken = keyValue[1];
                }
            }
        })

        // alert(state);


        let data = {
            username: this.state.username,
            password: this.state.password,
            sapbydHost: this.state.sapbydHost,
            port: this.state.port,
        }

        api.sapBydLogin(data, state, accessToken).then(res => {
            localStorage.setItem("connectionAuthStatus", 200);
            localStorage.setItem("connectionPayload", JSON.stringify(res.data));
            window.close();
        }).catch(err => {
            alert("Invalid SapByd Input. Please verify the inputs.");
        })
    }


    render() {
        return <div className="gocanvas-login">
            <img height={"65"} width="65" src={sapbydLogo} alt="GoCanvas"></img>
            <input type="text" placeholder="SapByD Url (without HTTPS)"
                value={this.state.sapbydHost}
                onChange={e => { this.setState({ sapbydHost: e.target.value }) }} />
            <input type="text" placeholder="SapByD Username"
                value={this.state.username}
                onChange={e => { this.setState({ username: e.target.value }) }} />

            <input type="text" placeholder="SapByD Password"
                value={this.state.password}
                onChange={e => { this.setState({ password: e.target.value }) }} />

            <span className="btn" onClick={() => {
                this.handleSubmit();
            }}>Authorize</span>
        </div>
    }
}

export default withRouter(SapBydLogin);