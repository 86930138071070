import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import api from "../services/ApiService";
import quickbaseLogo from "../images/quickbase.png";


class QuickBaseLogin extends Component {


    constructor(props) {
        super(props);
        this.state = {
            qbUrl: "",
            qbAppToken: "",
            qbUsername: "",
            qbPassword: "",
        }
    }



    handleSubmit() {

        let qs = this.props.location.search;
        qs = qs.replace("?", "");

        let qsSplit = qs.split("&");
        let state = ""
        let accessToken = ""
        qsSplit.map(q => {
            let keyValue = q.split("=");

            if (keyValue.length === 2) {

                if (keyValue[0] === "state") {
                    state = keyValue[1];
                }

                if (keyValue[0] === "userJWT") {
                    accessToken = keyValue[1];
                }
            }
        })

        // alert(state);


        let data = {
            qbUrl: this.state.qbUrl,
            qbAppToken: this.state.qbAppToken,
            qbUsername: this.state.qbUsername,
            qbPassword: this.state.qbPassword
        }

        api.quickbaseLogin(data, state, accessToken).then(res => {
            localStorage.setItem("connectionAuthStatus", 200);
            localStorage.setItem("connectionPayload", JSON.stringify(res.data));
            window.close();
        }).catch(err => {
            alert("Invalid username/password");
        })
    }
    render() {
        return <div className="gocanvas-login">
            <img height={"65"} width="65" src={quickbaseLogo} alt="QuickBase"></img>
            <input type="text" placeholder="QuickBase Url (Without HTTPS)"
                value={this.state.qbUrl}
                onChange={e => { this.setState({ qbUrl: e.target.value }) }} />

            <input type="text" placeholder="QuickBase App Token"
                value={this.state.qbAppToken}
                onChange={e => { this.setState({ qbAppToken: e.target.value }) }} />
            <input type="text" placeholder="QuickBase Username"
                value={this.state.qbUsername}
                onChange={e => { this.setState({ qbUsername: e.target.value }) }} />

            <input type="text" placeholder="QuickBase Password"
                value={this.state.qbPassword}
                onChange={e => { this.setState({ qbPassword: e.target.value }) }} />

            <span className="btn" onClick={() => {
                this.handleSubmit();
            }}>Authorize</span>
        </div>
    }
}

export default withRouter(QuickBaseLogin);