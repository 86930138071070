import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Logo from "../images/logo.png";
import IntegrationListHeader from "../components/IntegrationListHeader";
import IntegrationListItem from "../components/IntegrationListItem";
import api from '../services/ApiService';

class MonetList extends Component {


    constructor(props) {
        super(props);
        this.state = {
            monets: [{
                planName: "Tier 1",
                planCode: 1,
                default: true,
                maxIntegrations: 6,
                customization: ["createNewIntegration", "developerMode"]
            }]
        }
    }
    componentDidMount() {
        // api.getAllUsers().then(res => {
        //     if (res.status == 200 && res.data) {
        //         this.setState({ users: res.data });
        //     } else {
        //         console.log(JSON.stringify(res))
        //     }
        // }).catch(err => {
        //     console.log(JSON.stringify(err));
        // })
    }


    handleDelete(user) {

    }

    handleEdit(user) {
        this.props.history.push("/index/admin/edit-user/" + user.id);
    }
    render() {

        const customizationOps = [{ name: "Create new integration", key: "createNewIntegration" },
        { name: "Developer mode", key: "developerMode" },
        { name: "Formulas", key: "formulas" },
        { name: "Field Mapping", key: "fieldMapping" }]

        const monets = this.state.monets.map(monet => {
            let customization = "";
            monet.customization.map(cus => {
                let cust = customizationOps.filter(f => { return f.key === cus });
                if (cust && cust.length > 0) {
                    customization += cust[0].name + ", "
                }
            });
            return <div key={monet.planCode} className="user-list--items">
                <span className={"user-list--item--value user-list--item--value__name"}>{monet.planName}</span>
                <span className={"user-list--item--value user-list--item--value__email"}>{monet.planCode}</span>
                <span className={"user-list--item--value user-list--item--value__role"}>{monet.maxIntegrations}</span>
                <span className={"user-list--item--value user-list--item--value__icount"}>{customization}</span>
                <span className={"user-list--item--value user-list--item--value__icount"}>{"" + monet.default}</span>
                <span className={"user-list--item--value user-list--item--value__action"}>
                    <i class="material-icons" onClick={() => {
                        this.handleEdit(monet);
                    }}>edit</i>
                    <i class="material-icons" onClick={() => {
                        this.handleDelete(monet);
                    }}>delete</i>
                </span>
            </div>
        })


        return <div className="user-list">
            <header className="user-list--header">
                <div className={"user-list--header--item user-list--header--item__name"}>Plan Name</div>
                <div className={"user-list--header--item user-list--header--item__email"}>Plan Code</div>
                <div className={"user-list--header--item user-list--header--item__icount"}>Max Integration</div>
                <div className={"user-list--header--item user-list--header--item__icount"}>customization</div>
                <div className={"user-list--header--item user-list--header--item__icount"}>default</div>
                <div className={"user-list--header--item user-list--header--item__action"}>Edit</div>
            </header>
            {monets}
        </div>;
    }

}

export default withRouter(MonetList);