import React, { Component } from "react";
import sfdc from "../images/sfdc.png";
import qbo from "../images/qbo.png";
import gocanvas from "../images/gocanvas.png"
import dbc from "../images/dbc.png"
import Input from "./Input";


/*
    props:
        items
        searchProp
        htmlBlock
        dropdownButton
        itemRenderer
        onItemSelect
        onItemClear

*/
class Select extends Component {

    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
            hide: true,
            searchText: "",
            selectedValue: "",
            logos: {
                "QBO": qbo,
                "SFDC": sfdc,
                "GOCANVAS": gocanvas,
                "DBC": dbc
            }
        }

    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
    * Alert if clicked on outside of element
    */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ hide: true });
        }
    }

    togglePopup() {
        if (this.state.hide) {
            this.setState({ hide: false })
        } else {
            this.setState({ hide: true })
        }
    }



    render() {

        let childList = [];
        let dropdownHtml = null;
        if (this.props.items) {
            let items = this.props.items;

            if (this.props.filterField && this.state.searchText) {

                items = this.props.items.filter(item => {
                    if(item[this.props.filterField]){
                        let res = item[this.props.filterField].toUpperCase().includes(this.state.searchText.toUpperCase());
                        console.log(`item.${item[this.props.filterField]}==${res}`)
                        return res;
                    }else{
                        return false;
                    }
                })
            }
            for (let i = 0; i < items.length; i++) {
                childList.push(
                    <div key={i} onClick={() => {
                        this.setState({ searchText: "" });
                        this.props.onItemSelect && this.props.onItemSelect(items[i])
                    }} className="select-list-item">
                        {items[i].id} {this.props.itemRenderer && this.props.itemRenderer(items[i])}</div>
                )
            }

            dropdownHtml = <div className={"dropdown__items_box" + (this.state.hide ? " dropdown__items_box--hide" : "")}>{childList}</div>

        }
        // childList = this.props.items.map(item => {

        // })
        //

        // <input className={this.props.className ? this.props.className : ""}
        //         type="text" placeholder={this.props.placeholder ? this.props.placeholder : "Type here.."}
        //         value={this.state.selectedValue}
        //         onChange={e => { this.props.filter && this.props.searchTextChange(e) }} />
        //     <div className={"dropdown__items_box" + (this.state.hide ? " dropdown__items_box--hide" : "")}>{childList}</div>
        return <div ref={this.setWrapperRef} onClick={() => { this.togglePopup() }}>

            <Input textInput={this.state.searchText} htmlBlock={this.props.htmlBlock} htmlButton={this.props.dropdownButton}
                onDeleteHtmlBlock={() => {
                    this.props.onItemClear && this.props.onItemClear()
                    console.log("html delete occurred")
                }} onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                }}></Input>
            {dropdownHtml}
        </div>
    }
}

export default Select;