import React, { Component } from "react";
import { withRouter, Route } from "react-router-dom";
import api from "../services/ApiService";
import NotificationAlert from "../components/NotificationAlert";
import BuilderHeader from "../components/BuilderHeader";
import BuilderNav from "../components/BuilderNav";
import BuilderLogoHolder from "../components/BuilderLogoHolder";
import BuilderProgressBar from "../components/BuilderProgressBar";
import BuilderContentHeader from "../components/BuilderContentHeader";
import TriggerApp from "../components/TriggerApp";
import ConnectTriggerAccount from "../components/ConnectTriggerAccount";
import SelectTrigger from "../components/SelectTrigger";
import SelectTriggerObject from "../components/SelectTriggerObject";
import SelectActionApp from "../components/SelectActionApp";
import ConnectActionAccount from "../components/ConnectActionAccount";
import SelectAction from "../components/SelectAction";
import SelectActionObject from "../components/SelectActionObject";
import FieldMapping from "../components/FieldMapping";
import Loader from "../components/Loader";
import { getGoogleSchema, getGSheetColumns } from "../util";

class Builder extends Component {

  constructor() {
    super();
    this.state = {
      applications: [],
      connections: [],
      triggers: [],
      triggerObjects: [],
      actionConnections: [],
      actionObjects: [],
      integration: {},
      notificationAlert: { type: "", message: "" },
      step: 1,
      stepsDone: {},
      showLoader: false,
      nextEnable: false,
      nextButtonText: "next",
      stepTitles: {
        1: "Trigger Application", 2: "Connect Your Account", 3: "Select a Trigger", 4: "Select an Object",
        5: "Action Application", 6: "Connect Your Account", 7: "Select an Action", 8: "Select a object", 9: "Field Mapping"
      }
    }
  }

  componentDidMount() {
    const integrationId = sessionStorage.getItem("integrationId");
    api.getIntegrationById(integrationId).then(res => {
      if (res.status = 200 && res.data) {
        /// sessionStorage.setItem("builderJob", res.data);
        if (res.data.config) {
          let stepsDone = {};
          let step = 1;

          const integration = res.data.config;
          if (integration.trigger && integration.trigger.application && integration.trigger.application.applicationName) {
            stepsDone["1"] = "done";
            step = 1;
          }

          if (integration.trigger && integration.trigger.connection && integration.trigger.connection.connectionName) {
            stepsDone["2"] = "done";
            step = 2;
          }

          if (integration.trigger && integration.trigger.type && integration.trigger.type.triggerName) {
            stepsDone["3"] = "done";
            step = 3;
          }

          if (integration.trigger && integration.trigger.triggerObject && integration.trigger.triggerObject.objectName) {
            stepsDone["4"] = "done";
            step = 4;
          }

          if (integration.action && integration.action.application && integration.action.application.applicationName) {
            stepsDone["5"] = "done";
            step = 5;
          }

          if (integration.action && integration.action.connection && integration.action.connection.connectionName) {
            stepsDone["6"] = "done";
            step = 6;
          }

          if (integration.action && integration.action.type && integration.action.type.actionName) {
            stepsDone["7"] = "done";
            step = 7;
          }

          if (integration.trigger && integration.trigger.triggerObject && integration.trigger.triggerObject.objectSchema) {
            if (integration.action && integration.action.actionObject && integration.action.actionObject.objectSchema) {
              stepsDone["8"] = "done";
              step = 8;
            }
          }


          this.setState({ integration, step, stepsDone }, () => {
            this.stepNextActions();
          });
        }



        // this.props.history.push("/builder/" + this.state.step);
        this.loadApplications();
      } else {
        this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });
        setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
      }
    }).catch(err => {
      this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });

      setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
    });
  }

  handleTriggerAppSelect(app) {
    let integration = this.state.integration;
    integration.trigger = { application: app };

    this.setState({ integration, nextEnable: true });

  }

  showLoader() {
    this.setState({ ...this.state, showLoader: true });
  }
  hideLoader() {
    this.setState({ ...this.state, showLoader: false });
  }
  loadApplications() {
    this.showLoader();
    api.getAllApplications().then(res => {
      if (res.status === 200 && res.data) {
        this.setState({ applications: res.data });
      }
      this.hideLoader();
    }).catch(err => {
      this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });
      this.hideLoader();
      setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
    });
  }

  loadConnections() {
    this.showLoader();
    api.getAllAppAcconnections(this.state.integration.trigger.application.applicationName).then(res => {
      this.setState({ connections: res.data });
      this.hideLoader();
    }).catch(err => {
      this.hideLoader();
    });
  }

  stepNextActions() {
    switch (this.state.step) {
      case 1:
        this.triggerAppNextHandler();
        break;
      case 2:
        this.connectTriggerAppNextHandler();
        break;
      case 3:
        this.selectTriggerNextHandler();
        break;
      case 4:
        this.selectTriggerObjectNextHandler();
        break;
      case 5:
        this.selectActionAppNextHandler();
        break;
      case 6:
        this.connectActionAppNextHandler();
        break;
      case 7:
        this.selectActionNextHandler();
        break;
      case 8:
        this.selectActionObjectNextHandler();
        break;
      case 9:
        this.publishAndGoBackToHome();
        break;
      default:
        return null;
    }
  }

  stepBackActions() {
    let prevStep = this.state.step - 1;
    if (prevStep > 0) {
      this.setState({ step: prevStep, nextEnable: true, nextButtonText: 'next' });
    } else if (this.state.integration.trigger && this.state.integration.trigger.application && this.state.integration.trigger.application.applicationName) {
      this.setState({ step: 1, nextEnable: true, nextButtonText: 'next' });
    } else {
      this.setState({ step: 1, nextButtonText: 'next' });
    }
  }

  triggerAppNextHandler() {
    this.showLoader();
    api.saveIntegration(sessionStorage.getItem("integrationId"), this.state.integration).then(res => {
      api.getAllAppAcconnections(this.state.integration.trigger.application.applicationName).then(res => {
        this.hideLoader();
        if (res.status === 200 && res.data) {
          let stepsDone = this.state.stepsDone;
          stepsDone["1"] = "done";
          if (this.state.integration.trigger.connection && this.state.integration.trigger.connection.connectionName) {
            this.setState({ step: 2, connections: res.data, nextEnable: true });
          } else {
            this.setState({ step: 2, connections: res.data, nextEnable: false });
          }

        }
      }).catch(err => {
        this.hideLoader();
        this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });
        setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
      });
    }).catch(err => {
      this.hideLoader();
      this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });
      setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
    })


  }

  connectTriggerAppNextHandler() {
    this.showLoader();
    api.saveIntegration(sessionStorage.getItem("integrationId"), this.state.integration).then(res => {
      api.getTriggersForApp(this.state.integration.trigger.application.applicationName).then(res => {
        this.hideLoader();
        if (res.status === 200 && res.data) {
          let stepsDone = this.state.stepsDone;
          stepsDone["2"] = "done";
          if (this.state.integration.trigger.type && this.state.integration.trigger.type.triggerName) {
            this.setState({ stepsDone, step: 3, triggers: res.data, nextEnable: true });
          } else {
            this.setState({ stepsDone, step: 3, triggers: res.data, nextEnable: false });
          }

        }
      }).catch(err => {
        this.hideLoader();
        this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });
        setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
      });
    }).catch(err => {
      this.hideLoader();
      this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });
      setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
    })
  }

  selectTriggerNextHandler() {
    this.showLoader();
    api.saveIntegration(sessionStorage.getItem("integrationId"), this.state.integration).then(res => {
      api.getObjectsForTrigger(this.state.integration.trigger.application.applicationName, sessionStorage.getItem("integrationId"), this.state.integration.trigger.type.triggerId,
        this.state.integration.trigger.connection.dataPointId).then(res => {
          this.hideLoader();
          if (res.status === 200 && res.data) {
            let stepsDone = this.state.stepsDone;
            stepsDone["3"] = "done";
            if (this.state.integration.trigger.triggerObject && this.state.integration.trigger.triggerObject.objectName) {
              this.setState({ stepsDone, step: 4, triggerObjects: res.data, nextEnable: true });
            } else {
              this.setState({ stepsDone, step: 4, triggerObjects: res.data, nextEnable: false });
            }
          }
        }
        ).catch(err => {
          this.hideLoader();
          this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });
          setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
        })
    }).catch(err => {
      this.hideLoader();
      this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });
      setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
    })

  }

  selectTriggerObjectNextHandler() {
    this.showLoader();
    api.saveIntegration(sessionStorage.getItem("integrationId"), this.state.integration).then(res => {
      const appName = this.state.integration.trigger.application.applicationName;
      const triggerId = this.state.integration.trigger.type.triggerId;
      const dataPointId = this.state.integration.trigger.connection.dataPointId;
      const objectId = this.state.integration.trigger.triggerObject.objectName;
      this.hideLoader();
      if (this.state.integration.trigger.type.dynamicObjectFetch) {
        this.showLoader();
        api.getTriggerObjectSchema(appName, triggerId, objectId, dataPointId).then(res => {
          this.hideLoader();
          if (res.data) {
            let integration = this.state.integration;
            integration.trigger.triggerObject.objectSchema = res.data;
            let stepsDone = this.state.stepsDone;
            stepsDone["4"] = "done";
            if (this.state.integration.action && this.state.integration.action.application.applicationName) {
              this.setState({ integration, stepsDone, step: 5, nextEnable: true });
            } else {
              this.setState({ integration, stepsDone, step: 5, nextEnable: false });
            }

          } else {

            this.setState({ notificationAlert: { type: "warning", message: "No sample data found." } })
            setTimeout(() => {
              this.setState({ notificationAlert: { type: "", message: "" } })
            }, 4000);
          }
        }).catch(err => {
          this.hideLoader();
        });
      } else {
        let stepsDone = this.state.stepsDone;
        stepsDone["4"] = "done";
        this.setState({ stepsDone, step: 5, nextEnable: false });
      }
    }).catch(err => {
      this.hideLoader();
      this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });
      setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
    })
  }

  selectActionAppNextHandler() {
    this.showLoader();
    api.saveIntegration(sessionStorage.getItem("integrationId"), this.state.integration).then(res => {
      api.getAllAppAcconnections(this.state.integration.action.application.applicationName).then(res => {
        this.hideLoader();
        if (res.status === 200 && res.data) {
          let stepsDone = this.state.stepsDone;
          stepsDone["5"] = "done";
          if (this.state.integration.action.connection && this.state.integration.action.connection.connectionName) {
            this.setState({ stepsDone, step: 6, actionConnections: res.data, nextEnable: true });
          } else {
            this.setState({ stepsDone, step: 6, actionConnections: res.data, nextEnable: false });
          }
        }
      }).catch(err => {
        this.hideLoader();
        this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });
        setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
      });
    }).catch(err => {
      this.hideLoader();
      this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });
      setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
    })
  }

  connectActionAppNextHandler() {
    this.showLoader();
    api.saveIntegration(sessionStorage.getItem("integrationId"), this.state.integration).then(res => {
      api.getActionsForApp(this.state.integration.action.application.applicationName).then(res => {
        this.hideLoader();
        if (res.status === 200 && res.data) {
          let stepsDone = this.state.stepsDone;
          stepsDone["6"] = "done";
          if (this.state.integration.action.type && this.state.integration.action.type.actionName) {
            this.setState({ stepsDone, step: 7, actions: res.data, nextEnable: true });
          } else {
            this.setState({ stepsDone, step: 7, actions: res.data, nextEnable: false });
          }

        }
      }).catch(err => {
        this.hideLoader();
        this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });
        setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
      });
    }).catch(err => {
      this.hideLoader();
      this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });
      setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
    })
  }

  selectActionNextHandler() {
    this.showLoader();
    api.saveIntegration(sessionStorage.getItem("integrationId"), this.state.integration).then(res => {
      api.getObjectsForAction(this.state.integration.action.application.applicationName,
        this.state.integration.action.type.actionId,
        this.state.integration.action.connection.dataPointId).then(res => {
          this.hideLoader();
          if (res.status === 200 && res.data) {
            let stepsDone = this.state.stepsDone;
            stepsDone["7"] = "done";
            if (this.state.integration.action.actionObject && this.state.integration.action.actionObject.objectName) {
              this.setState({ stepsDone, step: 8, actionObjects: res.data, nextEnable: true });
            } else {
              if (res.data.length === 1 && res.data[0].dynamicObjectName) {
                let integration = this.state.integration;
                integration.action.actionObject = res.data[0];
                this.setState({ stepsDone, step: 8, actionObjects: res.data, integration, nextEnable: false });
              } else {
                this.setState({ stepsDone, step: 8, actionObjects: res.data, nextEnable: false });
              }
            }

          }
        }
        ).catch(err => {
          this.hideLoader();
          this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });
          setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
        })
    }).catch(err => {
      this.hideLoader();
      this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });
      setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
    })
  }

  selectActionObjectNextHandler() {
    this.showLoader();
    api.saveIntegration(sessionStorage.getItem("integrationId"), this.state.integration).then(res => {
      this.hideLoader();
      const applicationName = this.state.integration.action.application.applicationName;
      const actionId = this.state.integration.action.type.actionId;
      const objectId = this.state.integration.action.actionObject.objectName;
      const connectionId = this.state.integration.action.connection.dataPointId;

      if (this.state.integration.action.type.dynamicObjectFetch) {
        this.showLoader();
        api.getActionObjectSchema(applicationName, actionId, objectId, connectionId).then(res => {
          this.hideLoader();
          let integration = this.state.integration;
          integration.action.actionObject.objectSchema = res.data;
          let stepsDone = this.state.stepsDone;
          stepsDone["8"] = "done";
          this.setState({ stepsDone, step: 9, nextEnable: false, nextButtonText: "publish" });
        }).catch(err => {
          this.hideLoader();
          this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });
          setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
        })
      } else {
        let stepsDone = this.state.stepsDone;
        stepsDone["8"] = "done";
        this.setState({ stepsDone, step: 9, nextEnable: false, nextButtonText: "publish" });
      }
    }).catch(err => {
      this.hideLoader();
      this.setState({ notificationAlert: { type: "warning", message: "unknown error occurred try again later" } });
      setTimeout(() => { this.setState({ notificationAlert: { type: "", message: "" } }) }, 2000)
    })

  }

  publishAndGoBackToHome() {
    this.showLoader();
    const integrationId = sessionStorage.getItem("integrationId");
    api.publishIntegration(integrationId, this.state.integration).then(res => {
      this.hideLoader();
      if (res.status === 200) {
        this.setState({ notificationAlert: { type: "success", message: "integration published successfully" } });

        setTimeout(() => {
          this.props.history.push("/index/integrations");
        }, 3000);
      }

    }).catch(err => {
      this.hideLoader();
    });
  }

  handleTriggerSelect(triggerType) {
    if (this.state.integration.trigger.application.applicationName === 'GSHEET') {
      let integration = this.state.integration;
      integration.trigger.type = triggerType;
      this.setState({ integration, nextEnable: false, showLoader: true });
      api.getGDrives(this.state.integration.trigger.connection.dataPointId).then(res => {
        this.hideLoader();
        if (res.data && res.data.drives) {
          const enumList = res.data.drives.map(drive => {
            return { key: drive.id, value: drive.name }
          })
          enumList.push({ key: "0", value: "My Drive" });

          integration.trigger.type = triggerType;
          integration.trigger.type.userInputSchema = {
            "type": "object",
            "required": ["drive"],
            "properties": {
              "drive": {
                "type": "string",
                "format": "string",
                "title": "Select Google Drive",
                "description": "",
                "placeholder": "Select Google Drive",
                enum: enumList
              }
            }
          }
          this.setState({ integration, nextEnable: false });
        } else {
          alert("Error occurred. Unable to load Google Sheets");
        }

      }).catch(err => {
        this.hideLoader();
        alert("Error occurred. Unable to load Google Sheets");
      })
    } else if (this.state.integration.trigger.application.applicationName === 'PROCORE') {
      let integration = this.state.integration;
      integration.trigger.type = triggerType;
      this.setState({ integration, nextEnable: false, showLoader: true });
      api.getProcoreCompanies(this.state.integration.trigger.connection.dataPointId).then(res => {
        this.hideLoader();
        if (res.data) {
          const enumList = res.data.map(company => {
            return { key: company.id, value: company.name }
          });
          integration.trigger.type = triggerType;

          integration.trigger.type.userInputSchema &&
            integration.trigger.type.userInputSchema.properties &&
            integration.trigger.type.userInputSchema.properties.companyId &&
            (integration.trigger.type.userInputSchema.properties.companyId['enum'] = enumList);

          this.setState({ integration, nextEnable: false });
        }
      }).catch(err => {
        this.hideLoader();
        alert("Error occurred. Unable to load Procore Companies");
      })
    } else {
      let integration = this.state.integration;
      integration.trigger.type = triggerType;
      this.setState({ integration, nextEnable: true });
    }

  }

  handleActionSelect(actionType) {
    if (this.state.integration.action.application.applicationName === 'GSHEET') {
      let integration = this.state.integration;
      integration.action.type = actionType;
      this.setState({ integration, nextEnable: false, showLoader: true });
      api.getGDrives(this.state.integration.action.connection.dataPointId).then(res => {
        this.hideLoader();
        if (res.data && res.data.drives) {
          const enumList = res.data.drives.map(drive => {
            return { key: drive.id, value: drive.name }
          })
          enumList.push({ key: "0", value: "My Drive" });

          integration.action.type = actionType;
          integration.action.type.userInputSchema = {
            "type": "object",
            "required": ["drive"],
            "properties": {
              "drive": {
                "type": "string",
                "format": "string",
                "title": "Select Google Drive",
                "description": "",
                "placeholder": "Select Google Drive",
                enum: enumList
              }
            }
          }
          this.setState({ integration, nextEnable: false });
        } else {
          alert("Error occurred. Unable to load Google Sheets");
        }

      }).catch(err => {
        this.hideLoader();
        alert("Error occurred. Unable to load Google Sheets");
      })
    } else {
      let integration = this.state.integration;
      integration.action.type = actionType;
      this.setState({ integration, nextEnable: true });
    }

  }

  handleGSHEETTriggerUserInput(fieldName, value) {
    let integration = this.state.integration;
    integration.trigger.type.userInputObject = integration.trigger.type.userInputObject ? integration.trigger.type.userInputObject : {};
    if (fieldName === 'drive') {
      integration.trigger.type.userInputObject[fieldName] = value;

      this.setState({ ...this.state, integration })
      this.showLoader();

      api.getGSheetFiles(this.state.integration.trigger.connection.dataPointId, value !== "0" ? value : "").then(res => {
        this.hideLoader();
        if (res.data && res.data.files) {
          const enumList = res.data.files.map(file => {
            return { key: file.id, value: file.name }
          })


          integration.trigger.type.userInputSchema.properties["file"] = {
            "type": "string",
            "format": "string",
            "title": "Select Google Sheet File",
            "description": "",
            "placeholder": "Select Google Sheet",
            enum: enumList
          }
          this.setState({ integration, nextEnable: false });
        } else {
          alert("Error occurred. Unable to load Google Sheets");
        }

      }).catch(err => {
        this.hideLoader();
        alert("Error occurred. Unable to load Google Sheets");
      })
      // "file": {
      //   "type": "string",
      //     "format": "string",
      //       "title": "Select File",
      //         "description": "",
      //           "placeholder": "Select Google Drive"
      // }
    } else if (fieldName === 'file') {
      integration.trigger.type.userInputObject[fieldName] = value;
      this.setState({ ...this.state, integration })
      this.showLoader();

      api.getGSheetBooks(this.state.integration.trigger.connection.dataPointId, value).then(res => {
        this.hideLoader();
        if (res.data && res.data.sheets) {
          const enumList = res.data.sheets.map(sheet => {
            return { key: sheet.properties.title, value: sheet.properties.title }
          })


          integration.trigger.type.userInputSchema.properties["sheet"] = {
            "type": "string",
            "format": "string",
            "title": "Select Google Sheet",
            "description": "",
            "placeholder": "Select Google Sheet",
            enum: enumList
          }
          this.setState({ integration, nextEnable: false });
        } else {
          alert("Error occurred. Unable to load Google Sheets");
        }
      }).catch(err => {
        this.hideLoader();
        alert("Error occurred. Unable to load Google Sheets");
      })
    } else if (fieldName === 'sheet') {
      integration.trigger.type.userInputObject[fieldName] = value;
      this.setState({ ...this.state, integration })

      this.showLoader();
      api.getGSheetBookRows(this.state.integration.trigger.connection.dataPointId, integration.trigger.type.userInputObject['file'], value).then(res => {
        this.hideLoader();
        if (res.data && res.data.values) {
          if (res.data.values.length > 0) {
            const schema = getGoogleSchema(res.data.values);
            integration.trigger.triggerObject = {
              objectName: integration.trigger.type.userInputObject['file'],
              displayName: "Google Sheet",
              application: integration.trigger.application,
              objectSchema: schema,
              dynamicSchema: false,
              addFieldButtonText: null,
              dynamicObjectName: false
            }

            integration.trigger.type.userInputSchema.properties["updateField"] = {
              "type": "string",
              "format": "string",
              "title": "Select Update Field (Only if this field updated in google sheet integration will be triggered)",
              "description": "",
              "placeholder": "",
              enum: getGSheetColumns(res.data.values)
            }

            this.setState({ ...this.state, integration, nextEnable: false, triggerObjects: [integration.trigger.triggerObject] })

          } else {
            alert("Error occurred. Google sheet does not have proper column header");
          }
        } else {
          alert("Error occurred. Google sheet does not have data");
        }
      }).catch(err => {
        this.hideLoader();
        alert("Error occurred. Unable to load Google Sheets");
      })
    } else if (fieldName === 'updateField') {
      integration.trigger.type.userInputObject[fieldName] = value;
      this.setState({ ...this.state, integration, nextEnable: true })
    }
  }

  handleGSHEETActionUserInput(fieldName, value) {
    let integration = this.state.integration;
    integration.action.type.userInputObject = integration.action.type.userInputObject ? integration.action.type.userInputObject : {};
    if (fieldName === 'drive') {
      integration.action.type.userInputObject[fieldName] = value;

      this.setState({ ...this.state, integration })
      this.showLoader();

      api.getGSheetFiles(this.state.integration.action.connection.dataPointId, value !== "0" ? value : "").then(res => {
        this.hideLoader();
        if (res.data && res.data.files) {
          const enumList = res.data.files.map(file => {
            return { key: file.id, value: file.name }
          })


          integration.action.type.userInputSchema.properties["file"] = {
            "type": "string",
            "format": "string",
            "title": "Select Google Sheet File",
            "description": "",
            "placeholder": "Select Google Sheet",
            enum: enumList
          }
          this.setState({ integration, nextEnable: false });
        } else {
          alert("Error occurred. Unable to load Google Sheets");
        }

      }).catch(err => {
        this.hideLoader();
        alert("Error occurred. Unable to load Google Sheets");
      })
      // "file": {
      //   "type": "string",
      //     "format": "string",
      //       "title": "Select File",
      //         "description": "",
      //           "placeholder": "Select Google Drive"
      // }
    } else if (fieldName === 'file') {
      integration.action.type.userInputObject[fieldName] = value;
      this.setState({ ...this.state, integration })
      this.showLoader();

      api.getGSheetBooks(this.state.integration.action.connection.dataPointId, value).then(res => {
        this.hideLoader();
        if (res.data && res.data.sheets) {
          const enumList = res.data.sheets.map(sheet => {
            return { key: sheet.properties.title, value: sheet.properties.title }
          })


          integration.action.type.userInputSchema.properties["sheet"] = {
            "type": "string",
            "format": "string",
            "title": "Select Google Sheet",
            "description": "",
            "placeholder": "Select Google Sheet",
            enum: enumList
          }
          this.setState({ integration, nextEnable: false });
        } else {
          alert("Error occurred. Unable to load Google Sheets");
        }
      }).catch(err => {
        this.hideLoader();
        alert("Error occurred. Unable to load Google Sheets");
      })
    } else if (fieldName === 'sheet') {
      integration.action.type.userInputObject[fieldName] = value;
      this.setState({ ...this.state, integration })

      this.showLoader();
      api.getGSheetBookRows(this.state.integration.action.connection.dataPointId, integration.action.type.userInputObject['file'], value).then(res => {
        this.hideLoader();
        if (res.data && res.data.values) {
          if (res.data.values.length > 0) {
            const schema = getGoogleSchema(res.data.values);
            integration.action.actionObject = {
              objectName: integration.action.type.userInputObject['file'],
              displayName: "Google Sheet",
              application: integration.action.application,
              objectSchema: schema,
              dynamicSchema: false,
              addFieldButtonText: null,
              dynamicObjectName: false
            }

            // integration.action.type.userInputSchema.properties["updateField"] = {
            //   "type": "string",
            //   "format": "string",
            //   "title": "Select Update Field (Only if this field updated in google sheet integration will be triggered)",
            //   "description": "",
            //   "placeholder": "",
            //   enum: getGSheetColumns(res.data.values)
            // }

            this.setState({ ...this.state, integration, nextEnable: true, actionObjects: [integration.action.triggerObject] })

          } else {
            alert("Error occurred. Google sheet does not have proper column header");
          }
        } else {
          alert("Error occurred. Google sheet does not have data");
        }
      }).catch(err => {
        this.hideLoader();
        alert("Error occurred. Unable to load Google Sheets");
      })
    }
  }

  handlePROCORETriggerUserInput(fieldName, value) {
    let integration = this.state.integration;
    integration.trigger.type.userInputObject = integration.trigger.type.userInputObject ? integration.trigger.type.userInputObject : {};

    if (fieldName === 'companyId') {
      integration.trigger.type.userInputObject[fieldName] = value;

      this.setState({ ...this.state, integration, nextEnable: true })
    }
  }

  render() {

    const steps = {
      1: <TriggerApp clearField={() => {
        let integration = this.state.integration;
        integration.trigger = undefined;
        this.setState({ integration });
      }} applications={this.state.applications} onAppNameSelect={appName => {
        this.handleTriggerAppSelect(appName)
      }} selectedApp={this.state.integration.trigger ? this.state.integration.trigger.application : ""}></TriggerApp>,

      2: <ConnectTriggerAccount onTriggerAuthComplete={(connection) => {
        let integration = this.state.integration;
        let connections = this.state.connections;
        connections.push(connection);
        if (integration.trigger) integration.trigger.connection = connection;
        else integration.trigger = { connection };
        this.setState({ integration, connections, nextEnable: true });
      }} a
        clearField={() => {
          let integration = this.state.integration;
          if (integration.trigger) { integration.trigger.connection = undefined; }
          this.setState({ integration });
        }}
        selectedConnection={this.state.integration.trigger && this.state.integration.trigger.connection ? this.state.integration.trigger.connection : null}
        searchText={this.state.integration.trigger && this.state.integration.trigger.connection ? this.state.integration.trigger.connection.connectionName : null}
        application={this.state.integration.trigger ? this.state.integration.trigger.application : null} connections={this.state.connections}></ConnectTriggerAccount>,

      3: <SelectTrigger triggers={this.state.triggers} onTriggerTypeSelect={(triggerType) => {

        this.handleTriggerSelect(triggerType);
      }}
        clearField={() => {
          let integration = this.state.integration;
          integration.trigger.type = undefined;
          this.setState({ integration, nextEnable: false });
        }}
        userInputChange={(fieldName, value) => {
          let integration = this.state.integration;
          if (this.state.integration.trigger.application.applicationName === 'GSHEET') {
            this.handleGSHEETTriggerUserInput(fieldName, value);
          } else if (this.state.integration.trigger.application.applicationName === 'PROCORE') {
            this.handlePROCORETriggerUserInput(fieldName, value);
          } else {
            integration.trigger.type.userInputObject = integration.trigger.type.userInputObject ? integration.trigger.type.userInputObject : {};
            integration.trigger.type.userInputObject[fieldName] = value;
            let triggerType = integration.trigger.type;
            let nextEnable = true;
            if (triggerType.userInput) {
              const reqFields = triggerType.userInputSchema.required;
              for (let i = 0; i < reqFields.length; i++) {
                if (!triggerType.userInputObject || !triggerType.userInputObject[reqFields[i]]) {
                  nextEnable = false;
                  break;
                }
              }
            }
            this.setState({ integration, nextEnable });
          }

        }}
        selectedTrigger={this.state.integration.trigger && this.state.integration.trigger.type ? this.state.integration.trigger.type : null}
        searchText={this.state.integration.trigger && this.state.integration.trigger.type ? this.state.integration.trigger.type.triggerName : ""}></SelectTrigger>,

      4: <SelectTriggerObject objects={this.state.triggerObjects}
        onTriggerObjectSelect={(triggerObject) => {
          let integration = this.state.integration;
          integration.trigger.triggerObject = triggerObject;
          this.setState({ integration, nextEnable: true });
        }}
        clearField={() => {
          let integration = this.state.integration;
          integration.trigger.triggerObject = undefined;
          this.setState({ integration, nextEnable: false });
        }}
        selectedObject={this.state.integration.trigger && this.state.integration.trigger.triggerObject ? this.state.integration.trigger.triggerObject : null}
        objectLabel={this.state.integration.trigger && this.state.integration.trigger.type && this.state.integration.trigger.type.objectLabel}
        searchText={this.state.integration.trigger && this.state.integration.trigger.triggerObject ? this.state.integration.trigger.triggerObject.displayName : ""}
      ></SelectTriggerObject>,
      5: <SelectActionApp applications={this.state.applications} onAppNameSelect={app => {
        let integration = this.state.integration;
        integration.action = { application: app };
        this.setState({ integration, nextEnable: true })
      }}
        clearField={() => {
          let integration = this.state.integration;
          integration.action.application = undefined;
          this.setState({ integration, nextEnable: false })
        }}
        selectedApp={this.state.integration.action && this.state.integration.action.application ? this.state.integration.action.application : null}></SelectActionApp>,

      6: <ConnectActionAccount connections={this.state.actionConnections}
        application={this.state.integration.action ? this.state.integration.action.application : null}
        onActionAuthComplete={(connection) => {
          let integration = this.state.integration;
          integration.action.connection = connection;
          let actionConnections = this.state.connections;
          actionConnections.push(connection);
          this.setState({ integration, actionConnections, nextEnable: true });
        }}
        clearField={() => {
          let integration = this.state.integration;
          integration.action.connection = undefined;

          this.setState({ integration, nextEnable: false });
        }}

        selectedConnection={this.state.integration.action && this.state.integration.action.connection ? this.state.integration.action.connection : null}
        searchText={this.state.integration.action && this.state.integration.action.connection ? this.state.integration.action.connection.connectionName : ""}
      ></ConnectActionAccount>,
      7: <SelectAction actions={this.state.actions} onActionTypeSelect={(actionType) => {

        this.handleActionSelect(actionType);
        // let integration = this.state.integration;
        // integration.action.type = actionType;
        // let nextEnable = true;
        // if (actionType.userInput) {
        //   const reqFields = actionType.userInputSchema.required;
        //   for (let i = 0; i < reqFields.length; i++) {
        //     if (!actionType.userInputObject || !actionType.userInputObject[reqFields[i]]) {
        //       nextEnable = false;
        //       break;
        //     }
        //   }
        // }
        // this.setState({ integration, nextEnable: nextEnable });
      }}
        clearField={() => {
          let integration = this.state.integration;
          integration.action.type = undefined;
          this.setState({ integration, nextEnable: false });
        }}
        selectedActionType={this.state.integration.action && this.state.integration.action.type}
        userInputChange={(fieldName, value) => {

          if (this.state.integration.action.application.applicationName === 'GSHEET') {
            this.handleGSHEETActionUserInput(fieldName, value);
          } else {
            let integration = this.state.integration;
            integration.action.type.userInputObject = integration.action.type.userInputObject ? integration.action.type.userInputObject : {};
            integration.action.type.userInputObject[fieldName] = value;
            let actionType = integration.action.type;
            let nextEnable = true;
            if (actionType.userInput) {
              const reqFields = actionType.userInputSchema.required;
              for (let i = 0; i < reqFields.length; i++) {
                if (!actionType.userInputObject || !actionType.userInputObject[reqFields[i]]) {
                  nextEnable = false;
                  break;
                }
              }
            }

            this.setState({ integration, nextEnable });
          }
        }

        }
        searchText={this.state.integration.action && this.state.integration.action.type ? this.state.integration.action.type.actionName : ""}
      ></SelectAction>,

      8: <SelectActionObject objects={this.state.actionObjects} onActionObjectSelect={(actionObject) => {
        let integration = this.state.integration;
        integration.action.actionObject = actionObject;
        this.setState({ integration, nextEnable: true });
      }}
        onActionObjectNameChange={(actionObjectName) => {
          let integration = this.state.integration;
          integration.action.actionObject.objectName = actionObjectName;
          this.setState({ integration, nextEnable: true });
        }
        }
        clearField={() => {
          let integration = this.state.integration;
          integration.action.actionObject = undefined;
          this.setState({ integration, nextEnable: false });
        }}
        objectLabel={this.state.integration.action && this.state.integration.action.type && this.state.integration.action.type.objectLabel}
        actionObject={this.state.integration.action && this.state.integration.action.actionObject}
        searchText={this.state.integration.action && this.state.integration.action.actionObject ? this.state.integration.action.actionObject.objectName : ""}
      ></SelectActionObject>,

      9: <FieldMapping handleActionAddNewField={(objectSchema) => {
        let integration = this.state.integration;
        if (integration.action.actionObject.dynamicSchema) {

          integration.action.actionObject.objectSchema = objectSchema;
          this.setState({ integration });
        }

      }}
        onFieldMapped={(mappedObject) => {
          let integration = this.state.integration;
          integration.action.actionObject.objectSchema = mappedObject;

          // if (integration.action.fieldMapping) integration.action.fieldMapping.push(mappedObject);
          // else integration.action.fieldMapping = [mappedObject];

          // const mandatoryFields = integration.action.actionObject.objectSchema.filter(ao => {
          //   return ao.mandatory;
          // })

          let enableSubmit = true;
          // let nex
          // for (let i = 0; i < mandatoryFields.length; i++) {
          //   const temp = integration.action.fieldMapping ? integration.action.fieldMapping.filter(f => { return (f.xpath === mandatoryFields[i].xpath) }) : [];
          //   if (temp.length === 0) {
          //     enableSubmit = false;
          //     break;
          //   }
          // }

          this.setState({ integration, nextEnable: enableSubmit });
        }} trigger={this.state.integration.trigger} action={this.state.integration.action}></FieldMapping>
    };


    return (

      <div className="builder">
        {this.state.showLoader && <Loader></Loader>}
        <NotificationAlert notificationAlert={this.state.notificationAlert}></NotificationAlert>
        <BuilderHeader integrationName={this.state.integration.integrationName}></BuilderHeader>
        <main className="builder-main">
          <BuilderNav stepsDone={this.state.stepsDone} stepInprogress={this.state.step} step={this.state.step} stepTitle={this.state.stepTitles[this.state.step]}></BuilderNav>
          <section className="builder__content">
            <BuilderLogoHolder triggerApp={this.state.integration.trigger}
              actionApp={this.state.integration.action}></BuilderLogoHolder>

            <BuilderProgressBar stepsDone={this.state.stepsDone}
              stepInprogress={this.state.step}
              step={this.state.step}
              stepTitle={this.state.stepTitles[this.state.step]}></BuilderProgressBar>

            <BuilderContentHeader nextEnable={this.state.nextEnable}
              step={this.state.step}
              stepTitle={this.state.stepTitles[this.state.step]}
              nextButtonText={this.state.nextButtonText}
              handleNext={() => {
                this.stepNextActions();
              }} handleBack={() => {
                this.stepBackActions();
              }}></BuilderContentHeader>

            {steps[this.state.step]}
          </section>
        </main>
      </div>
    );
  }
}

export default withRouter(Builder);
