import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import DropDown from "./DropDown";
import Input from "./Input";
import Select from "./Select";

class SelectActionObject extends Component {


    constructor(props) {
        super(props);
        this.state = {
            searchText: ""
        }
    }

    handleItemClick(object) {
        this.props.onActionObjectSelect && this.props.onActionObjectSelect(object);
    }
    handleItemDelete() {
        this.props.clearField && this.props.clearField();
    }


    componentDidMount() {
        if (this.props.objects.length === 1 && this.props.objects[0].dynamicObjectName) {

        }
    }

    dropdownTextChange(e) {
        this.setState({ searchText: e.target.value });
    }
    render() {

        let fieldDisplay = null;



        if (this.props.objects.length === 1 && this.props.objects[0].dynamicObjectName) {
            fieldDisplay = <div className="builder__content__section__item">
                <label className="builder__content__section__item__label">
                    {this.props.objectLabel}
                </label>
                <Input type="text"
                    textInput={this.props.actionObject.objectName}
                    onChange={e => {
                        this.props.onActionObjectNameChange
                            && this.props.onActionObjectNameChange(e.target.value)
                    }} />
            </div>
        } else {
            const appList = this.props.objects ? this.props.objects.map(object => {
                return (<div key={object.objectName} className="trigger-select--dropdown--item" onClick={() => {
                    this.handleItemClick(object);
                }}>
                    <div className="trigger--dropdown--item--text">
                        <span className="title">{object.objectName}</span>
                        <span className="secondary">{object.displayName}</span>
                    </div>
                </div>);
            }) : null;




            let htmlBlock = null;
            if (this.props.actionObject) {
                htmlBlock = <div className="trigger--dropdown--item">
                    <div className="">
                        <span className="trigger--dropdown--item--text">{this.props.actionObject.displayName}</span>
                    </div>
                </div>
            }

            let selectView = <Select
                htmlBlock={htmlBlock}
                items={this.props.objects}
                itemRenderer={object => {
                    return (<div key={object.objectId} className="trigger-select--dropdown--item">
                        <div className="trigger--dropdown--item--text">
                            <span className="title">{object.displayName}</span>
                            <span className="secondary">{object.objectName}</span>
                        </div>
                    </div>);
                }}
                onItemSelect={(item) => {
                    this.handleItemClick(item);
                }}
                filterField={'displayName'}
                onItemClear={() => {
                    this.handleItemDelete();
                }}
            ></Select>

            fieldDisplay = <div className="builder__content__section__item">
                <label className="builder__content__section__item__label">
                    {this.props.objectLabel}</label>
                {selectView}
            </div>
        }



        const searchText = this.props.searchText ? this.props.searchText : this.state.searchText;
        return (
            <section className="builder__content__section">
                {fieldDisplay}
            </section>
        );
    }
}

export default withRouter(SelectActionObject);
